import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import ContentBasic from './ContentBasic';
import ContentMore from './ContentMore';
import { StateContext } from '../../../data/state/state-context';
import { createStore, matchStore } from '../actions';
import LoadingSpinner from '../../../shared/loadingspinner';
import ProductFeatures from './ProductFeatures';
import { analytics } from '../../../lib/firebase';

const Content = () => {
  const [page, setPage] = useState('intro');
  const [placeName, setPlaceName] = useState('');
  const [placePhone, setPlacePhone] = useState('');
  const [, dispatch] = useContext(StateContext);
  const history = useHistory();

  const onCreateStore = async (
    address,
    locationInfo,
    storeImgFile,
    urlHost,
    currency,
    colors,
    aggrUrl
  ) => {
    logEvent(analytics, 'onCreateStore', { urlHost });
    const placeId = await createStore(
      dispatch,
      history,
      placeName,
      address,
      locationInfo,
      storeImgFile,
      placePhone,
      urlHost,
      currency,
      colors
    );

    if (aggrUrl) {
      logEvent(analytics, 'matchStore', { aggrUrl });
      await matchStore(history, dispatch, placeId, aggrUrl);
    } else {
      logEvent(analytics, 'import-online-store');
      history.push('/import-online-store');
    }
  };

  logEvent(analytics, 'create_store_ex', { page });
  switch (page) {
    case 'intro':
      return <ProductFeatures setPage={setPage} />;

    case 'create-basic':
      return (
        <ContentBasic setPage={setPage} setPlaceName={setPlaceName} setPlacePhone={setPlacePhone} />
      );

    case 'create-more':
      return <ContentMore placeName={placeName} onCreateStore={onCreateStore} />;

    default:
      return <ProductFeatures setPage={setPage} />;
  }
};

const CreateStore = () => {
  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
      <main className="body-content">
        <Content />
        <LoadingSpinner asOverlay />
      </main>
    </div>
  );
};

export default CreateStore;
