import React from "react";
import moment from "moment";
import { removeDealPrice } from "../../Items/actions";

const DealItem = ({dispatch, item }) => {
  return (
    <tr>
      <td>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div>
            <img
              src={item.imageSrc}
              style={{ width: 50, height: 30 }}
              alt={item.itemName}
            />
          </div>
          <div>{item.itemName}</div>
        </div>
      </td>
      <td>
        <span className="text-capitalize">{Number(item.price).toFixed(2)}</span>
      </td>
      <td>
        <span className="text-capitalize">
          {Number(item.dealPrice).toFixed(2)}
        </span>
      </td>
      <td>
        <span className="text-capitalize">
          {moment(item.dealValidFrom.toDate()).format("lll")}
        </span>
      </td>
      <td>
        <span className="text-capitalize">
          {moment(item.dealValidTo.toDate()).format("lll")}
        </span>
      </td>
      <td>
        <div
          onClick={() => {
            const confirmBox = window.confirm(`Delete '${item.itemName}'?`);
            if (confirmBox === true) {
              removeDealPrice(dispatch, item);
            }
          }}
        >
          <i className="far fa-trash-alt ms-text-danger" />
        </div>
      </td>
    </tr>
  );
};

export default DealItem;
