import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Content from './Content';
import { StateContext } from '../../../data/state/state-context';
import { onChangeLocalLang, updateStore } from '../actions';
import LoadingSpinner from '../../../shared/loadingspinner';

const EditStore = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const {
    placeId,
    placeName,
    workingHoursText,
    imageSrc,
    locationInfo,
    phoneNumber,
    address,
    hasOnlineOrdering,
    onlineOrderingSchedule,
    hasOwnDelivery,
    deliveryOptions,
    hasDunzoDelivery,
    urlHost,
    currency,
    colors,
    localLanguages
  } = rootState.main;
  const history = useHistory();
  const onUpdateStore = (
    placeName,
    workingHoursText,
    locationInfo,
    storeImgFile,
    phNo,
    currImgSrc,
    rmImgSrc,
    hasImageModified,
    hasOnlineOrdering,
    onlineOrderingSchedule,
    hasOwnDelivery,
    deliveryOptions,
    hasDunzoDelivery,
    currency,
    colors
  ) =>
    updateStore(
      dispatch,
      history,
      placeId,
      placeName,
      workingHoursText,
      locationInfo,
      storeImgFile,
      phNo,
      currImgSrc,
      rmImgSrc,
      hasImageModified,
      hasOnlineOrdering,
      onlineOrderingSchedule,
      hasOwnDelivery,
      deliveryOptions,
      hasDunzoDelivery,
      currency,
      colors
    );

  const updateLocalLang = useCallback(
    (lng) => {
      let updatedLocalLangs = [...localLanguages];
      if (localLanguages.indexOf(lng) === -1) {
        updatedLocalLangs.push(lng);
      } else {
        updatedLocalLangs = localLanguages.filter((l) => l !== lng);
      }

      onChangeLocalLang(dispatch, placeId, updatedLocalLangs);
    },
    [dispatch, placeId, localLanguages]
  );

  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <Content
          onUpdateStore={onUpdateStore}
          placeId={placeId}
          placeInfo={{
            placeName,
            workingHoursText,
            imageSrc,
            locationInfo,
            phoneNumber,
            address,
            hasOnlineOrdering,
            onlineOrderingSchedule,
            hasOwnDelivery,
            deliveryOptions,
            hasDunzoDelivery,
            urlHost,
            currency,
            colors,
            localLanguages
          }}
          updateLocalLang={updateLocalLang}
        />
        <LoadingSpinner asOverlay />
      </main>
    </div>
  );
};

export default EditStore;
