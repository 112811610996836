import React, { useEffect, useRef } from 'react';
import { toPng } from 'html-to-image';
import QRCode from 'qrcode.react';
import { waMeLink } from '../../../utils/wame';

const StickerImage = ({ backgroundImage, urlHost, imagex }) => {
  const waLink = waMeLink({ urlHost, tnum: imagex });
  return (
    <div
      className="col"
      style={{
        width: '1300px',
        height: '800px',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }}>
      <h2
        className=" d-flex justify-content-end"
        style={{
          width: '530px',
          fontWeight: 900,
          fontSize: '25px',
          marginTop: '40px'
        }}>
        TABLE
      </h2>
      <h1
        className=" d-flex  justify-content-end "
        style={{ width: '520px', fontWeight: 1000, fontSize: '50px', marginTop: '10px' }}>
        {imagex < 10 ? '0' + imagex : imagex}
      </h1>
      <QRCode
        id="store-website-url"
        value={waLink}
        size={192}
        level="H"
        includeMargin={true}
        style={{ marginTop: '250px', marginLeft: '160px' }}
      />
    </div>
  );
};

// Create Document Component
const MergeStickerImage = ({ image, backgroundImage, urlHost, addMergeImages, index }) => {
  const domEl = useRef(null);

  useEffect(() => {
    const toImage = async () => {
      // From https://www.npmjs.com/package/html-to-image
      // Safari is not supported, as it uses a stricter security model on <foreignObject> tag.
      // Suggested workaround is to use toSvg and render on the server.

      const dataUrl = await toPng(domEl.current);
      addMergeImages(dataUrl, index);
    };
    if (domEl.current) {
      toImage();
    }
  }, [domEl]);

  return (
    <div
      id="domEl"
      ref={domEl}
      style={{
        fontFamily: 'inter',
        width: '102%',
        height: '1602px'
      }}>
      <div>
        <div className="d-flex ">
          {image[0] && (
            <StickerImage backgroundImage={backgroundImage} urlHost={urlHost} imagex={image[0]} />
          )}
          {image[1] && (
            <StickerImage backgroundImage={backgroundImage} urlHost={urlHost} imagex={image[1]} />
          )}
        </div>
        <div class="d-flex">
          {image[2] && (
            <StickerImage backgroundImage={backgroundImage} urlHost={urlHost} imagex={image[2]} />
          )}
          {image[3] && (
            <StickerImage backgroundImage={backgroundImage} urlHost={urlHost} imagex={image[3]} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MergeStickerImage;
