import * as ActionTypes from '../../data/state/action-types';

const initialState = {
  menuItems: [ /* {id, include, itemName, description, category, price, imageSrc, available} */ ],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_IMPORTED_MENU_ITEMS:
      return {
        ...state,
        menuItems: action.menuItems,
      };
    case ActionTypes.UPDATE_IMPORTED_MENU_ITEM:
      return {
        ...state,
        menuItems: state.menuItems.map((mi) => ( mi.id === action.menuItem.id ? action.menuItem : mi)),
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
