import { doc, setDoc, collection, addDoc } from 'firebase/firestore';
import { geohashForLocation } from 'geofire-common';
import { query, where, getDocs } from 'firebase/firestore';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';

import { auth, db } from '../../lib/firebase';
import * as ActionTypes from '../../data/state/action-types';
import { uploadImage } from '../AddItem/actions';
import { toggleIsLoading } from '../Loading/actions';
import { getUrlHost, idfy } from '../../utils';
import settings from '../../../config/settings';
import { doImport } from '../ImportMenu/actions';
import { setUserAction } from '../Login/actions';

const setStoreAction = (
  placeId,
  placeName,
  workingHoursText,
  imageSrc,
  verified,
  locationInfo,
  geohash,
  phoneNumber,
  address,
  hasOnlineOrdering,
  onlineOrderingSchedule,
  hasOwnDelivery,
  deliveryOptions,
  hasDunzoDelivery,
  urlHost,
  currency,
  theme,
  colors,
  primaryColor,
  secondaryColor,
  subscriptionType,
  localLanguages
) => ({
  type: ActionTypes.SET_STORE,
  placeId,
  placeName,
  workingHoursText,
  imageSrc,
  verified,
  locationInfo,
  geohash,
  phoneNumber,
  address,
  hasOnlineOrdering,
  onlineOrderingSchedule,
  hasOwnDelivery,
  deliveryOptions,
  hasDunzoDelivery,
  urlHost,
  currency,
  theme,
  colors,
  primaryColor,
  secondaryColor,
  subscriptionType,
  localLanguages
});

const sendVerificationEmail = (dispatch, email, userCredential, resolve) => {
  // User created
  const user = userCredential.user;
  // ...
  console.log('yahooo..', user);
  dispatch(setUserAction(user ?? {}, email));

  sendEmailVerification(auth.currentUser, { url: settings.storeUrl })
    .then(() => {
      console.log('Verification Email Sent Successfully !');

      // Add a new document in collection "users"
      setDoc(doc(db, 'users', user.uid), {
        displayName: email.split('@')[0],
        userType: 'store-user',
        email,
        createdAt: new Date()
      });

      resolve(true);
    })
    .catch((error) => {
      console.error(error);
    });
};

const createUser = (dispatch, email, password, resolve) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => sendVerificationEmail(dispatch, email, userCredential, resolve))
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('errors..', errorCode, errorMessage);
      alert(errorMessage);
    });
};

const createAccount = (dispatch, slug, p) => {
  const email = `${slug}@myferrypal.com`;
  return new Promise((resolve) => createUser(dispatch, email, p, resolve));
};

const saveLead = async (placeName, phNo, email) => {
  const phoneNumber = phNo ? phNo.substring(1) : null;
  await addDoc(collection(db, 'leads'), {
    placeName,
    phoneNumber,
    email
  });
};

const matchStore = async (history, dispatch, placeId, aggrUrl) => {
  toggleIsLoading(dispatch, true); // start spinning
  const response = await fetch(
    `${settings.croquetUrl}/matchingstores-bylink?link=${encodeURIComponent(aggrUrl)}`
  );
  let storeData = [];
  if (response.status === 200) {
    let data = await response.json();
    storeData = data[0]; // we expect only only store in the result
  }

  const menuItems = [];
  const itemCategories = storeData?.itemCategories;
  if (!Array.isArray(itemCategories)) {
    return;
  }

  itemCategories.forEach(({ name: categName, items }) => {
    items.forEach(({ name: itemName, description = '', price = '0' }, index2) => {
      menuItems.push({
        id: index2,
        include: true,
        itemName,
        description,
        category: { id: idfy(categName), name: categName },
        price: parseFloat(price.replace(/[^\d.]*/g, ''))
      });
    });
  });
  toggleIsLoading(dispatch, false); // stop spinning

  // from here we are going to utilize the ImportMenu's doImport
  await doImport(history, dispatch, placeId, menuItems);
};

const createStore = async (
  dispatch,
  history,
  placeName,
  address,
  locationInfo,
  storeImgFile,
  phNo,
  urlHost,
  currency,
  colors
) => {
  toggleIsLoading(dispatch, true); // start spinning
  // create a user
  const result = await fetch(`${settings.croquetUrl}/matchingstores-genpass`);
  let p = '';
  if (result.status === 200) {
    let data = await result.json();
    p = data.p;
  } else {
    alert('An error occurred');
    return null;
  }

  const slug = urlHost.replace('.myferrypal.com', '');
  const accountCreated = await createAccount(dispatch, slug, p);
  if (accountCreated !== true) {
    alert('Failed to create an account');
    return null;
  }

  let imageSrc = '';
  if (storeImgFile !== null) {
    imageSrc = await uploadImage(storeImgFile);
  }
  const { lat, lng } = locationInfo;
  const geohash = geohashForLocation([lat, lng]);
  const phoneNumber = phNo ? phNo.substring(1) : null;
  // const address = locationInfo.geocodedAddress;
  const docRef = await addDoc(collection(db, 'places'), {
    placeName,
    address,
    geohash,
    createdBy: auth.currentUser.uid,
    createdAt: new Date(),
    imageSrc,
    locationInfo,
    phoneNumber,
    urlHost,
    currency,
    colors,
    subscriptionType: 0
  });
  toggleIsLoading(dispatch, false); // stop spinning
  console.log('Document written with ID: ', docRef.id);

  dispatch(
    setStoreAction(
      docRef.id,
      placeName,
      '',
      imageSrc,
      false,
      locationInfo,
      geohash,
      phoneNumber,
      address,
      true,
      {},
      false,
      [],
      false,
      urlHost,
      currency,
      'custom',
      colors,
      '',
      '',
      0,
      []
    )
  );

  // history.push('/configure-website');
  return docRef.id;
};

const validateUrlHost = async (urlPrefix) => {
  if (!urlPrefix || urlPrefix.trim() === '') {
    return '';
  }

  if (urlPrefix.trim().length < 2) {
    return 'invalid';
  }

  const urlHost = getUrlHost(urlPrefix);
  try {
    const q = query(collection(db, 'places'), where('urlHost', '==', urlHost));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      const q2 = query(collection(db, 'places'), where('urlHost2', '==', urlHost));
      const querySnapshot2 = await getDocs(q2);
      if (querySnapshot2.empty) {
        return 'valid';
      } else {
        return 'invalid';
      }
    } else {
      return 'invalid';
    }
  } catch (error) {}
};

export { saveLead, matchStore, createStore, validateUrlHost };
