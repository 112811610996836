import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import TopNavigationEx from '../../Root/components/TopNavigationEx';
import SmartQrImageEx from './SmartQrImageEx';
import ContentEx from './ContentEx';
import { StateContext } from '../../../data/state/state-context';

const card1 = encodeURI('https://m.qrmeu.com/assets/Card Marketing/Frame 3.png');
const card2 = encodeURI('https://m.qrmeu.com/assets/Card Marketing/Frame 1.png');
const card3 = encodeURI('https://m.qrmeu.com/assets/Card Marketing/Frame 5.png');
const card4 = encodeURI('https://m.qrmeu.com/assets/Card Marketing/Frame 7.png');
const card5 = encodeURI('https://m.qrmeu.com/assets/Card Marketing/Frame 9.png');
const cardQr = encodeURI('https://m.qrmeu.com/assets/Card Marketing/bg.png');
const demoCard1 = encodeURI('https://m.qrmeu.com/assets/posts/Instagram post - 12.png');
const demoCard2 = encodeURI('https://m.qrmeu.com/assets/posts/Instagram post - 13.png');
const demoCard3 = encodeURI('https://m.qrmeu.com/assets/posts/Instagram post - 14.png');
const demoCard4 = encodeURI('https://m.qrmeu.com/assets/posts/Instagram post - 15.png');
const demoCard5 = encodeURI('https://m.qrmeu.com/assets/posts/Instagram post - 16.png');
const SmartOrderEx = () => {
  const { slug } = useParams();
  const [rootState] = useContext(StateContext);
  const { urlHost } = rootState.main;
  const [qrCard, setQrCard] = useState(null);
  return (
    <>
      <div style={{ backgroundColor: '#f0f0fa' }}>
        <div className="ms-body ms-primary-theme">
          <main className="body-content">
            <TopNavigationEx slug={slug} />
            <ContentEx
              cardImages={[
                {
                  data: card1,
                  orderQR: qrCard,
                  demo: demoCard1
                },
                {
                  data: card2,
                  orderQR: qrCard,
                  demo: demoCard2
                },
                {
                  data: card3,
                  orderQR: qrCard,
                  demo: demoCard3
                },

                { data: card4, orderQR: qrCard, demo: demoCard4 },
                { data: card5, orderQR: qrCard, demo: demoCard5 }
              ]}
            />
          </main>
        </div>
      </div>
      <div style={{ opacity: 0 }}>
        <SmartQrImageEx setQrCard={setQrCard} CardQr={cardQr} urlHost={urlHost} />
      </div>
    </>
  );
};

export default SmartOrderEx;
