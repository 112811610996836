export const currencies = [
  { id: 'USD', name: 'US Dollar', countryCode: 'US' },
  { id: 'CAD', name: 'Canadian Dollar', countryCode: 'CA' },
  { id: 'INR', name: 'Indian Rupee', countryCode: 'IN' },
  { id: 'AED', name: 'UAE Dirham', countryCode: 'AE' }
];

export const defaultCategories = [
  { id: 'appetizer', name: 'Appetizer' },
  { id: 'soup', name: 'Soup' },
  { id: 'main', name: 'Main' },
  { id: 'side', name: 'Side' },
  { id: 'drink', name: 'Drink' },
  { id: 'dessert', name: 'Dessert' },
  { id: 'other', name: 'Other' }
];

export const defaultDeliveryCharge = {
  USD: { distanceUpto: 3, deliveryCharge: 2, freeDeliveryFor: 50 },
  CAD: { distanceUpto: 3, deliveryCharge: 2, freeDeliveryFor: 50 },
  INR: { distanceUpto: 3, deliveryCharge: 50, freeDeliveryFor: 500 },
  AED: { distanceUpto: 3, deliveryCharge: 5, freeDeliveryFor: 100 }
};

export const languages = [
  { lng: 'en', name: 'English' },
  { lng: 'ta', name: 'Tamil' },
  { lng: 'ml', name: 'Malayalam' }
];
