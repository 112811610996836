import { addDoc, collection } from 'firebase/firestore';
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-phone-number-input/style.css';
import { db } from '../../../lib/firebase';
import { ArrowBack } from '@mui/icons-material';
const ContentBasic = ({
  prevPage,
  setPrevPage,
  setPage,
  placeName,
  location,
  setPlaceName,
  setLocation,
  setLeadId
}) => {
  const [isPlaceNameValid, setIsPlaceNameValid] = useState(true);
  const [islocation, setIsLocation] = useState(true);
  const [videoOpen, setVideoOpen] = useState(false);
  console.log(prevPage);
  const onContinue = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (placeName.length === 0 || location.length === 0) {
      placeName.length === 0 && setIsPlaceNameValid(false);
      location.length === 0 && setIsLocation(false);
      return;
    }
    try {
      const docRef = await addDoc(collection(db, 'leads'), {
        placeName: placeName,
        storeLocation: location
      });
      setLeadId(docRef.id);
      setPrevPage([...prevPage, 'create-basic']);
      setPage('show-stores');
      console.log('Document written with ID: ', docRef.id);
    } catch (error) {
      console.error('Error adding document:', error);
    }
  };
  const handleLocation = (e) => {
    setLocation(e.target.value);
  };
  const handleBack = () => {
    const prev = prevPage[prevPage.length - 1];
    setPage(prev);
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <form className="needs-validation" noValidate>
              <ArrowBack className="mb-5" onClick={handleBack} />
              <h3>Create Store</h3>
              <div className="mb-3">
                <label htmlFor="create-store-name">Name of the store *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="create-store-name"
                    style={isPlaceNameValid ? {} : { borderColor: 'red' }}
                    onFocus={() => setIsPlaceNameValid(true)}
                    value={placeName}
                    onChange={(e) => {
                      setPlaceName(e.target.value);
                    }}
                    placeholder="Store name"
                    autoComplete="new-name"
                    required
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="location">Location</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    style={islocation ? {} : { borderColor: 'red' }}
                    onFocus={() => setIsLocation(true)}
                    id="location"
                    value={location}
                    onChange={handleLocation}
                    placeholder="City, State"
                    required
                  />
                </div>
              </div>
              <button className="btn btn-primary mt-4 d-block w-100" onClick={onContinue}>
                Continue
              </button>
              <p className="mb-0 mt-4 text-center text-lg">
                <ModalVideo
                  channel="youtube"
                  autoplay
                  isOpen={videoOpen}
                  videoId={'7U-cP6ek_uI'}
                  onClose={() => setVideoOpen(false)}
                />
                <span role="button" className="btn-link" onClick={() => setVideoOpen(true)}>
                  Watch this quick video for help
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentBasic;
