import React, { useContext, useEffect, useState } from 'react';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { StateContext } from '../../../data/state/state-context';
import AddDeal from './AddDeal';
import DealItem from './DealItem';

const ItemDeals = ({ dealCategories, dealItems, setDealItems }) => {
  const [rootState, dispatch] = useContext(StateContext);
  const { items } = rootState.items;
  const [isAddNewVissible, toggleAddNew] = useState(false);
  useEffect(() => {
    // setDealItems from items which has dealPrice active
    const now = new Date().getTime();
    const _dealItems = items
      .map((itm) => {
        if (
          itm.dealValidFrom &&
          itm.dealValidTo &&
          typeof itm.dealValidFrom.toDate === 'function' &&
          typeof itm.dealValidTo.toDate === 'function' &&
          new Date(itm.dealValidFrom.toDate()).getTime() <
            new Date(itm.dealValidTo.toDate()).getTime() &&
          now < new Date(itm.dealValidTo.toDate()).getTime()
        ) {
          return itm;
        } else {
          return null;
        }
      })
      .filter((itm) => itm !== null);
    setDealItems(_dealItems);
  }, [items]);

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header new">
              <h6>Deals</h6>
              <button className="btn btn-primary d-block" onClick={() => toggleAddNew(true)}>
                Add New Deal
              </button>
            </div>
            <div className="ms-panel-body px-1 py-1">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Item Name</th>
                      <th scope="col">Actual Price</th>
                      <th scope="col">Deal Price</th>
                      <th scope="col">Valid From</th>
                      <th scope="col">Valid To</th>
                      <th scope="col">{/*Actions for deletion*/}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isAddNewVissible && (
                      <AddDeal
                        data={items}
                        toggleAddNew={toggleAddNew}
                        dispatch={dispatch}
                        dealItems={dealItems}
                        dealCategories={dealCategories}
                      />
                    )}
                    {dealItems.map((deal, idx) => (
                      <DealItem dispatch={dispatch} item={deal} key={`deal-item-${idx}`} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDeals;
