const generateGUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-mixed-operators
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

const validateEmail = (email) => {
  return /^\S+@\S+\.\S+$/.test(email);
};

const getUrlHost = (urlPrefix) => `${urlPrefix}.myferrypal.com`;

const removeWhiteSpaceAndReturnLowerCase = (str) => str.toLowerCase().replace(/ /g, '');

const isNumber = (n) => !isNaN(parseFloat(n)) && isFinite(n);

const getCountryCode = () => {
  // temporarily guess country code based on time zone (won't work for countries with DST)
  const offset = new Date().getTimezoneOffset();
  switch (offset) {
    case -330:
      return 'IN';
    case -240:
      return 'AE';
    default:
      return 'US';
  }
};

// addrComponents[].types => https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
//
// extract country short name (e.g. GB for Great Britain) from google geocode API result
const getCountryCodeFromAddress = (addrComponents = []) => {
  for (var i = 0; i < addrComponents.length; i++) {
    if (addrComponents[i].types[0] === 'country') {
      return addrComponents[i].short_name;
    }
  }
  return getCountryCode();
};

const getStateFromAddress = (addrComponents = []) => {
  for (var i = 0; i < addrComponents.length; i++) {
    if (addrComponents[i].types[0] === 'administrative_area_level_1') {
      return addrComponents[i].long_name;
    }
  }
  return '';
};

const getCityFromAddress = (addrComponents = []) => {
  for (var i = 0; i < addrComponents.length; i++) {
    if (addrComponents[i].types[0] === 'locality') {
      return addrComponents[i].long_name;
    }
  }
  return '';
};

const idfy = (name) => {
  if (!name) {
    return '';
  }

  return name.trim().toLowerCase().split(' ').join('-');
};

export {
  generateGUID,
  validateEmail,
  getUrlHost,
  removeWhiteSpaceAndReturnLowerCase,
  isNumber,
  idfy,
  getCountryCode,
  getCountryCodeFromAddress,
  getStateFromAddress,
  getCityFromAddress
};
