// Action Types

export const SET_USER = 'SET_USER';
export const POPUP_SWITCH_TEMP_USER = 'POPUP_SWITCH_TEMP_USER';
export const SET_STORE = 'SET_STORE';
export const UPDATE_STORE = 'UPDATE_STORE';
export const UPDATE_THEME = 'UPDATE_THEME';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const ADD_ITEM = 'ADD_ITEM';
export const LOAD_ITEMS = 'LOAD_ITEMS';
export const APPEND_ORDER = 'APPEND_ORDER';
export const TOGGLE_ORDER_ITEM_READY = 'TOGGLE_ORDER_ITEM_READY';
export const SET_ORDER_ACCEPTED_AT = 'SET_ORDER_ACCEPTED_AT';
export const SET_ORDER_REJECTED_AT = 'SET_ORDER_REJECTED_AT';
export const SET_EXPECTED_READY_TIME = 'SET_EXPECTED_READY_TIME';
export const SET_ORDER_PICKED_UP_AT = 'SET_ORDER_PICKED_UP_AT';
export const SET_ITEM_AVAILABILITY = 'SET_ITEM_AVAILABILITY';
export const SET_ITEM_SPECIAL = 'SET_ITEM_SPECIAL';
export const DELETE_ITEM = 'DELETE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const PROGRESS_MODAL = 'PROGRESS_MODAL';
export const TOGGLE_ISLOADING = 'TOGGLE_ISLOADING';
export const LOAD_IMPORTED_MENU_ITEMS = 'LOAD_IMPORTED_MENU_ITEMS';
export const UPDATE_IMPORTED_MENU_ITEM = 'UPDATE_IMPORTED_MENU_ITEM';
export const INIT_MULTI_MENU_ITEMS = 'INIT_MULTI_MENU_ITEMS';
export const LOAD_MULTI_MENU_ITEMS = 'LOAD_MULTI_MENU_ITEMS';
export const UPDATE_MULTI_MENU_ITEM = 'UPDATE_MULTI_MENU_ITEM';
export const UPDATE_LOCAL_LANGUAGE = 'UPDATE_LOCAL_LANGUAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
