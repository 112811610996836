import React from 'react';
import { Page, Image, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  pageBackground: {
    height: '113px',
    width: '193px'
  }
});

// Create Document Component
const PageOfSmartOrder = ({ cardImage, cardQrImage }) => {
  return (
    <Document>
      <Page size={[113]} orientation="landscape">
        <Image src={cardImage} style={styles.pageBackground} />
      </Page>
      <Page size={[113]} orientation="landscape">
        <Image src={cardQrImage} style={styles.pageBackground} />
      </Page>
    </Document>
  );
};

export default PageOfSmartOrder;
