import React, { useContext } from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Listcontent from './Listcontent';
import Quickbar from '../../../components/layouts/Quickbar';
import { StateContext } from '../../../data/state/state-context';

const InfoBar = () => {
  const [rootState] = useContext(StateContext);
  const { urlHost } = rootState.main;

  return (
    <div className="alert alert-success mx-4 mt-4 mb-0" role="alert">
      To view your store's website, go to{' '}
      <a href={`https://${urlHost}`} rel="noopener noreferrer" target="_blank">
        https://{urlHost}
      </a>
    </div>
  );
};

const Items = () => {
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <InfoBar />
        <Listcontent />
      </main>
      <Quickbar />
    </div>
  );
};

export default Items;
