import React from 'react';
import 'react-phone-number-input/style.css';
import { Link } from 'react-router-dom';

import Breadcrumb from './Breadcrumb';
import Steps from './Steps';

const Content = () => {
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb />
          <div className="alert alert-success" role="alert">
            Got an image or PDF of the menu? <Link to="/import-menu">If so, try Import Menu.</Link>
          </div>
        </div>
        <div className="col-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>Add Multiple Items</h6>
            </div>
            <div className="ms-panel-body ms-panel-fh">
              <Steps />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
