import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import App from './App';
import { useRootReducer, StateContext } from '../../../data/state/state-context';
import { MuiTheme } from '../../../theme/muitheme';
import { initializeServiceWorker } from '../actions';
import '../../../i18n';
import ProgressModal from '../../../shared/ProgressModal';
import NewUserChangePassword from '../../Login/components/NewUserChangePassword';

const Root = () => {
  const [rootState, dispatch] = useRootReducer();

  useEffect(() => {
    initializeServiceWorker();
  }, []);

  return (
    <StateContext.Provider value={[rootState, dispatch]}>
      <BrowserRouter basename={'/'}>
        <MuiThemeProvider theme={MuiTheme}>
          <App />
          <ProgressModal />
          <NewUserChangePassword />
        </MuiThemeProvider>
      </BrowserRouter>
    </StateContext.Provider>
  );
};

export default Root;
