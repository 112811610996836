const pexelsUrls = (data) => {
  /**
   * data => {
      "page": 1,
      "per_page": 5,
      "photos":[ {
        "id", "width", "height", "url", "photographer", "photographer_url", "photographer_id", "avg_color", "liked", "alt", "src": {
          "original", "large2x", "large", "medium", "small", "portrait", "landscape", "tiny"
        },
      }, ]
    }
   */
  return (
    data?.photos?.map(({ src }) => {
      return { target: src?.medium };
    }) ?? []
  );
};

const searchPexels = async (searchString) => {
  try {
    const response = await fetch(
      `https://api.pexels.com/v1/search?query=${encodeURIComponent(
        searchString
      )}&per_page=5&orientation=landscape`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: '563492ad6f91700001000001b1a9a8b0a2894fa9adc9ae26d5944e71',
          Accept: 'application/json'
        })
      }
    );
    if (response.status === 200) {
      const data = await response.json();
      return pexelsUrls(data);
    }
    return [];
  } catch (error) {
    console.log('fetchClientSecret Error => ', error.message);
  }
};

export { searchPexels };
