import React, { useCallback } from 'react';

const createNewId = () => new Date().getTime();

const OwnDelivery = ({
  hasOwnDelivery,
  setHasOwnDelivery,
  deliveryOptions,
  setDeliveryOptions,
  currency
}) => {
  const onUpdateInputValue = useCallback(
    (optionId, fieldName, value) => {
      const optionInfo = deliveryOptions.find((o) => o.id === optionId);
      if (optionInfo !== undefined) {
        optionInfo[fieldName] = value;
        const updatedOptions = deliveryOptions.map((option) =>
          option.id === optionId ? { ...optionInfo } : option
        );
        setDeliveryOptions(updatedOptions);
      }
    },
    [deliveryOptions, setDeliveryOptions]
  );

  const rendrClm = useCallback(
    (optionId, fieldName, value) => (
      <div className="d-md-inline-flex">
        <input
          className="form-control"
          value={value}
          onChange={(e) => onUpdateInputValue(optionId, fieldName, e.target.value)}
        />
      </div>
    ),
    [onUpdateInputValue]
  );

  const deleteItem = useCallback(
    (optionId) => {
      if (deliveryOptions && deliveryOptions.length > 1) {
        const updatedOptions = deliveryOptions.filter((o) => o.id !== optionId);
        setDeliveryOptions(updatedOptions);
      } else {
        // switch off own delivery
        setHasOwnDelivery(false);
      }
    },
    [deliveryOptions, setHasOwnDelivery, setDeliveryOptions]
  );

  const onPressMore = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (deliveryOptions && deliveryOptions.length > 0) {
        const lastOptnInfo = deliveryOptions[deliveryOptions.length - 1];
        const { distanceUpto, deliveryCharge, freeDeliveryFor } = lastOptnInfo;
        const newOption = {
          id: createNewId(),
          distanceUpto: distanceUpto * 2,
          deliveryCharge: deliveryCharge * 2,
          freeDeliveryFor: freeDeliveryFor * 2
        };
        const updatedOptions = [...deliveryOptions, newOption];
        setDeliveryOptions(updatedOptions);
      }
    },
    [deliveryOptions, setDeliveryOptions]
  );

  return (
    <div className="my-2">
      <div className="d-md-inline-flex">
        <label className="ms-switch">
          <input
            type="checkbox"
            checked={hasOwnDelivery}
            defaultChecked={hasOwnDelivery === true}
            onClick={(e) => {
              setHasOwnDelivery(e.target.checked);
            }}
          />
          <span className="ms-switch-slider ms-switch-success round" />
        </label>
        <span> We do delivery</span>
      </div>
      {hasOwnDelivery && (
        <div>
          <table className="table-striped">
            <thead>
              <tr>
                <th className="px-2">Distance upto (km)</th>
                <th className="px-2">Delivery charge ({currency})</th>
                <th className="px-2">Free delivery for order above ({currency})</th>
                <th className="px-2" />
              </tr>
            </thead>
            <tbody>
              {deliveryOptions.map((o, i) => (
                <tr key={`item-${i}`}>
                  <td className="px-2">{rendrClm(o.id, 'distanceUpto', o.distanceUpto)}</td>
                  <td className="px-2">{rendrClm(o.id, 'deliveryCharge', o.deliveryCharge)}</td>
                  <td className="px-2">{rendrClm(o.id, 'freeDeliveryFor', o.freeDeliveryFor)}</td>
                  <td className="px-2">
                    <div onClick={() => deleteItem(o.id)}>
                      <i className="far fa-trash-alt ms-text-danger" />
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={4} style={{ textAlign: 'center', paddingBottom: 5 }}>
                  <button
                    onClick={onPressMore}
                    type="button"
                    class="btn btn-outline-secondary btn-sm">
                    + More
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default OwnDelivery;
