import React, { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { getCountryCode } from '../../../utils';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { ArrowBack } from '@mui/icons-material';
const StoreContacts = ({
  prevPage,
  setPrevPage,
  setPage,
  leadId,
  placePhone,
  setPlacePhone,
  userEmail,
  setUserEmail
}) => {
  const [isPhone, setIsPhone] = useState(true);
  const [isEmail, setIsEmail] = useState(true);
  const [hasAtSymbol, setHasAtSymbol] = useState(false);
  const [countryCode, setCountryCode] = useState('IN');
  const phoneInputRef = useRef();

  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.style.borderWidth = 0; // style prop on the component gets ignored
    }

    setCountryCode(getCountryCode());
  }, []);
  const handleEmail = (e) => {
    const inputValue = e.target.value;

    setUserEmail(e.target.value);
    if (inputValue.length > 3 && inputValue.includes('@')) {
      setHasAtSymbol(true);
    } else {
      setHasAtSymbol(false);
    }
  };
  const handleCreateStore = async (event) => {
    event.preventDefault();
    if (placePhone.length === 0 || userEmail.length === 0) {
      // alert('enter the contact Info Properly');
      placePhone.length === 0 && setIsPhone(false);
      userEmail.length === 0 && setIsEmail(false);
      return;
    }
    const docRef = doc(db, 'leads', leadId);
    try {
      await updateDoc(docRef, {
        phoneNumber: placePhone,
        email: userEmail
      });
      console.log('Document updated with ID: ', leadId);
      setPrevPage([...prevPage, 'store-contacts']);
      setPage('payment');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };
  const handleBack = () => {
    const prev = prevPage[prevPage.length - 1];
    setPrevPage((prevItems) => prevItems.filter((item, i) => i !== prevItems.length - 1));
    setPage(prev);
  };
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <div className="m-3 pt-5">
              <ArrowBack className="mb-5" onClick={handleBack} />

              <h4>Awesome! We will get back to you in 24-48 hours.</h4>
              <h4>Where can we reach you?</h4>
              <div className=" d-flex flex-column justify-content-center align-content-center">
                <div className="mb-3">
                  <label htmlFor="whatsApp">Phone with WhatsApp</label>
                  <div
                    className="form-control"
                    style={isPhone ? {} : { borderColor: 'red' }}
                    onFocus={() => setIsPhone(true)}>
                    <PhoneInput
                      ref={phoneInputRef}
                      value={placePhone}
                      onChange={setPlacePhone}
                      defaultCountry={countryCode}
                      international={true}
                      id="whatsApp"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="email">Email</label>

                  <input
                    type="email"
                    id="email"
                    style={isEmail ? {} : { borderColor: 'red' }}
                    onFocus={() => setIsEmail(true)}
                    value={userEmail}
                    onChange={handleEmail}
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
                {userEmail.length > 3 && !hasAtSymbol && (
                  <span style={{ color: 'red' }}>Please include an '@' symbol in the email</span>
                )}

                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary w-50 m-3" onClick={handleCreateStore}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreContacts;
