import { createTheme } from '@mui/material/styles';

export const MuiTheme = createTheme({
  palette: {
    primary: {
      light: '#369d70',
      main: '#04854d',
      dark: '#025d35',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff9b37',
      main: '#ff8306',
      dark: '#b25b04',
      contrastText: '#000'
    }
  }
});
