import React from 'react';
import { Page, Image, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%'
  }
});

// Create Document Component
const PageOfTableSticker = ({ arrayOfMergedImages }) => {
  return (
    <Document>
      {arrayOfMergedImages.map((item, index) => (
        <Page size="A4" orientation="portrait" key={index}>
          <Image src={item.data} style={styles.pageBackground} />
        </Page>
      ))}
    </Document>
  );
};

export default PageOfTableSticker;
