import React from 'react';
import { getCategoryObj } from '../actions';

const ProductCategorySelect = ({ categories, selected, onSelect, ...props }) => {
  const onChange = (e) => {
    const categoryName = e.target.value;
    const category = getCategoryObj(categories, categoryName);
    onSelect(category);
  };

  return (
    <>
      <input {...props} list="datalistOptions" id="categoryList" onChange={onChange} value={selected?.name} placeholder="Type to search..." />
      <datalist id="datalistOptions">
        {categories.map((category) => (
          <option key={category.id}>{category.name}</option>
        ))}
      </datalist>
    </>
  );
};

export default ProductCategorySelect;
