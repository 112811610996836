import React, { useEffect, useState, useRef } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { ColorExtractor } from 'react-color-extractor';

import PlacesAutocomplete from './PlaceAutocomplete';
import StoreUrl from './StoreUrl';
import { getUrlHost, removeWhiteSpaceAndReturnLowerCase } from '../../../utils';
import { currencies } from '../../../data/constants';
import CurrencySelect from './Currency';

const SelectImage = ({ onImageChange }) => (
  <div className="mb-2">
    <label htmlFor="create-store-image">Store logo/image</label>
    <div className="input-group">
      <input
        type="file"
        className="form-control"
        id="create-store-image"
        placeholder="Select image..."
        required
        onChange={onImageChange}
      />
    </div>
    <div className="invalid-feedback">Invalid image</div>
  </div>
);

const StoreImage = ({ storeImgSrc, onRemoveImage }) => (
  <div className="mb-2">
    <div>
      <img className="d-block w-100" src={storeImgSrc} alt="Store" />
    </div>

    <div className="new">
      <button type="button" className="btn btn-secondary" onClick={onRemoveImage}>
        Remove
      </button>
    </div>
  </div>
);

const Content = ({ onCreateStore }) => {
  const [storeImgSrc, setStoreImgSrc] = useState(null);
  const [storeImgFile, setStoreImgFile] = useState(null);
  const [address, setAddress] = useState('');
  const [geocodedAddress, setGeocodedAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [placeName, setPlaceName] = useState('');
  const [urlPrefix, setUrlPrefix] = useState('');
  const [isUrlValid, setIsUrlValid] = useState(''); // 'valid' || 'invalid'
  const [currency, setCurrency] = useState('INR');
  const [countryCode, setCountryCode] = useState('IN');
  const [phNo, setPhNo] = useState();
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [isPlaceNameValid, setIsPlaceNameValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [colors, setColors] = useState([]);
  const phoneInputRef = useRef();

  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.style.borderWidth = 0; // style prop on the component gets ignored
    }
  }, []);

  useEffect(() => {
    //initialize datatable
    function formValidation() {
      window.addEventListener(
        'load',
        function () {
          // // Fetch all the forms we want to apply custom Bootstrap validation styles to
          // var forms = document.getElementsByClassName('needs-validation');
          // // Loop over them and prevent submission
          // var validation = Array.prototype.filter.call(forms, function (form) {
          //     form.addEventListener('submit', function (event) {
          //         if (form.checkValidity() === false) {
          //             event.preventDefault();
          //             event.stopPropagation();
          //         }
          //         form.classList.add('was-validated');
          //     }, false);
          // });
        },
        false
      );
    }
    formValidation();
  }, []);

  useEffect(() => {
    let _currency = 'INR';
    const currencyInfo = currencies.find((x) => x.countryCode === countryCode);
    if (currencyInfo) {
      _currency = currencyInfo.id;
    }
    setCurrency(_currency);
  }, [countryCode]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function () {
        setStoreImgSrc(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setStoreImgFile(event.target.files[0]);
      event.target.value = null; // clear the input field
    }
  };

  const onRemoveImage = () => {
    setStoreImgSrc(null);
    setStoreImgFile(null);
  };

  const onCreate = (event) => {
    // TODO: there is bootstrap validation that kicks in during submit
    // Need to see how to make it work even when we handle submit here
    event.preventDefault();
    event.stopPropagation();

    const locationInfo = {
      ...latLng,
      city,
      state,
      countryCode,
      geocodedAddress
    };
    const _storeImgFile = storeImgSrc ? storeImgFile : null;
    if (placeName.trim() !== '' && isUrlValid === 'valid') {
      const urlHost = getUrlHost(urlPrefix);
      onCreateStore(
        placeName,
        address,
        locationInfo,
        _storeImgFile,
        phNo,
        urlHost,
        currency,
        colors
      );
    } else {
      setIsPlaceNameValid(placeName.trim().length > 0);
      // setIsAddressValid(address.trim().length > 0);
    }
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <form className="needs-validation" noValidate>
              <h3>Create Store</h3>
              <p>Please enter store details to continue</p>
              <div className="mb-3">
                <label htmlFor="create-store-name">Name of the store *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="create-store-name"
                    style={isPlaceNameValid ? {} : { borderColor: 'red' }}
                    onFocus={() => setIsPlaceNameValid(true)}
                    value={placeName}
                    onChange={(e) => {
                      const _placeName = e.target.value;
                      const _urlPrefix = removeWhiteSpaceAndReturnLowerCase(_placeName);
                      setPlaceName(_placeName);
                      setUrlPrefix(_urlPrefix);
                    }}
                    placeholder="Store name"
                    autoComplete="new-name"
                    required
                  />
                  <div className="invalid-feedback">Please provide a valid name.</div>
                </div>
              </div>
              <StoreUrl
                urlPrefix={urlPrefix}
                setUrlPrefix={setUrlPrefix}
                isUrlValid={isUrlValid}
                setIsUrlValid={setIsUrlValid}
              />
              <PlacesAutocomplete
                setAddress={setAddress}
                setGeocodedAddress={(val) => {
                  setGeocodedAddress(val);
                  setIsAddressValid(val.trim().length > 0);
                }}
                setCity={setCity}
                setState={setState}
                placeholder="Address"
                setLatLng={setLatLng}
                defaultValue=""
                isValid={isAddressValid}
                onFocusInput={() => setIsAddressValid(true)}
                setCountryCode={setCountryCode}
              />
              <div className="mb-3">
                <label htmlFor="create-store-phone">Phone</label>
                <div className="form-control">
                  <PhoneInput
                    ref={phoneInputRef}
                    placeholder="Enter phone number"
                    value={phNo}
                    onChange={setPhNo}
                    defaultCountry={countryCode}
                    international={true}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="storecurrency">Currency</label>
                <div className="input-group">
                  <CurrencySelect selectedId={currency} onSelect={setCurrency} />
                  <div className="invalid-feedback">Please select a currency</div>
                </div>
              </div>
              {storeImgSrc !== null ? (
                <StoreImage storeImgSrc={storeImgSrc} onRemoveImage={onRemoveImage} />
              ) : (
                <SelectImage onImageChange={onImageChange} />
              )}
              <div className="mb-3">
                {storeImgSrc !== null && <ColorExtractor src={storeImgSrc} getColors={setColors} />}
              </div>
              <button className="btn btn-primary mt-4 d-block w-100" onClick={onCreate}>
                Create Store
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
