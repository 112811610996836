import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { validateEmail } from '../../../utils';
import { createUser } from '../actions';
import TermsAndConditons from './TermsAndConditions';

const Content = ({ dispatch, history }) => {
  const [userName, setUserName] = useState('');
  const [isUserNameValid, setUserNameValid] = useState(''); // 'valid' || 'invalid'
  const [userPassword, setUserPwd] = useState('');
  const [isUserPwdValid, setUserPwdValid] = useState(''); // 'valid' || 'invalid'
  const [agreeToTnc, setAgreeToTnc] = useState(false);
  const [isTncVisible, setTncVisible] = useState(false);

  useEffect(() => {
    //initialize datatable
    function formValidation() {
      window.addEventListener(
        'load',
        function () {
          // // Fetch all the forms we want to apply custom Bootstrap validation styles to
          // var forms = document.getElementsByClassName('needs-validation');
          // // Loop over them and prevent submission
          // var validation = Array.prototype.filter.call(forms, function (form) {
          //     form.addEventListener('submit', function (event) {
          //         if (form.checkValidity() === false) {
          //             event.preventDefault();
          //             event.stopPropagation();
          //         }
          //         form.classList.add('was-validated');
          //     }, false);
          // });
        },
        false
      );
    }
    formValidation();
  }, []);

  const onCreate = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const isValidEmail = validateEmail(userName);
    if (isValidEmail && userPassword) {
      createUser(dispatch, history, userName, userPassword);
    } else {
      if (!isValidEmail) {
        setUserNameValid('invalid');
      }
      if (!userPassword) {
        setUserPwdValid('invalid');
      }
    }
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col w-125">
          <div className="ms-auth-bg-with-logo " />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <form className="needs-validation justify-content-center" noValidate>
              <Link
                className=" btn  text-dark w-50"
                to="/default-login"
                style={{ height: '40px', borderRadius: '36px' }}>
                Login
              </Link>

              <Link
                className=" btn btn-primary  text-white w-50"
                to="/default-register"
                style={{ height: '40px', borderRadius: '36px' }}>
                Register
              </Link>

              <h3 className="my-3">Create Account</h3>
              <div className="form-row">
                <div className="col-md-12 ">
                  <label htmlFor="create-account-user-email">Email Address</label>
                  <div className="input-group ">
                    <input
                      type="email"
                      className={` is-${isUserNameValid} inp-field `}
                      id="create-account-user-email"
                      placeholder="Email Address"
                      required
                      value={userName}
                      onChange={(e) => {
                        const value = e.target.value;
                        const isValid = validateEmail(value);
                        if (isValid) {
                          setUserNameValid('valid');
                        } else {
                          setUserNameValid('');
                        }
                        setUserName(value);
                      }}
                    />
                    <div className={`invalid-feedback is-${isUserNameValid} `}>
                      Please provide a valid email.
                    </div>
                  </div>
                </div>
                <div className="col-md-12 ">
                  <label htmlFor="create-account-user-password">Password</label>
                  <div className="input-group ">
                    <input
                      type="password"
                      className={`is-${isUserPwdValid} inp-field `}
                      id="create-account-user-password"
                      placeholder="Password"
                      required
                      value={userPassword}
                      onChange={(e) => {
                        setUserPwd(e.target.value);
                        setUserPwdValid(''); // remove error display
                      }}
                    />
                    <div className={`invalid-feedback is-${isUserPwdValid}`}>
                      Please provide a password.
                    </div>
                  </div>
                </div>
              </div>
              <TermsAndConditons isVisible={isTncVisible} onClose={() => setTncVisible(false)} />
              <div className="form-group">
                <div className="form-check pl-0">
                  <div>
                    <label className="ms-checkbox-wrap">
                      <input
                        className="form-check-input"
                        checked={agreeToTnc}
                        onChange={() => {}}
                        onClick={(e) => {
                          setAgreeToTnc(e.target.checked);
                        }}
                        type="checkbox"
                        id="invalidCheck"
                        required
                      />
                      <i className="ms-checkbox-check" />
                    </label>
                    <span>
                      I agree to the
                      <Link
                        className="card-link pl-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setTncVisible(true);
                        }}
                        to="#">
                        terms and conditions
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <button
                className={`btn ${agreeToTnc ? 'btn-primary' : 'btn-secondary'} mt-4 d-block w-100`}
                onClick={onCreate}
                disabled={agreeToTnc !== true}>
                Create Account
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
