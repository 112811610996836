import React from 'react';

const StripeOnlinePayment = () => {
  return (
    <div className="container">
      <h3>Online payment</h3>
    </div>
  );
};

export default StripeOnlinePayment;
