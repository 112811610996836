import React, { useContext } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import MenuItems from './MenuItems';
import { StateContext } from '../../../data/state/state-context';

// Create Document Component
const PDFMenu = ({ coverImage }) => {
  const [rootState] = useContext(StateContext);
  const { categories, items } = rootState.items;
  const { placeName } = rootState.main;

  return (
    coverImage && (
      <>
        <div className="d-block p-2">
          <PDFDownloadLink
            document={<MenuItems coverImage={coverImage} categories={categories} items={items} />}
            fileName={`${placeName} Menu`}>
            {({ loading }) =>
              loading ? (
                <button type="button" class="btn btn-primary">
                  Please Wait..
                </button>
              ) : (
                <button type="button" class="btn btn-primary">
                  Download Menu
                </button>
              )
            }
          </PDFDownloadLink>
        </div>
      </>
    )
  );
};

export default PDFMenu;
