import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './pricingplan.css';

const startUpPlan = [
  'Printable Professional Menu',
  'Digital & QR Code Menu',
  'Deals and Promotions',
  'Menu Management',
  'Branded Website',
  'Unlimited Menu'
];
const proPlan = [
  'Everything In Startup Plan',
  '24/7 Customer Support',
  'Customer Notifications',
  'WhatsApp Ordering',
  'Takeaway/Delivery',
  'Online Ordering'
];
const premiumPlan = [
  'Everything In Pro Plan',
  'Canva Post Templates',
  '1:1 Account Manager',
  'Brand Consultation',
  'Your Own Domain',
  'Local SEO'
];

const PlanItem = ({
  planTitle,
  planFeatures,
  price,
  bgColor,
  pricingPlan,
  onChoosePlan,
  isSelected
}) => {
  return (
    <div className="pricing-plan">
      <h4 className="pricing-header" style={{ backgroundColor: bgColor }}>
        {planTitle}
      </h4>
      <ul className="pricing-features">
        {planFeatures.map((item, idx) => (
          <li className="pricing-features-item" key={`plan_feature_${idx}`}>
            <i className="fa fa-check mr-2" style={{ color: 'green' }}></i>
            <h6 style={{ textAlign: 'left' }}>{item}</h6>
          </li>
        ))}
      </ul>
      <div className='pricing'>
        {price === 0 ? <span className="pricing-price">Free Forever</span> : <>{pricingPlan}</>}
        <a
          href="#/"
          className={`pricing-button ${isSelected ? 'is-featured' : ''}`}
          onClick={onChoosePlan}>
          {isSelected ? 'Selected Plan' : 'Choose Plan'}
        </a>
      </div>
    </div>
  );
};

const SubscriptionPlan = ({ isMonthly, setIsMonthly }) => (
  <FormControl>
    <RadioGroup row>
      <FormControlLabel
        control={<Radio checked={isMonthly} onChange={(e) => setIsMonthly(true)} value="monthly" />}
        label="Monthly"
      />
      <FormControlLabel
        control={
          <Radio checked={!isMonthly} onChange={(e) => setIsMonthly(false)} value="yearly" />
        }
        label="Yearly"
      />
    </RadioGroup>
  </FormControl>
);

const SubscriptionSelection = ({ pricePlan, currency, frequency }) => (
  <>
    {pricePlan.discount > 0 && (
      <Typography
        fontSize={18}
        px={0.5}
        mr={0.5}
        sx={(theme) => ({
          color: 'red',
          verticalAlign: 'text-top',
          textDecoration: 'line-through'
        })}>
        {`${pricePlan.regular} ${currency}`}
      </Typography>
    )}
    <Typography color="green" fontWeight="bold" fontSize={18}>
      {`${(pricePlan.regular - pricePlan.discount).toFixed(2)} ${currency}`}
    </Typography>
    <Typography component="p" variant="caption">
      {frequency}
    </Typography>
  </>
);

const PricingPlan = ({
  pricing,
  currency,
  value,
  setValue,
  isMonthly,
  setIsMonthly,
  isUpgrading
}) => {
  useEffect(() => {
    if (isUpgrading) {
      setValue(1);
    }
  }, [isUpgrading, setValue]);

  const { starterOneTime, pro, premium } = pricing[currency];

  return (
    <div>
      <SubscriptionPlan isMonthly={isMonthly} setIsMonthly={setIsMonthly} />

      <div className="pricing-table">
        <PlanItem
          planTitle={'Startup Plan'}
          planFeatures={startUpPlan}
          price={1}
          bgColor="#34A551"
          pricingPlan={
            <SubscriptionSelection
              pricePlan={starterOneTime}
              currency={currency}
              frequency="One Time"
            />
          }
          isSelected={value === 0}
          onChoosePlan={() => setValue(0)}
        />
        <PlanItem
          planTitle={'Pro Plan'}
          planFeatures={proPlan}
          price={1}
          bgColor="#3DBBF3"
          pricingPlan={
            <SubscriptionSelection
              pricePlan={pro}
              currency={currency}
              frequency={isMonthly ? '/ Month' : '/ Year'}
            />
          }
          isSelected={value === 1}
          onChoosePlan={() => setValue(1)}
        />
        <PlanItem
          planTitle={'Premium Plan'}
          planFeatures={premiumPlan}
          price={1}
          bgColor="#EA2315"
          pricingPlan={
            <SubscriptionSelection
              pricePlan={premium}
              currency={currency}
              frequency={isMonthly ? '/ Month' : '/ Year'}
            />
          }
          isSelected={value === 2}
          onChoosePlan={() => setValue(2)}
        />
      </div>
    </div>
  );
};

export default PricingPlan;
