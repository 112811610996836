import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDealPrice, removeDealPrice } from '../../Items/actions';

const AddDeal = ({ data, toggleAddNew, dispatch, dealItems, dealCategories }) => {
  const [newItemInfo, setNewItemInfo] = useState({});
  const [dealPrice, setDealPrice] = useState({
    value: '',
    isValid: 'valid',
    errMsg: ''
  });
  const [validFrom, setValidFrom] = useState(new Date());
  const [validTo, setValidTo] = useState({
    value: new Date(),
    isValid: 'valid',
    errMsg: ''
  });

  const onChangeNewItem = useCallback(
    (name) => {
      // finding by name, TODO: if possible find by itemId
      const _newItemInfo = data.find((itm) => itm.itemName === name);
      const foundItem = dealItems.find((item) => item.itemName === name);
      if (_newItemInfo) {
        setNewItemInfo(_newItemInfo);
        if (foundItem) {
          const confirmBox = window.confirm(`do you want to replace '${name}'?`);
          if (confirmBox === true) {
            removeDealPrice(dispatch, foundItem);
          } else {
            setNewItemInfo({});
            return;
          }
        }
      } else {
        setNewItemInfo({});
      }
    },
    [data, dealItems, dispatch]
  );

  const validateAndSaveData = useCallback(
    async (e) => {
      e.preventDefault();
      const _dealPrice = { isValid: 'valid', errMsg: '' };
      const _validTo = { isValid: 'valid', errMsg: '' };
      const dealPriceVal = Number(dealPrice.value);
      const ExistingItemDeal = dealCategories.find((item) => item.id === newItemInfo.category);
      if (ExistingItemDeal) {
        alert(
          `There is an existing deal for this Item's Category: ${newItemInfo.category}. Please note, Item discount will overrite Category discount.`
        );
      }
      // validate deal price
      if (!newItemInfo.price) {
        _dealPrice.isValid = 'invalid';
        _dealPrice.errMsg = 'Actual price not found';
      } else {
        const isValidDealPrice = !isNaN(dealPriceVal) && !isNaN(parseFloat(dealPriceVal));
        if (isValidDealPrice) {
          if (Number(newItemInfo.price) <= Number(dealPriceVal)) {
            _dealPrice.isValid = 'invalid';
            _dealPrice.errMsg = 'Deal Price >= Actual Price';
          }
        } else {
          _dealPrice.isValid = 'invalid';
          _dealPrice.errMsg = 'Invalid deal price';
        }
      }

      // validate todate
      const isToDateValid = new Date(validFrom).getTime() < new Date(validTo.value).getTime();
      if (!isToDateValid) {
        _validTo.isValid = 'invalid';
        _validTo.errMsg = 'Valid to <= Valid from';
      }

      setDealPrice({ ...dealPrice, ..._dealPrice });
      setValidTo({ ...validTo, ..._validTo });

      if (_dealPrice.isValid === 'valid' && _validTo.isValid === 'valid') {
        await addDealPrice(
          dispatch,
          newItemInfo,
          parseFloat(dealPrice.value) || 0,
          validFrom,
          validTo.value
        );
        toggleAddNew(false);
      }
    },
    [dispatch, dealPrice, newItemInfo, validFrom, validTo, toggleAddNew]
  );

  return (
    <tr>
      <td>
        <input
          className="form-control"
          list="datalistOptions"
          id="addNewItemInput"
          placeholder="Type to search..."
          onChange={(e) => {
            onChangeNewItem(e.target.value);
          }}
        />
        <datalist id="datalistOptions">
          {data.map((item, idx) => (
            <option key={`data-${idx}`} value={item.itemName} />
          ))}
        </datalist>
      </td>
      <td>
        <div>{newItemInfo.price ? Number(newItemInfo.price).toFixed(2) : ''}</div>
      </td>
      <td>
        <div className="input-group mb-0">
          <input
            type="text"
            className={`form-control ${dealPrice.isValid === 'invalid' && 'is-invalid'}`}
            id="deal-price"
            placeholder="0.00"
            autoComplete="new-price"
            required
            onChange={(e) => {
              setDealPrice({
                value: e.target.value,
                isValid: 'valid'
              });
            }}
          />
          <div className="invalid-feedback">{dealPrice.errMsg}</div>
        </div>
      </td>
      <td>
        <DatePicker selected={validFrom} onChange={setValidFrom} showTimeSelect dateFormat="Pp" />
      </td>
      <td>
        <div
          className={`form-control ${validTo.isValid === 'invalid' && 'is-invalid'} p-0`}
          style={{ height: 'auto' }}>
          <DatePicker
            selected={validTo.value}
            onChange={(date) => {
              setValidTo({ value: date, isValid: 'valid' });
            }}
            showTimeSelect
            dateFormat="Pp"
          />
        </div>
        <div className="invalid-feedback">{validTo.errMsg}</div>
      </td>
      <td>
        <div>
          <button
            style={{
              margin: 1,
              outline: 'none',
              paddingTop: 2,
              padding: 10
            }}
            className="btn-secondary btn-sm"
            onClick={() => toggleAddNew(false)}>
            Cancel
          </button>
          <button
            style={{
              margin: 1,
              outline: 'none',
              paddingTop: 2,
              padding: 10
            }}
            className="btn-primary btn-sm"
            onClick={validateAndSaveData}>
            Save
          </button>
        </div>
      </td>
    </tr>
  );
};

export default AddDeal;
