import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { StateContext } from '../../../data/state/state-context';
import { toggleIsLoading } from '../../Loading/actions';
import {
  addPlaceIdToSalesMan,
  updateAgentSubscription,
  updateFreeSubscription,
  verifyAgent
} from '../actions';
import AgentLogin from './AgentLogin';
import StripeOnlinePayment from './Stripe';
import settings from '../../../../config/settings';
import PricingPlan from './PricingPlan';

const annualPricing = {
  USD: {
    starterOneTime: { regular: 39.99, discount: 20 },
    pro: { regular: 79, discount: 30 },
    premium: { regular: 159, discount: 0 }
  },
  CAD: {
    starterOneTime: { regular: 49.99, discount: 25 },
    pro: { regular: 99, discount: 40 },
    premium: { regular: 199, discount: 0 }
  },
  INR: {
    starterOneTime: { regular: 3000, discount: 1500 },
    pro: { regular: 7200, discount: 1700 },
    premium: { regular: 14400, discount: 3900 }
  },
  AED: {
    starterOneTime: { regular: 199, discount: 100 },
    pro: { regular: 399, discount: 150 },
    premium: { regular: 799, discount: 0 }
  }
};

const monthlyPricing = {
  USD: {
    starterOneTime: { regular: 39.99, discount: 20 },
    pro: { regular: 7.99, discount: 2 },
    premium: { regular: 15.99, discount: 0 }
  },
  CAD: {
    starterOneTime: { regular: 49.99, discount: 25 },
    pro: { regular: 9.99, discount: 3 },
    premium: { regular: 19.99, discount: 0 }
  },
  INR: {
    starterOneTime: { regular: 3000, discount: 1500 },
    pro: { regular: 600, discount: 100 },
    premium: { regular: 1200, discount: 200 }
  },
  AED: {
    starterOneTime: { regular: 199, discount: 100 },
    pro: { regular: 39, discount: 15 },
    premium: { regular: 79, discount: 0 }
  }
};

const Content = () => {
  const history = useHistory();
  const [rootState, dispatch] = useContext(StateContext);
  const [payMode, setPayMode] = useState('online'); // agent || online
  const [clientSecret, setClientSecret] = useState('');
  const [pricingValue, setPricingValue] = useState(0); // 0, 1, 2
  const [isMonthly, setIsMonthly] = useState(true);

  const { state } = useLocation();
  const isUpgrading = state && state.isUpgrading ? true : false;

  const pricing = useMemo(() => (isMonthly ? monthlyPricing : annualPricing), [isMonthly]);

  const { placeId, currency } = rootState.main;
  const { items } = rootState.items;

  const proceedWithAgentLogin = useCallback(
    async (agentId, pinNumber, urlHost2) => {
      try {
        toggleIsLoading(dispatch, true); // start spinnig
        const { salesManId, placesBySalesMan } = await verifyAgent(agentId, pinNumber);
        if (salesManId) {
          // agent is valid
          if (placesBySalesMan.indexOf(placeId) === -1) {
            placesBySalesMan.push(placeId);
            await addPlaceIdToSalesMan(salesManId, placesBySalesMan);
          }

          await updateAgentSubscription(placeId, agentId, urlHost2, pricingValue, isMonthly);
          if (items && items.length > 0) {
            history.push('/');
          } else {
            history.push('/import-online-store');
          }
        }
        toggleIsLoading(dispatch, false); // stop spinnig
      } catch (error) {
        toggleIsLoading(dispatch, false); // stop spinnig
        console.log('proceedWithAgentLogin Error : ', error.message);
      }
    },
    [dispatch, placeId, history, pricingValue, isMonthly, items]
  );

  const proceedWithFreePlan = useCallback(async () => {
    try {
      toggleIsLoading(dispatch, true); // start spinnig
      await updateFreeSubscription(placeId);
      toggleIsLoading(dispatch, false); // stop spinnig
      if (items && items.length > 0) {
        history.push('/');
      } else {
        history.push('/import-online-store');
      }
    } catch (error) {
      toggleIsLoading(dispatch, false); // stop spinnig
      console.log('proceedWithFreePlan Error : ', error.message);
    }
  }, [dispatch, placeId, history, items]);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        let amount = 0;
        switch (pricingValue) {
          case 0:
            amount = Math.floor(
              (pricing[currency].starterOneTime.regular -
                pricing[currency].starterOneTime.discount) *
                100
            );
            break;
          case 1:
            amount = Math.floor(
              (pricing[currency].pro.regular - pricing[currency].pro.discount) * 100
            );
            break;
          case 2:
            amount = Math.floor(
              (pricing[currency].premium.regular - pricing[currency].premium.discount) * 100
            );
            break;
          default:
            break;
        }

        if (!amount) {
          return;
        }

        const response = await fetch(
          `${settings.croquetUrl}/stripe-clientkey?amount=${encodeURIComponent(
            amount
          )}&currency=${encodeURIComponent(currency)}`
        );
        if (response.status === 200) {
          let data = await response.json();
          setClientSecret(data.clientSecret);
        }
      } catch (error) {
        console.log('fetchClientSecret Error => ', error.message);
      }
    };

    fetchClientSecret();
  }, [currency, pricingValue, pricing]);

  return (
    <div className="m-3">
      {isUpgrading && (
        <div style={{ paddingTop: 10 }}>
          <Button onClick={() => history.push('/')}>Go Back</Button>
        </div>
      )}
      <form className="needs-validation m-2 text-center" noValidate>
        <h3 className="m-3">Choose a plan</h3>
        <PricingPlan
          pricing={pricing}
          currency={currency}
          value={pricingValue}
          setValue={setPricingValue}
          isMonthly={isMonthly}
          setIsMonthly={setIsMonthly}
          isUpgrading={isUpgrading}
        />
        {false && pricingValue === 0 && isUpgrading !== true && (
          <button
            className={`btn btn-primary btn-block`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              proceedWithFreePlan();
            }}>
            Proceed
          </button>
        )}
      </form>

      <form className="needs-validation m-4" noValidate>
        {true && (
          <>
            <h3 className="text-center">Payment</h3>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <div
                  className={`nav-link ${payMode === 'online' ? 'active' : ''}`}
                  onClick={() => setPayMode('online')}>
                  Pay Online
                </div>
              </li>
              <li className="nav-item">
                <div
                  className={`nav-link ${payMode === 'agent' ? 'active' : ''}`}
                  onClick={() => setPayMode('agent')}>
                  Pay Agent
                </div>
              </li>
            </ul>
            {payMode === 'online' && clientSecret !== '' && (
              <StripeOnlinePayment
                placeId={placeId}
                clientSecret={clientSecret}
                subscriptionType={pricingValue}
                isMonthly={isMonthly}
                items={items}
              />
            )}
            {payMode === 'agent' && <AgentLogin onSubmit={proceedWithAgentLogin} />}
          </>
        )}
      </form>
    </div>
  );
};

export default Content;
