import React, { useContext, useEffect, useState } from 'react';

import { StateContext } from '../../../data/state/state-context';
import { db } from '../../../lib/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { WhatsApp } from '@mui/icons-material';
import ReactPaginate from 'react-paginate';
const CustomerRow = ({ customer, keyIndex }) => {
  return (
    <tr key={keyIndex}>
      <td>{customer.displayName}</td>
      <td>{customer.phoneNumber}</td>
      <td>
        {' '}
        <a href={`https://wa.me/${customer.phoneNumber}`} target="_blank" rel="noopener noreferrer">
          <WhatsApp />
        </a>
      </td>
    </tr>
  );
};
const DealList = () => {
  const [rootState] = useContext(StateContext);
  const { placeId } = rootState.main;
  const [customers, setCustomers] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const dataPerPage = 20;
  const pagesVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(customers.length / dataPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const fetchCustomer = async () => {
    let resList = [];
    const q = query(collection(db, 'users'), where(`places.${placeId}`, '>', 0));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const { displayName, phoneNumber, places } = doc.data();
      resList.push({ displayName: displayName, phoneNumber: phoneNumber, places: places });
    });

    let myobject = resList.filter((obj) => {
      return typeof obj.places === 'object' && obj.places.hasOwnProperty(`${placeId}`);
    });

    setCustomers(myobject);
  };

  useEffect(() => {
    fetchCustomer();
  }, []);
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header new">
              <h6>Customers</h6>
            </div>
            <div className="ms-panel-body px-1 py-1">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">WhatsApp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.slice(pagesVisited, pagesVisited + dataPerPage).map((item, i) => (
                      //   customer row
                      <CustomerRow customer={item} keyIndex={i} key={i} />
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={'pagination  justify-content-center'}
                  pageClassName={'page-item mx-2'}
                  pageLinkClassName={'page-link'}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  disabledClassName={'disabled'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealList;
