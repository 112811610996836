import React from "react";
import "react-phone-number-input/style.css";

import Steps from './Steps';

const Content = () => {
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">
                    <h6>Import menu</h6>
                </div>
                <div className="ms-panel-body ms-panel-fh">
                    <Steps />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
