import React from 'react';

import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { getConfigValue } from '../../../../config/configurations';
import settings from '../../../../config/settings';
import { updateOnlineSubscription } from '../actions';

const pKey = getConfigValue('stripe', 'pKey');

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(pKey);

const CheckoutForm = ({ placeId, subscriptionType, isMonthly, items }) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: settings.storeUrl
      }
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      const { type: errType, message } = result.error;
      const errMessage =
        errType === 'card_error' || errType === 'validation_error' ? message : 'Card failed';
      alert(errMessage);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      // If redirect is "not required", then we will be here
      if (result.paymentIntent?.status === 'succeeded') {
        await updateOnlineSubscription(
          placeId,
          result.paymentIntent.id,
          'card',
          subscriptionType,
          isMonthly
        );
        if (items && items.length > 0) {
          history.push('/');
        } else {
          history.push('/import-online-store');
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ margin: 20 }}>
        <PaymentElement />
      </div>
      <button
        className={`btn mt-4 d-block w-100 ${!stripe ? 'btn-secondary' : 'btn-primary'}`}
        disabled={!stripe}>
        Submit
      </button>
    </form>
  );
};

const StripeOnlinePayment = ({ placeId, clientSecret, subscriptionType, isMonthly, items }) => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret
  };

  return (
    <div className="ms-wizard-step">
      <div>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            placeId={placeId}
            subscriptionType={subscriptionType}
            isMonthly={items}
            items={items}
          />
        </Elements>
      </div>
    </div>
  );
};

export default StripeOnlinePayment;
