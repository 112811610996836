import React from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { getCityFromAddress, getCountryCodeFromAddress, getStateFromAddress } from '../../../utils';

const PlacesAutocomplete = ({
  setAddress,
  setGeocodedAddress,
  setCity,
  setState,
  placeholder,
  setLatLng,
  defaultValue = '',
  isValid,
  onFocusInput,
  setCountryCode
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
    defaultValue
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    setAddress(e.target.value);
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  /* handleSelect gets called with following details
   {
      "description": "The Dubai Mall Bus Station - D71 - Dubai - United Arab Emirates",
      "place_id": "ChIJ20rnoe5DXz4R-qV8jc-ax4I",
      "structured_formatting": {
        "main_text": "The Dubai Mall Bus Station",
        "secondary_text": "D71 - Dubai - United Arab Emirates"
      },
      "types": [
        "point_of_interest",
        "establishment"
      ]
    }
   */
  const handleSelect =
    ({ description }) =>
    () => {
      setGeocodedAddress(description);
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => {
          if (results[0] && results[0].address_components) {
            const countryCode = getCountryCodeFromAddress(results[0].address_components);
            setCountryCode(countryCode);
            const city = getCityFromAddress(results[0].address_components);
            setCity(city);
            const state = getStateFromAddress(results[0].address_components);
            setState(state);
          }
          return getLatLng(results[0]);
        })
        .then(({ lat, lng }) => {
          setLatLng({ lat, lng });
          console.log('📍 Coordinates: ', { lat, lng });
        })
        .catch((error) => {
          console.log('😱 Error: ', error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li key={place_id} className="list-group-item" onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref} className="mb-2">
      <label htmlFor="create-store-address">Address *</label>
      <div className="input-group">
        <input
          value={value}
          onChange={handleInput}
          style={isValid === true ? {} : { borderColor: 'red' }}
          disabled={!ready}
          placeholder={placeholder}
          className="form-control"
          id="create-store-address"
          autoComplete="new-address"
          onFocus={onFocusInput}
        />
      </div>
      <div className="invalid-feedback">Please provide a valid address.</div>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <ul className="list-group">{renderSuggestions()}</ul>}
    </div>
  );
};

export default PlacesAutocomplete;
