import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';

import { db } from '../../lib/firebase';
import { setStoreAction } from '../CreateStore/actions';
import { defaultCategories } from '../../data/constants';
import { loadCategoriesAction, loadItemsAction, sortItemsByCategoryOrder } from '../Login/actions';

const loadStoreInfo = async (history, dispatch, slug) => {
  // get store info
  let placeDoc = null;
  const q = query(collection(db, 'places'), where('urlHost', '==', `${slug}.myferrypal.com`));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    placeDoc = querySnapshot.docs[0];
  }

  if (placeDoc === null) {
    history.push('/error');
    return;
  }

  const placeId = placeDoc.id;
  const {
    placeName,
    workingHoursText,
    imageSrc,
    verified,
    locationInfo,
    phoneNumber,
    geohash,
    address,
    hasOnlineOrdering,
    onlineOrderingSchedule,
    hasOwnDelivery,
    deliveryOptions,
    hasDunzoDelivery,
    urlHost,
    currency,
    theme,
    colors,
    primaryColor,
    secondaryColor,
    subscriptionType
  } = placeDoc.data();
  dispatch(
    setStoreAction(
      placeId,
      placeName,
      workingHoursText,
      imageSrc,
      verified,
      locationInfo,
      geohash,
      phoneNumber,
      address,
      hasOnlineOrdering ?? true,
      onlineOrderingSchedule ?? {},
      hasOwnDelivery,
      deliveryOptions,
      hasDunzoDelivery,
      urlHost,
      currency,
      theme,
      colors ?? [],
      primaryColor ?? '',
      secondaryColor ?? '',
      subscriptionType ?? 1
    )
  );

  const q3 = query(collection(db, 'categories'), where('placeId', '==', placeId), orderBy('order'));
  const querySnapshot3 = await getDocs(q3);
  const categories = [];
  querySnapshot3.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, ' => ', doc.data());
    const { categoryId, categoryName, order } = doc.data();
    categories.push({
      id: categoryId,
      name: categoryName,
      order
    });
  });
  dispatch(loadCategoriesAction(categories.length > 0 ? categories : defaultCategories));

  const q2 = query(collection(db, 'items'), where('placeId', '==', placeId));
  const querySnapshot2 = await getDocs(q2);
  const items = [];
  querySnapshot2.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, ' => ', doc.data());
    const {
      itemName,
      description,
      imageSrc,
      available,
      ourSpecial,
      price,
      category,
      dealPrice,
      dealValidFrom,
      dealValidTo
    } = doc.data();
    items.push({
      itemId: doc.id,
      itemName,
      description,
      imageSrc,
      available,
      ourSpecial,
      price,
      category,
      dealPrice,
      dealValidFrom,
      dealValidTo
    });
  });
  dispatch(loadItemsAction(sortItemsByCategoryOrder(items, categories)));
};

export { loadStoreInfo };
