import React from 'react';
import ProductCategorySelect from './ProductCategorySelect';

const ProductCategories = ({ categories, selected, onSelect }) => (
  <div className="col-md-12 mb-3">
    <label htmlFor="validationCustom23">Category</label>
    <div className="input-group">
      <ProductCategorySelect
        className="form-control"
        categories={categories}
        selected={selected}
        onSelect={onSelect}
      />
      <div className="invalid-feedback">Please select a Category</div>
    </div>
  </div>
);

export default ProductCategories;
