import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import TopNavigationEx from '../../Root/components/TopNavigationEx';
import ContentEx from './ContentEx';
import LoadingSpinner from '../../../shared/loadingspinner';
import CoverImage from '../../PrintableMenu/components/CoverImage';
import { StateContext } from '../../../data/state/state-context';
import { toggleIsLoading } from '../../Loading/actions';
import { loadStoreInfo } from '../actions';
import { analytics } from '../../../lib/firebase';

const backgroundImage1 = encodeURI('https://m.qrmeu.com/assets/menu-cover1.png');
const backgroundImage2 = encodeURI('https://m.qrmeu.com/assets/menu-cover2.png');
const backgroundImage3 = encodeURI('https://m.qrmeu.com/assets/menu-cover3.png');
const backgroundImage4 = encodeURI('https://m.qrmeu.com/assets/menu-cover4.png');
const backgroundImage5 = encodeURI('https://m.qrmeu.com/assets/menu-cover5.png');
const backgroundImage6 = encodeURI('https://m.qrmeu.com/assets/menu-cover6.png');
const backgroundImage7 = encodeURI('https://m.qrmeu.com/assets/menu-cover7.png');
const backgroundImage8 = encodeURI('https://m.qrmeu.com/assets/menu-cover8.png');
const backgroundImage9 = encodeURI('https://m.qrmeu.com/assets/menu-cover9.png');
const backgroundImage10 = encodeURI('https://m.qrmeu.com/assets/menu-cover10.png');
const backgroundImage11 = encodeURI('https://m.qrmeu.com/assets/menu-cover11.png');
const backgroundImage12 = encodeURI('https://m.qrmeu.com/assets/menu-cover12.png');
const backgroundImage13 = encodeURI('https://m.qrmeu.com/assets/menu-cover13.png');
const backgroundImage14 = encodeURI('https://m.qrmeu.com/assets/menu-cover14.png');
const backgroundImage15 = encodeURI('https://m.qrmeu.com/assets/menu-cover15.png');
const backgroundImage16 = encodeURI('https://m.qrmeu.com/assets/menu-cover16.png');
const backgroundImage17 = encodeURI('https://m.qrmeu.com/assets/menu-cover17.png');

/**
 * To add new menu designs here:
 * - Create a background image
 *   -- tip:
 *   -- make a copy of existing image like menu-cover2.png and change
 *   -- so far the diff between designs are just the left side of the image
 *   -- so you could just paste a new design on the left side and make it align
 * - Then 1. import that image, 2. add a `CoverImage` comp below, 3. update coverImages attribute of `Content`
 * - That's all!
 */

const PrintableMenu = () => {
  const history = useHistory();
  const { slug } = useParams();
  const [rootState, dispatch] = useContext(StateContext);
  const { placeName, urlHost } = rootState.main;
  const [imageData1, setImageData1] = useState(null);
  const [imageData2, setImageData2] = useState(null);
  const [imageData3, setImageData3] = useState(null);
  const [imageData4, setImageData4] = useState(null);
  const [imageData5, setImageData5] = useState(null);
  const [imageData6, setImageData6] = useState(null);
  const [imageData7, setImageData7] = useState(null);
  const [imageData8, setImageData8] = useState(null);
  const [imageData9, setImageData9] = useState(null);
  const [imageData10, setImageData10] = useState(null);
  const [imageData11, setImageData11] = useState(null);
  const [imageData12, setImageData12] = useState(null);
  const [imageData13, setImageData13] = useState(null);
  const [imageData14, setImageData14] = useState(null);
  const [imageData15, setImageData15] = useState(null);
  const [imageData16, setImageData16] = useState(null);
  const [imageData17, setImageData17] = useState(null);
  const [filter, setFilter] = useState('popular');
  const [coverImages, setCoverImages] = useState([]);

  useEffect(() => {
    const popularCoverImages = [
      {
        title: 'Bold',
        descr: 'Makes a bold stance as your yummy burger',
        data: imageData1
      },
      {
        title: 'Playful',
        descr: 'As playful as your young customers can imagine',
        data: imageData2
      },
      {
        title: 'Hotbun',
        descr: 'For the eyes of your craving customer',
        data: imageData3
      },
      { title: 'Elegant', descr: 'As elegant as it can be', data: imageData4 },
      { title: 'Juicy', descr: 'As juicy as your juices', data: imageData5 },
      { title: 'Cool Dark', descr: 'Plain, dark and simple', data: imageData6 }
    ];

    const latestCoverImages = [
      {
        title: 'Cheesy',
        descr: 'Yum and cheesy',
        data: imageData7
      },
      {
        title: 'Pizzalicious',
        descr: 'Delliciously pizza',
        data: imageData8
      },
      {
        title: 'Cookie Jar',
        descr: 'Mouthwatering treats for your taste',
        data: imageData9
      },
      { title: 'Wakeup', descr: 'Coffee forever', data: imageData10 },
      { title: 'Spicy', descr: 'Yummy spicy and tasty', data: imageData11 },
      { title: 'Veggie Lover', descr: 'Greens for everyones liking', data: imageData12 }
    ];

    const allCoverImages = [
      ...popularCoverImages,
      ...latestCoverImages,
      {
        title: 'Noodly',
        descr: 'Noodles and more just for you',
        data: imageData13
      },
      {
        title: 'Munch',
        descr: 'Snack time, yaas!',
        data: imageData14
      },
      {
        title: 'Tradition',
        descr: 'Bring you back memories',
        data: imageData15
      },
      { title: 'Breaktime', descr: 'Breaktime with pappad :)', data: imageData16 },
      { title: 'Cool', descr: 'Cools not just your eyes', data: imageData17 }
    ];

    let loading = false;
    switch (filter) {
      case 'popular':
        setCoverImages(popularCoverImages);
        loading = popularCoverImages.some(({ data }) => !data);
        break;

      case 'latest':
        setCoverImages(latestCoverImages);
        loading = latestCoverImages.some(({ data }) => !data);
        break;

      case 'all':
        setCoverImages(allCoverImages);
        loading = allCoverImages.some(({ data }) => !data);
        break;

      default:
        break;
    }
    toggleIsLoading(dispatch, loading);

    if (!loading) {
      // doing it when loading is done to avoid too many calls of logEvent
      logEvent(analytics, 'pint_menu_ex', { filter });
    }

    return () => toggleIsLoading(dispatch, false);
  }, [
    dispatch,
    setCoverImages,
    filter,
    imageData1,
    imageData2,
    imageData3,
    imageData4,
    imageData5,
    imageData6,
    imageData7,
    imageData8,
    imageData9,
    imageData10,
    imageData11,
    imageData12,
    imageData13,
    imageData14,
    imageData15,
    imageData16,
    imageData17
  ]);

  useEffect(() => {
    loadStoreInfo(history, dispatch, slug);
  }, [history, dispatch, slug]);

  if (!placeName) {
    return null;
  }

  return (
    <div style={{ backgroundColor: '#f0f0fa' }}>
      <div className="ms-body ms-primary-theme">
        <main className="body-content">
          <TopNavigationEx slug={slug} />
          <ContentEx coverImages={coverImages} filter={filter} setFilter={setFilter} />
          <LoadingSpinner asOverlay />
        </main>
      </div>

      {/* placing the section below outside of ms-body panel to generate image with a size that that fits well on A4 page */}
      {(filter === 'popular' || filter === 'all') && (
        <div style={{ opacity: 0 }}>
          <CoverImage
            setImageData={setImageData1}
            BackgroundImage={backgroundImage1}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData2}
            BackgroundImage={backgroundImage2}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData3}
            BackgroundImage={backgroundImage3}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData4}
            BackgroundImage={backgroundImage4}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData5}
            BackgroundImage={backgroundImage5}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData6}
            BackgroundImage={backgroundImage6}
            placeName={placeName}
            urlHost={urlHost}
          />
        </div>
      )}
      {(filter === 'latest' || filter === 'all') && (
        <div style={{ opacity: 0 }}>
          <CoverImage
            setImageData={setImageData7}
            BackgroundImage={backgroundImage7}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData8}
            BackgroundImage={backgroundImage8}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData9}
            BackgroundImage={backgroundImage9}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData10}
            BackgroundImage={backgroundImage10}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData11}
            BackgroundImage={backgroundImage11}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData12}
            BackgroundImage={backgroundImage12}
            placeName={placeName}
            urlHost={urlHost}
          />
        </div>
      )}
      {filter === 'all' && (
        <div style={{ opacity: 0 }}>
          <CoverImage
            setImageData={setImageData13}
            BackgroundImage={backgroundImage13}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData14}
            BackgroundImage={backgroundImage14}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData15}
            BackgroundImage={backgroundImage15}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData16}
            BackgroundImage={backgroundImage16}
            placeName={placeName}
            urlHost={urlHost}
          />
          <CoverImage
            setImageData={setImageData17}
            BackgroundImage={backgroundImage17}
            placeName={placeName}
            urlHost={urlHost}
          />
        </div>
      )}
    </div>
  );
};

export default PrintableMenu;
