import * as ActionTypes from '../../data/state/action-types';

const initialState = {
  trips: [
    /* {
    tripId,
    createdAt,
    userId,
    orderCreatedBy,
    orderCreatedByPhone,
    when,
    notes,
    acceptedByPlaceAt,
    rejectedByPlaceAt,
    expectedOrderReadyTime,
    pickedUpAt,
    isOrderDelivery,
    orderDelivery,
    orderPickup,
    subOrders: {
      orderFor: [
        { orderItemId, itemId, itemName, notes, quantity, price, orderReady }
      ]
    }
  } */
  ]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.APPEND_ORDER:
      // patch for bug: append gets called several times with same trip info
      if (state.trips.find((t) => t.tripId === action.tripId)) {
        return { ...state };
      }

      return {
        ...state,
        trips: [
          ...state.trips,
          {
            tripId: action.tripId,
            createdAt: action.createdAt,
            userId: action.userId,
            orderCreatedBy: action.orderCreatedBy,
            orderCreatedByPhone: action.orderCreatedByPhone,
            when: action.when,
            notes: action.notes,
            acceptedByPlaceAt: action.acceptedByPlaceAt,
            rejectedByPlaceAt: action.rejectedByPlaceAt,
            expectedOrderReadyTime: action.expectedOrderReadyTime,
            pickedUpAt: action.pickedUpAt,
            isOrderDelivery: action.isOrderDelivery,
            orderDelivery: action.orderDelivery,
            orderPickup: action.orderPickup,
            subOrders: action.subOrders
          }
        ]
      };

    case ActionTypes.TOGGLE_ORDER_ITEM_READY:
      return {
        ...state,
        trips: state.trips.map((trip) => {
          if (trip.tripId === action.tripId) {
            for (const [orderFor, orderItems] of Object.entries(trip.subOrders)) {
              trip.subOrders[orderFor] = orderItems.map((oi) => {
                if (oi.orderItemId === action.orderItemId) {
                  oi.orderReady = action.isReady;
                }
                return oi;
              });
            }
          }
          return trip;
        })
      };

    case ActionTypes.SET_ORDER_PICKED_UP_AT:
      return {
        ...state,
        trips: state.trips.map((trip) =>
          trip.tripId === action.tripId ? { ...trip, pickedUpAt: action.pickedUpAt } : trip
        )
      };

    case ActionTypes.SET_ORDER_ACCEPTED_AT:
      return {
        ...state,
        trips: state.trips.map((trip) =>
          trip.tripId === action.tripId
            ? {
                ...trip,
                acceptedByPlaceAt: new Date(),
                rejectedByPlaceAt: null,
                expectedOrderReadyTime: action.expectedOrderReadyTime
              }
            : trip
        )
      };

    case ActionTypes.SET_ORDER_REJECTED_AT:
      return {
        ...state,
        trips: state.trips.map((trip) =>
          trip.tripId === action.tripId
            ? {
                ...trip,
                acceptedByPlaceAt: null,
                rejectedByPlaceAt: new Date()
              }
            : trip
        )
      };

    case ActionTypes.SET_EXPECTED_READY_TIME:
      return {
        ...state,
        trips: state.trips.map((trip) =>
          trip.tripId === action.tripId
            ? {
                ...trip,
                expectedOrderReadyTime: action.expectedOrderReadyTime
              }
            : trip
        )
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
