const MAX_ITEMS_IN_A_SECTION = 20;
const MAX_NUM_OF_SECTIONS = 4;
const SPACING_FACTOR = 0.2;

const breakInToSections = (sortedCategories, sortedItems, maxItemsInSection, extraSpacing) => {
  const sections = [];
  let currCategory = '';
  for (let x = 0; x < sortedItems.length; ) {
    const section = [];
    for (let y = 0; y < maxItemsInSection && x < sortedItems.length; ) {
      x++;
      y += 1 + 1 * SPACING_FACTOR * extraSpacing; // 1, 1.2, 1.4, ...
      if (currCategory === sortedItems[x - 1].category) {
        section.push({ type: 'item', data: sortedItems[x - 1] });
      } else if (y < maxItemsInSection - 1) {
        // it's a new category and
        // more than two items can go into same section
        currCategory = sortedItems[x - 1].category;
        y += 1.5 + 1.5 * SPACING_FACTOR * extraSpacing; //counting for the category line 1.5x -> 1.5, 1.8, 2.1, ...
        section.push({
          type: 'category',
          // eslint-disable-next-line no-loop-func
          data: sortedCategories.find((c) => c.id === currCategory)
        });
        section.push({ type: 'item', data: sortedItems[x - 1] });
      } else {
        break;
      }
    }
    sections.push(section);
  }
  return sections;
};

const spreadOutItems = (sortedCategories, sortedItems, extraSpacing) => {
  const pages = [];
  let menuWithMostSectionsInLastPage = [];
  let sections = [];
  // try to get to 3-4 columns in the last page (not less than that)
  // why not just calculate it^ based on number of items?
  // - it's not easy coz each section may not have same number of categs/items
  let maxAttemptInOneIteration = 3;
  let attempt = 0 + extraSpacing * maxAttemptInOneIteration;
  let maxAttempt = maxAttemptInOneIteration * (1 + extraSpacing);
  for (; attempt < maxAttempt; attempt++) {
    sections = breakInToSections(
      sortedCategories,
      sortedItems,
      MAX_ITEMS_IN_A_SECTION - attempt,
      extraSpacing
    );
    pages.length = 0;
    for (let i = 0; i < sections.length; i += MAX_NUM_OF_SECTIONS) {
      const page = sections.slice(i, i + MAX_NUM_OF_SECTIONS);
      pages.push(page);
    }

    // if the last page has less than 3 columns
    if (pages.length > 1 && pages[pages.length - 1].length < 3) {
      // check and save highest cols so far in menuWithMostSectionsInLastPage
      if (
        menuWithMostSectionsInLastPage.length < 1 ||
        menuWithMostSectionsInLastPage[menuWithMostSectionsInLastPage.length - 1].length <
          pages[pages.length - 1].length
      ) {
        menuWithMostSectionsInLastPage = [...pages];
      }
      continue;
    }
    break;
  }

  // return whichever has the most cols in the last page
  if (
    menuWithMostSectionsInLastPage.length > 0 &&
    menuWithMostSectionsInLastPage[menuWithMostSectionsInLastPage.length - 1].length >
      pages[pages.length - 1].length
  ) {
    return menuWithMostSectionsInLastPage;
  }
  return pages;
};

const buildPagesFromCategAndItems = (categories, items) => {
  const sortedCategories = categories.sort((a, b) => a.order - b.order);
  const sortedItems = [];

  for (const categ of sortedCategories) {
    const categItems = items.filter((i) => i.category === categ.id);
    sortedItems.push(...categItems);
  }

  let pages = [];
  let extraSpacingUsed = 0;
  pages = spreadOutItems(sortedCategories, sortedItems, 0); // try with extraSpacing=0
  if (pages.length > 1 && pages[pages.length - 1].length < 3) {
    const pages1 = spreadOutItems(sortedCategories, sortedItems, 1); // try with extraSpacing=1
    if (pages1.length > 0 && pages1[pages1.length - 1].length > pages[pages.length - 1].length) {
      pages = pages1;
      extraSpacingUsed = 1;
    }
  }
  return { pages, extraSpacingUsed };
};

export { buildPagesFromCategAndItems };
