import React from 'react';
import moment from 'moment';
import { removeCategoryDeal } from '../../Items/actions';

const CategoryDealItem = ({ dispatch, category, placeId }) => {
  return (
    <tr>
      <td>
        <span className="text-capitalize">{category.name}</span>
      </td>
      <td>
        <span className="text-capitalize">{category.discountPercentage}%</span>
      </td>
      <td>
        <span className="text-capitalize">
          {moment(category.dealValidFrom.toDate()).format('lll')}
        </span>
      </td>
      <td>
        <span className="text-capitalize">
          {moment(category.dealValidTo.toDate()).format('lll')}
        </span>
      </td>
      <td>
        <div
          onClick={() => {
            const confirmBox = window.confirm(`Delete '${category.name}'?`);
            if (confirmBox === true) {
              removeCategoryDeal(dispatch, category, placeId);
            }
          }}>
          <i className="far fa-trash-alt ms-text-danger" />
        </div>
      </td>
    </tr>
  );
};

export default CategoryDealItem;
