import {
  doc,
  updateDoc,
  deleteDoc,
  collection,
  where,
  getDocs,
  query,
  deleteField,
  getDoc
} from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';

import { db } from '../../lib/firebase';
import * as ActionTypes from '../../data/state/action-types';
import { addCategoryIfNew } from '../AddItem/actions';

const setItemAvailabilityAction = (itemId, available) => {
  return {
    type: ActionTypes.SET_ITEM_AVAILABILITY,
    itemId,
    available
  };
};

const setItemSpecialAction = (itemId, ourSpecial) => {
  return {
    type: ActionTypes.SET_ITEM_SPECIAL,
    itemId,
    ourSpecial
  };
};

const setItemDeleteAction = (itemId) => {
  return {
    type: ActionTypes.DELETE_ITEM,
    itemId
  };
};

const setUpdateItemAction = (updatedItem) => {
  return {
    type: ActionTypes.UPDATE_ITEM,
    updatedItem,
    itemId: updatedItem.itemId
  };
};

const setUpdateCategoryAction = (updatedCategory) => {
  return {
    type: ActionTypes.UPDATE_CATEGORY,
    updatedCategory,
    id: updatedCategory.id
  };
};

const removeImageByUrl = async (imageSrc) => {
  const storage = getStorage();
  const imageRef = ref(storage, imageSrc);
  await deleteObject(imageRef);
  return;
};

const setItemAvailability = async (dispatch, itemId, available) => {
  const itemRef = doc(db, 'items', itemId);
  await updateDoc(itemRef, {
    available
  });

  dispatch(setItemAvailabilityAction(itemId, available));
};

const setItemSpecial = async (dispatch, itemId, ourSpecial) => {
  const itemRef = doc(db, 'items', itemId);
  await updateDoc(itemRef, {
    ourSpecial
  });

  dispatch(setItemSpecialAction(itemId, ourSpecial));
};

const deleteItem = async (dispatch, itemId, imageSrc) => {
  // Make sure the item is not exists in order items
  try {
    const orderItemQuery = query(collection(db, 'order_item'), where('itemId', '==', itemId));
    const snapShot = await getDocs(orderItemQuery);
    if (snapShot.empty) {
      // ok to proceed
      const itemRef = doc(db, 'items', itemId);
      await deleteDoc(itemRef);

      // also delete image of the item from storage
      if (imageSrc) {
        await removeImageByUrl(imageSrc);
      }

      dispatch(setItemDeleteAction(itemId));
    } else {
      alert(
        'This item cannot be deleted. This can happen if people have placed order for this item in the past. You can still edit and/or make this item not available.'
      );
    }
  } catch (error) {
    console.log('Error while delete items ', error.message);
  }
};

const updateItem = async (dispatch, placeId, itemInfo) => {
  const { itemId, itemName, imageSrc, available, price, description, category } = itemInfo;
  await addCategoryIfNew(dispatch, placeId, category);
  const itemRef = doc(db, 'items', itemInfo.itemId);
  await updateDoc(itemRef, {
    itemId,
    itemName,
    imageSrc,
    available,
    price,
    description,
    category: category.id
  });
  dispatch(setUpdateItemAction({ ...itemInfo, category: category.id }));
};

const addDealPrice = async (dispatch, itemInfo, dealPrice, dealValidFrom, dealValidTo) => {
  try {
    console.log(itemInfo);
    const { itemId } = itemInfo;
    const itemRef = doc(db, 'items', itemId);
    await updateDoc(itemRef, { dealPrice, dealValidFrom, dealValidTo });
    // To get the correct date format (firestore) , better to get it from db
    const updatedDoc = await getDoc(itemRef, { itemId });
    const _updatedDoc = updatedDoc.data();
    dispatch(
      setUpdateItemAction({
        ...itemInfo,
        dealPrice,
        dealValidFrom: _updatedDoc.dealValidFrom,
        dealValidTo: _updatedDoc.dealValidTo
      })
    );
  } catch (error) {
    console.log(error);
  }
};

const addCategoryDeal = async (
  dispatch,
  placeId,
  itemInfo,
  discountPercentage,
  dealValidFrom,
  dealValidTo
) => {
  try {
    const { id } = itemInfo;
    const docRef = collection(db, 'categories');
    const q = query(
      collection(db, 'categories'),
      where('categoryId', '==', id),
      where('placeId', '==', placeId)
    );
    const querySnapshot = await getDocs(q);
    const docId = querySnapshot.docs[0].id;
    await updateDoc(doc(docRef, docId), { discountPercentage, dealValidFrom, dealValidTo });

    // Get the updated document
    const updatedDocSnapshot = await getDoc(doc(docRef, docId));
    const updatedDocData = updatedDocSnapshot.data();
    dispatch(
      setUpdateCategoryAction({
        ...itemInfo,
        discountPercentage,
        dealValidFrom: updatedDocData.dealValidFrom,
        dealValidTo: updatedDocData.dealValidTo
      })
    );
  } catch (error) {
    console.log(error);
  }
};

const removeDealPrice = async (dispatch, itemInfo) => {
  try {
    const { itemId } = itemInfo;
    const itemRef = doc(db, 'items', itemId);
    await updateDoc(itemRef, {
      dealPrice: deleteField(),
      dealValidFrom: deleteField(),
      dealValidTo: deleteField()
    });
    const itemInfoArr = [itemInfo].map(
      ({ dealPrice, dealValidFrom, dealValidTo, ...rest }) => rest
    );
    dispatch(setUpdateItemAction(itemInfoArr[0]));
  } catch (error) {
    console.log(error);
  }
};

const removeCategoryDeal = async (dispatch, itemInfo, placeId) => {
  try {
    const { id } = itemInfo;
    const docRef = collection(db, 'categories');
    const q = query(
      collection(db, 'categories'),
      where('categoryId', '==', id),
      where('placeId', '==', placeId)
    );
    const querySnapshot = await getDocs(q);
    const docId = querySnapshot.docs[0].id;
    await updateDoc(doc(docRef, docId), {
      discountPercentage: deleteField(),
      dealValidFrom: deleteField(),
      dealValidTo: deleteField()
    });
    const itemInfoArr = [itemInfo].map(
      ({ discountPercentage, dealValidFrom, dealValidTo, ...rest }) => rest
    );
    dispatch(setUpdateCategoryAction(itemInfoArr[0]));
  } catch (error) {
    console.log(error);
  }
};

export {
  setItemAvailability,
  setItemSpecial,
  deleteItem,
  removeImageByUrl,
  removeCategoryDeal,
  updateItem,
  addDealPrice,
  addCategoryDeal,
  removeDealPrice
};
