import React, { useState } from 'react';

const StepOne = ({ setSrc, setJson }) => {
  const [fileTooLarge, setFileTooLarge] = useState(false);

  const onSelectFile = (evt) => {
    console.log('Reading');
    var reader = new FileReader();
    var file = evt.target.files[0];
    if (file.size > 11 * 1024 * 1024) {
      console.log('file is too large', file.size);
      setFileTooLarge(true);
      return;
    }

    // if the selected file is a json file, we can handle it locally
    const fileName = evt.target.value;
    if (fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase() === 'json') {
      reader.onload = function (upload) {
        setJson(upload.target.result);
      };
      reader.readAsText(file, 'UTF-8');
      return;
    }

    // we expect a PDF or Image here
    reader.onload = function (upload) {
      setSrc(upload.target.result);
    };
    reader.readAsDataURL(file);
    console.log('Done reading');
  };

  return (
    <div className="ms-wizard-step">
      {fileTooLarge === true && (
        <div className="alert alert-danger" role="alert">
          Please select a file smaller than 10 MB. If it is a multi page menu, try importing one
          page at a time.
        </div>
      )}
      <div className="form-row">
        <div className="mb-2 px-4 py-4 align-self-center col-12">
          <label htmlFor="menu-file">
            Select menu file (PDF or Image)
            <br />
            <span style={{ fontSize: 'x-large' }} role="img" aria-label="bulb">
              &#x1F4A1;
            </span>
            Tip: If the menu contains multiple sections, break it into multiple image or PDF files.
            Importing one section at a time will yield better result
          </label>
          <div className="input-group">
            <input
              type="file"
              className="form-control"
              id="menu-file"
              placeholder="Select files..."
              required
              onChange={onSelectFile}
            />
          </div>
          <div className="invalid-feedback">Invalid image</div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
