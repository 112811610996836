import React from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Addproductcontent from './Addproductcontent';
import Quickbar from '../../../components/layouts/Quickbar';
import LoadingSpinner from '../../../shared/loadingspinner';

const Addproduct = () => {
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <Addproductcontent />
        <LoadingSpinner asOverlay />
      </main>
      <Quickbar />
    </div>
  );
};

export default Addproduct;
