import { doc, updateDoc } from 'firebase/firestore';

// Local modules
import { db } from '../../lib/firebase';
import { getRecords } from '../../lib/helper';

const getRatings = async (placeId) => {
  try {
    const [place] = await getRecords('places', [placeId]);
    if (place && place.ratings) {
      return place.ratings;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error.message);
    return [];
  }
};

const updateStoreRatings = async (placeId, ratings) => {
  try {
    const placeRef = doc(db, 'places', placeId);
    await updateDoc(placeRef, {
      ratings
    });
  } catch (error) {
    console.log(error.message);
  }
};

export { getRatings, updateStoreRatings };
