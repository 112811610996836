import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import stringSimilarity from 'string-similarity';
import { debounce } from 'lodash';

import Breadcrumb from './Breadcrumb';
import { StateContext } from '../../../data/state/state-context';
import { uploadImage } from '../../AddItem/actions';
import { removeImageByUrl, updateItem } from '../../Items/actions';
import ProductCategories from '../../AddItem/components/ProductCategory';
import { toggleIsLoading } from '../../Loading/actions';
import foodImages from '../../../data/food-images.json';
import settings from '../../../../config/settings';
import { searchPexels } from '../../../utils/external-images';

const Editproductcontent = ({ itemInfo }) => {
  // eslint-disable-next-line no-unused-vars
  const [rootState, dispatch] = useContext(StateContext);
  const { categories } = rootState.items;
  const { placeId } = rootState.main;
  const history = useHistory();
  const [productImgSrc, setProductImgSrc] = useState(null);
  const [removeImgSrc, setRemoveImgSrc] = useState(null);
  const [productImgFile, setProductImgFile] = useState(null);
  const [rankedImages, setRankedImages] = useState([]);
  const [rankIndex, setRankIndex] = useState(0);
  const [itemName, setItemName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [hasImageModified, setHasImageModified] = useState(true);
  const [category, setCategory] = useState(categories[0]);
  const [userSelectedImage, setUserSelectedImage] = useState(false);

  const placeholderImage = process.env.PUBLIC_URL + '/assets/img/foodtech/add-product-6.jpg';

  const loadRankedImages = (iName) => {
    const matches = stringSimilarity.findBestMatch(iName.toLowerCase(), foodImages);
    const rankedResult = matches?.ratings?.sort((a, b) => b.rating - a.rating);
    if (rankedResult?.length > 0) {
      setRankedImages(rankedResult);
      setRankIndex(0);
    }
  };

  useEffect(() => {
    setItemName(itemInfo.itemName);
    setPrice(itemInfo.price);
    setDescription(itemInfo.description ?? '');
    if (itemInfo.category) {
      const categObj = categories.find(({ id }) => id === itemInfo.category);
      if (categObj) {
        setCategory(categObj);
      }
    }
    if (itemInfo.imageSrc) {
      setProductImgSrc(itemInfo.imageSrc);
      setRemoveImgSrc(itemInfo.imageSrc);
      setHasImageModified(false);
    }
    loadRankedImages(itemInfo.category + '-' + itemInfo.itemName);
  }, [itemInfo]);

  const onUpdateItem = async (available) => {
    try {
      toggleIsLoading(dispatch, true); // start spinning
      let imageSrc = itemInfo.imageSrc || '';
      if (productImgSrc !== null && hasImageModified) {
        if (userSelectedImage === false) {
          // we have an image url (local or remote) - no need to upload user selected file
          imageSrc = productImgSrc;
        } else {
          imageSrc = await uploadImage(productImgFile);
        }
      } else if (removeImgSrc !== null && hasImageModified) {
        // remove image
        await removeImageByUrl(removeImgSrc);
        imageSrc = '';
      }

      await updateItem(dispatch, placeId, {
        ...itemInfo,
        itemName,
        price: parseFloat(price) || 0,
        description,
        available,
        imageSrc,
        category
      });
      toggleIsLoading(dispatch, false); // stop spinning
      history.push('/menu-list');
    } catch (error) {
      toggleIsLoading(dispatch, false); // stop spinning
      console.log('Error while updating product ', error);
      alert('Something went wrong');
    }
  };

  const onCancel = () => history.push('/menu-list');

  const onSave = (event) => {
    // TODO: there is bootstrap validation that kicks in during submit
    // Need to see how to make it work even when we handle submit here
    event.preventDefault();
    event.stopPropagation();
    const available = document.getElementById('edit-product-available').checked;
    if (itemName) {
      onUpdateItem(available);
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUserSelectedImage(true);
      const reader = new FileReader();
      reader.onload = function () {
        setProductImgSrc(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      // setProductImgSrc(URL.createObjectURL(event.target.files[0]));
      setProductImgFile(event.target.files[0]);
      event.target.value = null; // clear the input field
    }
  };

  const onRemoveImage = () => {
    setUserSelectedImage(false);
    setProductImgSrc(null);
    setProductImgFile(null);
    setHasImageModified(true);
  };

  // NOTE: if searchKeywords is not provided, then findAnImage searches for category-itemName combo
  const findAnImage = useCallback(
    async (searchKeywords = '') => {
      let lookupTerm = '';
      let pexelsUrls = [];
      if (typeof searchKeywords === 'string' && searchKeywords !== '') {
        if (searchKeywords.trim().indexOf('https://') === 0) {
          // we have an image url
          setProductImgSrc(searchKeywords.trim());
          return;
        }

        pexelsUrls = await searchPexels(searchKeywords);
        lookupTerm = searchKeywords;
      } else if (userSelectedImage === false && hasImageModified) {
        // user hasn't picked a file - let's try to find one based on string similarity
        const itemName = document.getElementById('edit-product-name');
        if (!itemName?.value) {
          return;
        }

        lookupTerm = category.id + '-' + itemName.value.toLowerCase();
      }

      if (lookupTerm === '') {
        return;
      }

      const matches = stringSimilarity.findBestMatch(lookupTerm, foodImages);
      const rankedResult = matches?.ratings?.sort((a, b) => b.rating - a.rating);
      rankedResult.unshift(...pexelsUrls);
      if (rankedResult.length > 0) {
        setRankedImages(rankedResult);
        setRankIndex(0);
        const imgSrc =
          rankedResult[0].target.indexOf('https://') === 0
            ? rankedResult[0].target
            : `${settings.foodImagePath}/${rankedResult[0].target}`;
        setProductImgSrc(imgSrc);
      }
    },
    [category, userSelectedImage, hasImageModified]
  );

  const findAnImageDebounce = useMemo(() => debounce(findAnImage, 1000), [findAnImage]);

  const searchImgByKeyword = (e) => {
    setHasImageModified(true);
    // setUserSelectedImage(true);
    findAnImageDebounce(e.target.value);
  };

  const switchImage = (idx) => {
    // setUserSelectedImage(true);
    setHasImageModified(true);
    setRankIndex(idx);
    const imgSrc =
      rankedImages[idx].target.indexOf('https://') === 0
        ? rankedImages[idx].target
        : `${settings.foodImagePath}/${rankedImages[idx].target}`;
    setProductImgSrc(imgSrc);
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb />
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>Edit Product</h6>
            </div>
            <div className="ms-panel-body">
              <form id="edit-product-form" className="needs-validation clearfix" noValidate>
                <div className="form-row">
                  <ProductCategories
                    categories={categories}
                    selected={category}
                    onSelect={setCategory}
                  />
                  <div className="col-md-12 mb-3">
                    <label htmlFor="edit-product-name">Product Name</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setItemName(e.target.value);
                        }}
                        value={itemName}
                        id="edit-product-name"
                        placeholder="Name"
                        onBlur={findAnImage}
                        autoComplete="new-name"
                        required
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="edit-product-price">Price</label>
                    <div className="input-group">
                      <input
                        type="text"
                        value={price}
                        onChange={(e) => {
                          setPrice(e.target.value);
                        }}
                        className="form-control"
                        id="edit-product-price"
                        placeholder="0.00"
                        autoComplete="new-price"
                        required
                      />
                      <div className="invalid-feedback">Price</div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="edit-product-description">Description</label>
                    <div className="input-group">
                      <textarea
                        rows={5}
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        id="edit-product-description"
                        className="form-control"
                        placeholder="Short description"
                        required
                      />
                      <div className="invalid-feedback">Please provide description.</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="ms-panel">
                <div className="ms-panel-header">
                  <h6>Image </h6>
                </div>
                <div className="ms-panel-body">
                  {productImgSrc === null ? (
                    <img className="d-block w-100" src={placeholderImage} alt="Placeholder" />
                  ) : (
                    <>
                      <div className="ms-panel-header new">
                        <button
                          type="button"
                          className="btn btn-outline-secondary has-icon"
                          onClick={() => switchImage(rankIndex > 0 ? rankIndex - 1 : 0)}>
                          <i class="fa fa-chevron-left"></i>
                        </button>
                        <input
                          className="form-control form-control-lg border-dark mx-1"
                          type="text"
                          placeholder="Keywords or URL"
                          onChange={searchImgByKeyword}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary has-icon"
                          onClick={() => switchImage(rankIndex + 1)}>
                          <i class="fa fa-chevron-right"></i>
                        </button>
                      </div>
                      <img
                        src={productImgSrc}
                        alt="Product"
                        style={{ width: '100%', objectFit: 'cover' }}
                      />
                      <div className="new">
                        <button type="button" className="btn btn-secondary" onClick={onRemoveImage}>
                          Remove
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="ms-panel-header new">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="validatedCustomFile"
                      onChange={onImageChange}
                    />
                    <label className="custom-file-label" htmlFor="validatedCustomFile">
                      Select image...
                    </label>
                    <div className="invalid-feedback">Invalid image</div>
                  </div>
                </div>
                <div className="ms-panel-header new">
                  <p className="medium">Available</p>
                  <div>
                    <label className="ms-switch">
                      <input
                        type="checkbox"
                        id="edit-product-available"
                        defaultChecked={itemInfo.available}
                      />
                      <span className="ms-switch-slider round" />
                    </label>
                  </div>
                </div>
                <div className="ms-panel-header new">
                  <button className="btn btn-secondary d-block" onClick={onCancel}>
                    Cancel
                  </button>
                  <button className="btn btn-primary d-block" onClick={onSave}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editproductcontent;
