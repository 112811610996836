const waMeLink = ({ urlHost, tnum, scard }) => {
  const phoneNumber = '919021195875';
  const storeHandle = urlHost ? urlHost.split('.')[0] : '';
  const tnumHandle = tnum ? `#tnum=${tnum}` : '';
  const scardHandle = scard ? `#scard=${scard}` : '';
  const message = `Hi ${storeHandle}${tnumHandle}${scardHandle} - send menu`;
  const encodedMessage = encodeURIComponent(message);
  const waUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  return waUrl;
};

export { waMeLink };
