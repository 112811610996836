import React from "react";

const TocContent = () => (
  <>
    <b>Introduction</b>
    <p>

      Welcome to FerryPal! By signing up for a FerryPal Account (as defined in Section 1) or by using any FerryPal Services (as defined below), you are agreeing to be bound by the following terms and conditions (the “Terms of Service”).

      As used in these Terms of Service, “we”, “us”, “our” and “FerryPal” means the applicable FerryPal Contracting Party (as defined in Section 13 below), and “you” means the FerryPal User (if registering for or using a FerryPal Service as an individual), or the business employing the FerryPal User (if registering for or using a FerryPal Service as a business) and any of its affiliates.

      The services offered by FerryPal under these Terms of Service include various products and services that enable you to build your own store and help you to sell goods and services to customers, whether online (“Online Services”), in person (“POS Services”), or both. Any such service or services offered by FerryPal are referred to in these Terms of Services as the “Service(s)”. Any new features or tools which are added to the current Services will also be subject to the Terms of Service. You can review the current version of the Terms of Service at any time at https://www.ferrypal.com/terms-and-conditions.

      You must read, agree with and accept all of the terms and conditions contained or expressly referenced in these Terms of Service, including FerryPal’s Acceptable Use Policy (“AUP”) and Privacy Policy, and, if applicable, the Supplementary Terms of Service for E.U. Merchants ("EU Terms"), the FerryPal API License and Terms of Use (“API Terms”) and the FerryPal Data Processing Addendum (“DPA”) before you may sign up for a FerryPal Account or use any FerryPal Service. Additionally, if you offer goods or services in relation to COVID-19, you must read, acknowledge and agree to the Rules of Engagement for Sale of COVID-19 Related Products.

      Everyday language summaries are provided for convenience only and appear in bold near each section, but these summaries are not legally binding. Please read the Terms of Service, including any document referred to in these Terms of Service, for the complete picture of your legal requirements. By using FerryPal or any FerryPal services, you are agreeing to these terms. Be sure to occasionally check back for updates.

    </p>
    <b>1. Account Terms</b>
    <p>

      To access and use the Services, you must register for a FerryPal account (“Account”). To complete your Account registration, you must provide us with your full legal name, business address, phone number, a valid email address, and any other information indicated as required. FerryPal may reject your application for an Account, or cancel an existing Account, for any reason, at our sole discretion.

      You must be the older of: (i) 18 years, or (ii) at least the age of majority in the jurisdiction where you reside and from which you use the Services to open an Account.

      You confirm that you are receiving any Services provided by FerryPal for the purposes of carrying on a business activity and not for any personal, household or family purpose.

      You acknowledge that FerryPal will use the email address you provide on opening an Account or as updated by you from time to time as the primary method for communication with you (“Primary Email Address”). You must monitor the Primary Email Address you provide to FerryPal and your Primary Email Address must be capable of both sending and receiving messages. Your email communications with FerryPal can only be authenticated if they come from your Primary Email Address.

      You are responsible for keeping your password secure. FerryPal cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account and password.

      Technical support in respect of the Services is only provided to FerryPal Users. Questions about the Terms of Service should be sent to FerryPal Support.

      You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Services, or access to the Services without the express written permission by FerryPal.

      You agree not to work around, bypass, or circumvent any of the technical limitations of the Services, use any tool to enable features or functionalities that are otherwise disabled in the Services, or decompile, disassemble or otherwise reverse engineer the Services.

      You agree not to access the Services or monitor any material or information from the Services using any robot, spider, scraper, or other automated means.

      You understand that your Materials may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. “Materials” means Your Trademarks, copyright content, any products or services you sell through the Services (including description and price), and any photos, images, videos, graphics, written content, audio files, code, information, or other data provided or made available by you or your affiliates to FerryPal or its affiliates.



    </p>
    <b>2. Account Activation</b>
    <p>


      <br /><b>2.1 Store Owner</b><br />

      Subject to Section 2.1.2, the person signing up for the Service by opening an Account will be the contracting party (“Store Owner ”) for the purposes of our Terms of Service and will be the person who is authorized to use any corresponding Account we may provide to the Store Owner in connection with the Service. You are responsible for ensuring that the name of the Store Owner (including the legal name of the company that owns the Store, if applicable) is clearly visible on the Store’s website.
      If you are signing up for the Services on behalf of your employer, your employer will be the Store Owner. If you are signing up for the Services on behalf of your employer, then you must use your employer-issued email address and you represent and warrant that you have the authority to bind your employer to our Terms of Service.
      Your FerryPal Store can only be associated with one Store Owner. A Store Owner may have multiple FerryPal Stores. “Store” means the online store (whether hosted by FerryPal or on a third party website) or physical retail location(s) associated with the Account.
      <br /><b>2.2 Staff Accounts</b><br />

      Based on your FerryPal pricing plan, you can create one or more staff accounts (“Staff Accounts”) allowing other people to access the Account. Each Staff Account must include a full legal name and a valid email account. With Staff Accounts, the Store Owner can set permissions and let other people work in their Account while determining the level of access by Staff Accounts to specific business information (for example, you can limit Staff Account access to sales information on the Reports page or prevent Staff Accounts from changing general store settings).
      The Store Owner is responsible for: (a) ensuring it’s employees, agents and subcontractors, including via Staff Accounts, comply with these Terms of Service; and (b) any breach of these Terms of Service by the Store Owner’s employees, agents or subcontractors. The Store Owner acknowledges and agrees that Store Owner will be responsible for the performance of all of its obligations under the Agreement, regardless of whether it sublicenses or subcontracts any such obligations to any third party, including but not limited to any affiliates or subsidiaries of Store Owner.
      The Store Owner and the users under Staff Accounts are each referred to as a “FerryPal User”.
      <br /><b>2.3 PayPal Express Checkout and FerryPal Payments Accounts</b><br />

      Upon completion of sign up for the Service, FerryPal will create a PayPal Express Checkout account on your behalf, using your Primary Email Address. Depending on your location, FerryPal may also create a FerryPal Payments account on your behalf.
      You acknowledge that PayPal Express Checkout and/or FerryPal Payments will be your default payments gateway(s) and that it is your sole responsibility as the Store Owner to activate and maintain these accounts. If you do not wish to keep either of the payment accounts active, it is your responsibility to deactivate them. For the avoidance of doubt, PayPal Express Checkout is a Third Party Service, as defined in Section 9.7.1 of these Terms of Service.
      <br /><b>2.4 Shop Pay</b><br />

      Upon completion of sign up for the Service, if you have been enrolled in FerryPal Payments, Shop Pay will automatically appear as an accelerated checkout option on your checkout page. If you do not wish for Shop Pay to appear on your Store, it is your responsibility to remove it by managing your accelerated checkouts in your FerryPal administrative console.
      If your customers have enabled Shop Pay, customers may purchase goods and services from your FerryPal Store using Shop Pay.
      By using Shop Pay on your FerryPal Store, you agree to be bound by the Shop Pay Merchant Terms of Service, as they may be amended by FerryPal from time to time. If FerryPal amends the Shop Pay Merchant Terms of Service, amendments are effective as of the date of posting. Your continued use of Shop Pay on your Store after the amended Shop Pay Merchant Terms of Service are posted constitutes your agreement to, and acceptance of, the amended Shop Pay Merchant Terms of Service. If you do not agree to any changes to the Shop Pay Merchant Terms of Service, it is your responsibility to remove Shop Pay by managing your accelerated checkouts in your FerryPal administrative console.
      <br /><b>2.5 Apple Pay for Safari Account</b><br />

      Upon completion of sign up for the Service, FerryPal will create an Apple Pay for Safari (“Apple Pay”) account on your behalf, using the URL(s) and business name associated with your Account. Depending on your location, FerryPal may activate your Apple Pay account on your behalf, otherwise you will be required to activate your Apple Pay account within your Account admin. If you do not wish to keep your Apple Pay account active, it is your responsibility to deactivate it. For the avoidance of doubt, Apple Pay is a Third Party Service, as defined in Section 9.7.1 of these Terms of Service.
      If you use an Apple Pay supported payment gateway and your customers have enabled Apple Pay on their device, customers may purchase goods and services from your Store using Apple Pay.
      By using Apple Pay on your Store, you are agreeing to be bound by the Apple Pay Platform Web Merchant Terms and Conditions, as they may be amended by Apple from time to time. Such amendments to the Apple Pay Platform Web Merchant Terms are effective as of the date of posting. Your continued use of Apple Pay on your Store after the amended Apple Pay Platform Web Merchant Terms are posted constitutes your agreement to, and acceptance of, the amended Apple Pay Platform Web Merchant Terms. If you do not agree to any changes to the Apple Pay Platform Web Merchant Terms, de-activate your Apple Pay account and do not continue to use Apple Pay on your Store.
      <br /><b>2.6 Google Pay</b><br />

      Upon completion of sign up for the Service, if you have been enrolled in FerryPal Payments, FerryPal will also create a Google Pay account on your behalf. If you do not wish to keep your Google Pay account active, it is your responsibility to deactivate it. For the avoidance of doubt, Google Pay is a Third Party Service, as defined in Section 9.7.1 of these Terms of Service.
      If you use a Google Pay supported payment gateway and your customers have enabled Google Pay, customers may purchase goods and services from your Store using Google Pay.
      By using Google Pay on your Store, you are agreeing to be bound by the Google Pay API Terms of Service, as they may be amended by Google from time to time. If Google amends the Google Pay API Terms of Service, the amended and restated version will be posted here: https://payments.developers.google.com/terms/sellertos. Such amendments to the Google Pay API Terms of Service are effective as of the date of posting. Your continued use of Google Pay on your Store after the amended Google Pay API Terms of Service are posted constitutes your agreement to, and acceptance of, the amended Google Pay API Terms of Service. If you do not agree to any changes to the Google Pay API Terms of Service, deactivate your Google Pay account and do not continue to use Google Pay on your Store.
      <br /><b>2.7 Facebook Pay</b><br />

      Upon completion of sign up for the Service, if you have been enrolled in FerryPal Payments, FerryPal will also create a Facebook Pay account on your behalf. If you do not wish to keep your Facebook Pay account active, it is your responsibility to deactivate it. For the avoidance of doubt, Facebook Pay is a Third Party Service, as defined in Section 9.7.1 of these Terms of Service.
      If you use a Facebook Pay supported payment gateway and your customers have enabled Facebook Pay, customers may purchase goods and services from your Store using Facebook Pay.
      By using Facebook Pay on your Store, you are agreeing to be bound by the Facebook Pay Merchant Terms and Conditions, as they may be amended by Facebook from time to time. If Facebook amends the Facebook Pay Merchant Terms and Conditions, such amendments will be effective as of the date of posting by Facebook. Your continued use of Facebook Pay on your Store after the amended Facebook Pay Merchant Terms and Conditions are posted constitutes your agreement to, and acceptance of, the amended Facebook Pay Merchant Terms and Conditions. If you do not agree to any changes to the Facebook Pay Merchant Terms and Conditions, de-activate your Facebook Pay account and do not continue to use Facebook Pay on your Store.
      <br /><b>2.8 Domain Names</b><br />

      Upon purchasing a domain name through FerryPal, domain registration will be preset to automatically renew each year so long as your FerryPal Account remains active. You acknowledge that it is your sole responsibility to deactivate the auto-renewal function should you choose to do so.

    </p>
    <b>3. FerryPal Rights</b>
    <p>


      The Services have a range of features and functionalities. Not all Services or features will be available to all Merchants at all times and we are under no obligation to make any Services or features available in any jurisdiction. Except where prohibited in these Terms of Service or by applicable law, we reserve the right to modify the Services or any part thereof for any reason, without notice and at any time.
      FerryPal does not pre-screen Materials and it is in our sole discretion to refuse or remove any Materials from any part of the Services, including if we determine in our sole discretion that the goods or services that you offer through the Services, or the Materials uploaded or posted to the Services, violate our AUP or these Terms of Service.
      Verbal or written abuse of any kind (including threats of abuse or retribution) of any FerryPal employee, member, or officer will result in immediate Account termination.
      We reserve the right to provide our Services to your competitors and make no promise of exclusivity. You further acknowledge and agree that FerryPal employees and contractors may also be FerryPal customers or merchants and that they may compete with you, although they may not use your Confidential Information (as defined in Section 6) in doing so.
      In the event of a dispute regarding Account ownership, we reserve the right to request documentation to determine or confirm Account ownership. Documentation may include, but is not limited to, a scanned copy of your business license, government issued photo ID, the last four digits of the credit card on file, or confirmation of your status as an employee of an entity.
      FerryPal reserves the right to determine, in our sole discretion, rightful Account ownership and transfer an Account to the rightful Store Owner. If we are unable to reasonably determine the rightful Store Owner, without prejudice to our other rights and remedies, FerryPal reserves the right to temporarily suspend or disable an Account until resolution has been determined between the disputing parties.

    </p>
    <b>4. Your Responsibilities</b>
    <p>


      You acknowledge and agree to provide public-facing contact information, a refund policy and order fulfilment timelines on your FerryPal Store.
      You acknowledge and agree that the Services are not a marketplace, and any contract of sale made through the Services is directly between you and the customer. You are the seller of record for all items you sell through the Services. You are responsible for the creation and operation of your FerryPal Store, your Materials, the goods and services that you may sell through the Services, and all aspects of the transactions between you and your customer(s). This includes, but is not limited to, authorizing the charge to the customer in respect of the customer’s purchase, refunds, returns, fulfilling any sales or customer service, fraudulent transactions, required legal disclosures, regulatory compliance, alleged or actual violation of applicable laws (including but not limited to consumer protection laws in any jurisdiction where you offer products or services for sale), or your breach of these Terms of Service. You represent and warrant that your Store, your Materials and the goods and services you sell through the Services will be true, accurate, and complete, and will not violate any applicable laws, regulations or rights of third parties. For the avoidance of doubt, FerryPal will not be the seller or merchant or record and will have no responsibility for your Store or items sold to customers through the Services.
      You are solely responsible for the goods or services that you may sell through the Services (including description, price, fees, tax that you calculate, defects, required legal disclosures, regulatory compliance, offers or promotional content), including compliance with any applicable laws or regulations.
      You may not use the FerryPal Services for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws), the laws applicable to you in your customer’s jurisdiction, or the laws of United States and the state of Massachusetts. You will comply with all applicable laws, rules and regulations (including but not limited to obtaining and complying with the requirements of any license or permit that may be necessary to operate your store or that may be held by you) in your use of the Service and your performance of obligations under the Terms of Service.
      The API Terms govern your access to and use of the FerryPal API (as defined therein). You are solely responsible for the activity that occurs using your API Credentials (as defined in the API Terms) and for keeping your API Credentials secure.


    </p>
    <b>5. Payment of Fees and Taxes</b>
    <p>


      You will pay the Fees applicable to your subscription to Online Service and/or POS Services (“Subscription Fees”) and any other applicable fees, including but not limited to applicable fees relating to the value of sales made through your Store when using all payment providers other than FerryPal Payments (“Transaction Fees”), and any fees relating to your purchase or use of any products or services such as FerryPal Payments, POS Equipment, shipping, apps, Themes, domain names, Experts Marketplace, or Third Party Services (“Additional Fees”). Together, the Subscription Fees, Transaction Fees and the Additional Fees are referred to as the “Fees”.
      You must keep a valid payment method on file with us to pay for all incurred and recurring Fees. FerryPal will charge applicable Fees to any valid payment method that you authorize (“Authorized Payment Method”), and FerryPal will continue to charge the Authorized Payment Method for applicable Fees until the Services are terminated, and any and all outstanding Fees have been paid in full. Unless otherwise indicated, all Fees and other charges are in U.S. dollars, and all payments will be in U.S. currency.
      Subscription Fees are paid in advance and will be billed in 30 day intervals (each such date, a “Billing Date”). Transaction Fees and Additional Fees will be charged from time to time at FerryPal’s discretion. You will be charged on each Billing Date for all outstanding Fees that have not previously been charged. Fees will appear on an invoice, which will be sent to the Store Owner via the Primary Email Address provided. As well, an invoice will appear on the Account page of your Store’s administrative console. Users have approximately two weeks to bring up and settle any issues with the billing of Subscription Fees.
      If we are not able to process payment of Fees using an Authorized Payment Method, we will make a second attempt to process payment using any Authorized Payment Method, typically 14 days later, but FerryPal may attempt sooner. If the second attempt is not successful, we will make a final attempt, typically 14 days following the second attempt, but in certain circumstances FerryPal may do this second attempt sooner. If our final attempt is not successful, we may suspend and revoke access to your Account and the Services. Your Account will be reactivated upon your payment of any outstanding Fees, plus the Fees applicable to your next billing cycle. You may not be able to access your Account or your storefront during any period of suspension. If the outstanding Fees remain unpaid for 60 days following the date of suspension, FerryPal reserves the right to terminate your Account in accordance with Section 14.
      All Fees are exclusive of applicable federal, provincial, state, local or other governmental sales, goods and services (including Goods and Sales Tax under the Goods and Services Tax Act, Chapter 117A of Singapore), harmonized or other taxes, fees or charges now in force or enacted in the future (“Taxes”).
      You are responsible for all applicable Taxes that arise from or as a result of your subscription to or purchase of FerryPal’s products and services. To the extent that FerryPal charges these Taxes, they are calculated using the tax rates that apply based on the billing address you provide to us. Such amounts are in addition to the Fees for such products and services and will be billed to your Authorized Payment Method. If you are exempt from payment of such Taxes, you must provide us with evidence of your exemption, which in some jurisdictions includes an original certificate that satisfies applicable legal requirements attesting to tax-exempt status. Tax exemption will only apply from and after the date we receive evidence satisfactory to FerryPal of your exemption. If you are not charged Taxes by FerryPal, you are responsible for determining if Taxes are payable, and if so, self-remitting Taxes to the appropriate tax authorities in your jurisdiction.
      For the avoidance of doubt, all sums payable by you to FerryPal under these Terms of Service will be paid free and clear of any deductions or withholdings whatsoever. Other than Taxes charged by FerryPal to you and remitted to the appropriate tax authorities on your behalf, any deductions or withholdings that are required by law will be borne by you and paid separately to the relevant taxation authority. FerryPal will be entitled to charge the full amount of Fees stipulated under these Terms of Service to your Authorized Payment Method ignoring any such deduction or withholding that may be required.
      You are solely responsible for determining, collecting, withholding, reporting, and remitting applicable taxes, duties, fees, surcharges and additional charges that arise from or as a result of any sale on your FerryPal Store or your use of the Services. The Services are not a marketplace. Any contract of sale made through the Services is directly between you and the customer.
      You must maintain an accurate location in the administrative console of your FerryPal Store. If you change jurisdictions you must promptly update your location in the administrative console.
      FerryPal does not provide refunds.


    </p>
    <b>6. Confidentiality</b>
    <p>


      “Confidential Information” will include, but will not be limited to, any and all information associated with a party’s business and not publicly known, including specific business information, technical processes and formulas, software, customer lists, prospective customer lists, names, addresses and other information regarding customers and prospective customers, product designs, sales, costs (including any relevant processing fees), price lists, and other unpublished financial information, business plans and marketing data, and any other confidential and proprietary information, whether or not marked as confidential or proprietary. FerryPal’s Confidential Information includes all information that you receive relating to us, or to the Services, that is not known to the general public including information related to our security program and practices.
      Each party agrees to use the other party’s Confidential Information solely as necessary for performing its obligations under these Terms of Service and in accordance with any other obligations in these Terms of Service including this Section 6. Each party agrees that it will take all reasonable steps, at least substantially equivalent to the steps it takes to protect its own proprietary information, to prevent the duplication, disclosure or use of any such Confidential Information, other than (i) by or to its employees, agents and subcontractors who must have access to such Confidential Information to perform such party’s obligations hereunder, who each will treat such Confidential Information as provided herein, and who are each subject to obligations of confidentiality to such party that are at least as stringent as those contained herein; or (ii) as required by any law, regulation, or order of any court of proper jurisdiction over the parties and the subject matter contained in these Terms of Service, provided that, if legally permitted, the receiving party will give the disclosing party prompt written notice and use commercially reasonable efforts to ensure that such disclosure is accorded confidential treatment. Confidential Information will not include any information that the receiving party can prove: (A) was already in the public domain, or was already known by or in the possession of the receiving party, at the time of disclosure of such information; (B) is independently developed by the receiving party without use of or reference to the other party’s Confidential Information, and without breaching any provisions of these Terms of Service; or (C) is thereafter rightly obtained by the receiving party from a source other than the disclosing party without breaching any provision of these Terms of Service.


    </p>
    <b>7. Limitation of Liability and Indemnification</b>
    <p>


      You expressly understand and agree that, to the extent permitted by applicable laws, FerryPal and its suppliers will not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or relating to the use of or inability to use the Service or these Terms of Service (however arising, including negligence).
      You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, FerryPal partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of (a) your breach of these Terms of Service or the documents it incorporates by reference (including the AUP); (b) or your violation of any law or the rights of a third party; or (c) any aspect of the transaction between you and your Customer, including but not limited to refunds, fraudulent transactions, alleged or actual violation of applicable laws (including but not limited to Federal and State consumer protection laws), or your breach of the Terms of Service.
      You will be responsible for any breach of the Terms of Service by your affiliates, agents or subcontractors and will be liable as if it were your own breach.
      Your use of the Services is at your sole risk. The Services are provided on an “as is” and “as available” basis without any warranty or condition, express, implied or statutory.
      FerryPal does not warrant that the Services will be uninterrupted, timely, secure, or error-free.
      FerryPal does not warrant that the results that may be obtained from the use of the Services will be accurate or reliable.
      FerryPal is not responsible for any of your tax obligations or liabilities related to the use of FerryPal’s Services.
      FerryPal does not warrant that the quality of any products, services, information, or other materials purchased or obtained by you through the Services will meet your expectations, or that any errors in the Services will be corrected.


    </p>
    <b>8. Intellectual Property and Your Materials</b>
    <p>


    <br /><b>8.1 Your Materials</b><br />

      We do not claim ownership of the Materials you provide to FerryPal; however, we do require a license to those Materials. You grant FerryPal a non-exclusive, transferable, sub-licensable, royalty-free, worldwide right and license to host, use, distribute, expose, modify, run, copy, store, publicly perform, communicate to the public (including by telecommunication), broadcast, reproduce, make available, display, and translate, and create derivative works of any Materials provided by you in connection with the Services. We may use our rights under this license to operate, provide, and promote the Services and to perform our obligations and exercise our rights under the Terms of Service. You represent, warrant, and agree that you have all necessary rights in the Materials to grant this license. You irrevocably waive any and all moral rights you may have in the Materials in favour of FerryPal and agree that this waiver may be invoked by anyone who obtains rights in the materials through FerryPal, including anyone to whom FerryPal may transfer or grant (including by way of license or sublicense) any rights in the Materials.
      If you owned the Materials before providing them to FerryPal then, despite uploading them to your FerryPal Store they remain yours, subject to any rights or licenses granted in the Terms of Service or elsewhere. You can remove your FerryPal Store at any time by deleting your Account. Removing your FerryPal Store does not terminate any rights or licenses granted to the Materials that FerryPal requires to exercise any rights or perform any obligations that arose during the Term.
      You agree that FerryPal can, at any time, review and delete any or all of the Materials submitted to the Services, although FerryPal is not obligated to do so.
      You grant FerryPal a non-exclusive, transferable, sub-licensable, royalty-free, worldwide right and license to use the names, trademarks, service marks and logos associated with your Store (“Your Trademarks”) to operate, provide, and promote the Services and to perform our obligations and exercise our rights under the Terms of Service. This license will survive any termination of the Terms of Service solely to the extent that FerryPal requires the license to exercise any rights or perform any obligations that arose during the Term.
      8.2 FerryPal Intellectual Property

      You agree that you may not use any trademarks, logos, or service marks of FerryPal, whether registered or unregistered, including but not limited to the word mark FerryPal, the word mark SHOP, the word mark SHOP PAY, and the “S” and shopping bag design mark (“FerryPal Trademarks”) unless you are authorized to do so by FerryPal in writing. You agree not to use or adopt any marks that may be considered confusing with the FerryPal Trademarks. You agree that any variations or misspellings of the FerryPal Trademarks would be considered confusing with the FerryPal Trademarks.
      You agree not to purchase, register, or use search engine or other pay-per-click keywords (such as Google Ads), trademarks, email addresses, social media names, or domain names (including without limitation top-level domains, sub-domains, and page URLs) that use or include FerryPal or FerryPal Trademarks or that use or include any terms that may be confusing with the FerryPal Trademarks.
      You acknowledge and agree that the Terms of Service do not give you any right to implement FerryPal patents.


    </p>
    <b>9. Additional Services</b>
    <p>


    <br /><b>9.3 SMS Messaging</b><br />

      The Services allow you to send certain communications to your customers by short message service (SMS) messaging (for example, sending order confirmation notifications via SMS) (the "SMS Services"). You will only use the SMS Services in compliance with these Terms of Service, any other applicable terms (including Nexmo Inc.'s Acceptable Use Policy and Twilio’s Acceptable Use Policy, as may be amended from time to time), and the laws of the jurisdiction from which you send messages, and in which your messages are received.


      <br /><b>9.4 Theme Store</b><br />

      You may establish the appearance of your FerryPal Store with a design template from FerryPal’s Theme Store (a “Theme”). If you download a Theme, you are licensed to use it for a single Store only. You are free to transfer a Theme to a second one of your own Stores if you close your first Store. To initiate a transfer of a Theme to a second one of your Stores, please contact FerryPal Support. You are not permitted to transfer or sell a Theme to any other person’s Store on FerryPal or elsewhere. Multiple Stores require multiple downloads and each download is subject to the applicable fee. FerryPal gives no assurance that a particular Theme will remain available for additional downloads.
      You may modify the Theme to suit your Store. FerryPal may add or modify the footer in a Theme that refers to FerryPal at its discretion. FerryPal may modify the Theme where it contains, in our sole discretion, an element that violates the FerryPal AUP or other provisions of the Terms of Service, even if you received the Theme in that condition. FerryPal may modify the Theme to reflect technical changes and updates as required.
      The intellectual property rights of the Theme remain the property of the designer. If you exceed the rights granted by your purchase of a Theme, the designer may take legal action against you, and, without prejudice to our other rights or remedies, FerryPal may take administrative action such as modifying your Store or closing your Store.
      Technical support for a Theme is the responsibility of the designer, and FerryPal accepts no responsibility to provide such support. FerryPal may be able to help you contact the designer.
      It is the responsibility of the user, and not FerryPal, to ensure that the installation of a new theme does not overwrite or damage the current or preexisting theme, or UI, of the user.


      <br /><b>9.5 FerryPal Email</b><br />

      You may generate or send email from your Account using the FerryPal email services (the “Email Services”). In addition to the terms applicable to the Services generally (including FerryPal’s Acceptable Use Policy, and Privacy Policy), the following terms apply to your access and use of the Email Services:

      FerryPal employs certain controls to scan the content of emails you deliver using the Email Services prior to delivery (“Content Scanning”). Such Content Scanning is intended to limit spam, phishing, or other malicious content that contravenes these Terms of Service, or FerryPal’s Acceptable Use Policy (collectively, “Threats”). By using the Email Services, you explicitly grant FerryPal the right to employ such Content Scanning. FerryPal does not warrant that the Email Services will be free from Threats, and each FerryPal merchant is responsible for all content generated by their respective Stores.
      BY GENERATING OR SENDING EMAIL THROUGH THE EMAIL SERVICES, YOU AGREE TO COMPLY WITH THE FOLLOWING REQUIREMENTS (THE “EMAIL SERVICES REQUIREMENTS”). FerryPal, OR ITS THIRD PARTY PROVIDERS, MAY SUSPEND OR TERMINATE YOUR ACCESS TO AND USE OF THE EMAIL SERVICES IF YOU DO NOT COMPLY WITH THE EMAIL SERVICES REQUIREMENTS. 1. Your use of the Email Services must comply with all applicable laws. Examples of applicable laws include laws relating to spam or unsolicited commercial email (“UCE”), privacy, security, obscenity, defamation, intellectual property, pornography, terrorism, homeland security, gambling, child protection, and other applicable laws. It is your responsibility to know and understand the laws applicable to your use of the Email Services and the emails you generate or send through the Email Services. 2. Your use of the Email Services must comply with FerryPal’s Privacy Policy. It is your responsibility to read and understand the Privacy Policy applicable to your use of the Email Services and the emails you generate or send through the Email Services. 3. You will use commercially reasonable efforts not to send sensitive personal data, including information regarding an individual’s medical or health condition, race or ethnic origin, political opinions, religious or philosophical beliefs, or other sensitive data (collectively, “Sensitive Data”) through the Email Services. It is your responsibility to read and understand your obligations in relation to Sensitive Data. 4. Your use of the Email Services must follow all applicable guidelines established by FerryPal. The guidelines below are examples of practices that may violate the Email Services Requirements when generating, or sending emails through the Email Services:
      using non-permission based email lists (i.e., lists in which each recipient has not explicitly granted permission to receive emails from you by affirmatively opting-in to receive those emails);
      using purchased or rented email lists;
      using third party email addresses, domain names, or mail servers without proper permission;
      sending emails to non-specific addresses (e.g., webmaster@domain.com or info@domain.com);
      sending emails that result in an unacceptable number of spam or UCE complaints (even if the emails themselves are not actually spam or UCE);
      failing to include a working “unsubscribe” link in each email that allows the recipient to remove themselves from your mailing list;
      failing to comply with any request from a recipient to be removed from your mailing list within 10 days of receipt of the request;
      failing to include in each email a link to the then-current privacy policy applicable to that email;
      disguising the origin or subject matter of any email or falsifying or manipulating the originating email address, subject line, headers, or transmission path information for any email;
      failing to include in each email your valid physical mailing address or a link to that information; or
      including “junk mail,” “chain letters,” “pyramid schemes,” incentives (e.g., coupons, discounts, awards, or other incentives) or other material in any email that encourages a recipient to forward the Email to another recipient.
      If you or a customer knows of or suspects any violations of the Email Services Requirements, please notify FerryPal at contact@FerryPal.com. FerryPal will determine compliance with the Email Services Requirements in its discretion.
      FerryPal’s Email Services utilize Third Party Providers, including SendGrid (a Twilio Company). Your use of the Email Services is subject to SendGrid’s Acceptable Use Policy as it may be amended by SendGrid from time to time.


      <br /><b>9.6 Shop App</b><br />

      The Shop app is a FerryPal application that is available to Eligible Merchants (defined below) (the “Shop App”, more details here) to engage with customers that use the Shop App (each, a “Shop App User”).
      The Shop App is deemed to be a part of the Services, and all terms applicable to the Services apply. Without limiting the generality of the preceding sentence, and subject to these Terms of Service and all applicable guidelines and policies, FerryPal reserves the right to refuse a Merchant access to or use of all or part of the Shop App for any reason and at any time without prior notice. Only those Merchants who meet all merchant requirements and do not sell any prohibited products, as outlined in the Shop Merchant Guidelines (as may be amended from time to time), are eligible to access and use the Shop App Services (“Eligible Merchants”).
      We may display your Materials, including product or service listings, Your Trademarks and any other content, data, or information from your FerryPal Store on the Shop App. While the Shop App enables Shop App Users to shop natively in the Shop App, the transaction is completed on your FerryPal Store. For clarity and avoidance of doubt, Section 7 (Limitation of Liability) and Section 8 (Intellectual Property) apply to the use of your Materials on the Shop App and your responsibility for your Materials and purchases made through your FerryPal Store.
      Your Materials and your use of the Shop App must comply at all times with our Shop App Merchant Guidelines, these Terms of Service, the Additional Terms, all other applicable policies and guidelines, and applicable law.
      As in other areas of the Service, FerryPal is not obligated to intervene in any dispute arising between you and your customers (including Shop App Users).
      On the Shop App, we only show non-ad (unpaid, or ‘organic’) content. Organic content is ranked by factors including relevance to a search query or a user’s interests, image quality, domain quality and how frequently other users have engaged with the content. For more information about how we display content on the Shop App, see our Shop App help documentation.
      If you do not wish for your Materials to be available in the Shop App, it is your responsibility to manage or deactivate this Service. If you have the Shop App merchant channel installed, please see instructions here. If you do not yet have the Shop App merchant channel installed on your FerryPal Store, install it here. If the Shop App merchant channel is not currently supported in your country, please contact support to remove your Materials from the Shop App. Note that if you remove your Materials from the Shop App, Shop App Users who purchase from your FerryPal Store will continue to be able to view, manage and track orders from your FerryPal Store in the Shop App. If you delete your FerryPal Account, your Materials will be removed from the Shop App.


      <br /><b>9.7 Third Party Services, Experts, and Experts Marketplace</b><br />

      FerryPal may from time to time recommend, provide you with access to, or enable third party software, applications (“Apps”), products, services or website links (collectively, “Third Party Services”) for your consideration or use, including via the FerryPal App Store, or Experts Marketplace. Such Third Party Services are made available only as a convenience, and your purchase, access or use of any such Third Party Services is solely between you and the applicable third party services provider (“Third Party Provider”). In addition to these Terms of Service, you also agree to be bound by the additional service-specific terms applicable to services you purchase from, or that are provided by, Third Party Providers.
      FerryPal Experts is an online directory of independent third parties (“Experts”) that can help you build and operate your FerryPal Store.
      You can engage and work with an Expert directly or through Experts Marketplace. Experts Marketplace provides you with recommendations on Experts that can assist you with different aspects of your Store. Using Experts Marketplace, you can find, hire, and communicate with Experts directly from your Account admin.
      Any use by you of Third Party Services offered through the Services, FerryPal Experts, Experts Marketplace or FerryPal’s website is entirely at your own risk and discretion, and it is your responsibility to read the terms and conditions and/or privacy policies applicable to such Third Party Services before using them. In some instances, FerryPal may receive a revenue share from Third Party Providers that FerryPal recommends to you or that you otherwise engage through your use of the Services, FerryPal Experts, Experts Marketplace or FerryPal’s website.
      We do not provide any warranties or make representations to you with respect to Third Party Services. You acknowledge that FerryPal has no control over Third Party Services and will not be responsible or liable to you or anyone else for such Third Party Services. The availability of Third Party Services on FerryPal’s websites, including the FerryPal App Store or Experts Marketplace, or the integration or enabling of such Third Party Services with the Services does not constitute or imply an endorsement, authorization, sponsorship, or affiliation by or with FerryPal. FerryPal does not guarantee the availability of Third Party Services and you acknowledge that FerryPal may disable access to any Third Party Services at any time in its sole discretion and without notice to you. FerryPal is not responsible or liable to anyone for discontinuation or suspension of access to, or disablement of, any Third Party Service. FerryPal strongly recommends that you seek specialist advice before using or relying on Third Party Services, to ensure they will meet your needs. In particular, tax calculators should be used for reference only and not as a substitute for independent tax advice, when assessing the correct tax rates you should charge to your customers.
      If you install or enable a Third Party Service for use with the Services, you grant us permission to allow the applicable Third Party Provider to access your data and other Materials and to take any other actions as required for the interoperation of the Third Party Service with the Services, and any exchange of data or other Materials or other interaction between you and the Third Party Provider is solely between you and such Third Party Provider. FerryPal is not responsible for any disclosure, modification or deletion of your data or other Materials, or for any corresponding losses or damages you may suffer, as a result of access by a Third Party Service or a Third Party Provider to your data or other Materials. Google Maps is a Third Party Service that is used within the Services. Your use of the Services is subject to your acceptance of the Google Maps and Earth Enterprise Universal Acceptable Use Policy, Google Maps and Google Earth Additional Terms of Service and Google Privacy Policy as it may be amended by Google from time to time.
      You acknowledge and agree that: (i) by submitting a request for assistance or other information through Experts Marketplace, you consent to being contacted by one or more Experts at the Primary Email Address (or such other email address provided by you) as well as the applicable user email address; and (ii) FerryPal will receive all email communications exchanged via Experts Marketplace or in any reply emails (each a “Reply”) that originate from Experts Marketplace (directly or indirectly) between yourself and Experts. You further agree that FerryPal may share your contact details and the background information that you submit via the Experts Marketplace with Experts. Experts may require access to certain admin pages on your FerryPal Store. You choose the pages that the Experts can access.
      The relationship between you and any Third Party Provider is strictly between you and such Third Party Provider, and FerryPal is not obligated to intervene in any dispute arising between you and a Third Party Provider.
      Under no circumstances will FerryPal be liable for any direct, indirect, incidental, special, consequential, punitive, extraordinary, exemplary or other damages whatsoever, that result from any Third Party Services or your contractual relationship with any Third Party Provider, including any Expert. These limitations will apply even if FerryPal has been advised of the possibility of such damages. The foregoing limitations will apply to the fullest extent permitted by applicable law.
      You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, FerryPal partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys’ fees, arising out of your use of a Third Party Service or your relationship with a Third Party Provider.


      <br /><b>9.8 Beta Services</b><br />

      From time to time, FerryPal may, in its sole discretion, invite you to use, on a trial basis, pre-release or beta features that are in development and not yet available to all merchants (“Beta Services”). Beta Services are not part of the Services, and Beta Services may be subject to additional terms and conditions, which FerryPal will provide to you prior to your use of the Beta Services. Such Beta Services and all associated conversations and materials relating thereto will be considered FerryPal Confidential Information and subject to the confidentiality provisions in this agreement. Without limiting the generality of the foregoing, you agree that you will not make any public statements or otherwise disclose your participation in the Beta Services without FerryPal’s prior written consent. FerryPal makes no representations or warranties that the Beta Services will function. FerryPal may discontinue the Beta Services at any time in its sole discretion. FerryPal will have no liability for any harm or damage arising out of or in connection with a Beta Service. The Beta Services may not work in the same way as a final version. FerryPal may change or not release a final or commercial version of a Beta Service in our sole discretion.


    </p>
    <b>10. Feedback and Reviews</b>
    <p>


      FerryPal welcomes any ideas and/or suggestions regarding improvements or additions to the Services. Under no circumstances will any disclosure of any idea, suggestion or related material or any review of the Services, Third Party Services or any Third Party Provider (collectively, “Feedback") to FerryPal be subject to any obligation of confidentiality or expectation of compensation. By submitting Feedback to FerryPal (whether submitted directly to FerryPal or posted on any FerryPal hosted forum or page), you waive any and all rights in the Feedback and that FerryPal is free to implement and use the Feedback if desired, as provided by you or as modified by FerryPal, without obtaining permission or license from you or from any third party. Any reviews of a Third Party Service or Third Party Provider that you submit to FerryPal must be accurate to the best of your knowledge, and must not be illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable. FerryPal reserves the right (but not the obligation) to remove or edit Feedback of Third Party Services or Third Party Providers, but does not regularly inspect posted Feedback.


    </p>
    <b>11. DMCA Notice and Takedown Procedure</b>
    <p>



      FerryPal supports the protection of intellectual property and asks FerryPal merchants to do the same. It’s our policy to respond to all notices of alleged copyright infringement. If someone believes that one of our merchants is infringing their intellectual property rights, they can send a DMCA Notice to FerryPal’s designated agent using our form. Upon receiving a DMCA Notice, we may remove or disable access to the Materials claimed to be a copyright infringement. Once provided with a notice of takedown, the merchant can reply with a counter notification using our form if they object to the complaint. The original complainant has 14 business days after we receive a counter notification to seek a court order restraining the merchant from engaging in the infringing activity, otherwise we restore the material. Learn more at Reporting Intellectual Property Infringement.


    </p>
    <b>12. Privacy and Data Protection</b>
    <p>



      FerryPal is firmly committed to protecting the privacy of your personal information and the personal information of your customers. By using the Service, you acknowledge and agree that FerryPal’s collection, usage and disclosure of this personal information is governed by our Privacy Policy.

      Additionally, if: (a) you are established in the European Economic Area (EEA); (b) you provide goods or services to customers in the EEA; or (c) you are otherwise subject to the requirements of the EU General Data Protection Regulation, FerryPal’s collection and use of personal information of any European residents is also subject to our Data Processing Addendum.



    </p>
    <b>13. FerryPal Contracting Party</b>
    <p>


      If the billing address of your Store is located in the United States or Canada, this Section 13(1) applies to you:

      “FerryPal Contracting Party” means iShipd LLC., a Delaware, USA company, with offices located at 16192 Coastal Highway, Lewes, DE 19958.

      The parties irrevocably and unconditionally submit to the exclusive jurisdiction of the courts of Massachusetts with respect to any dispute or claim arising out of or in connection with the Terms of Service. The United Nations Convention on Contracts for the International Sale of Goods will not apply to these Terms of Service and is hereby expressly excluded.

    </p>
    <b>14. Term and Termination</b>
    <p>

      The term of these Terms of Service will begin on the date of your completed registration for use of a Service and continue until terminated by us or by you, as provided below (the “Term”).

      You may cancel your Account and terminate the Terms of Service at any time by contacting FerryPal Support and then following the specific instructions indicated to you in FerryPal’s response.

      Without limiting any other remedies, we may suspend or terminate your Account or the Terms of Service for any reason, without notice and at any time (unless otherwise required by law), including if we suspect that you (by conviction, settlement, insurance or escrow investigation, or otherwise) have engaged in fraudulent activity in connection with the use of the Services. Termination of the Terms of Service will be without prejudice to any rights or obligations which arose prior to the date of termination.

      Upon termination of the Services by either party for any reason:

      FerryPal will cease providing you with the Services and you will no longer be able to access your Account;

      unless otherwise provided in the Terms of Service, you will not be entitled to any refunds of any Fees, pro rata or otherwise;

      any outstanding balance owed to FerryPal for your use of the Services through the effective date of such termination will immediately become due and payable in full; and

      your FerryPal Store will be taken offline.

      If you purchased a domain name through FerryPal, upon cancellation your domain will no longer be automatically renewed. Following termination, it will be your sole responsibility to handle all matters related to your domain with the domain provider.

      If there are any outstanding Fees owed by you at the date of termination of the Service, you will receive one final invoice via email. Once that invoice has been paid in full, you will not be charged again.


    </p>
    <b>15. Modifications</b>
    <p>


      We reserve the right, in our sole and absolute discretion, to update or change any portion of the Terms of Service at any time. We will provide you with reasonable advance notice of changes to the Terms of Service that materially adversely affect your use of the Services or your rights under the Terms of Service by sending an email to the Primary Email Address, providing notice through the FerryPal administrative console, or by similar means. However, FerryPal may make changes that materially adversely affect your use of the Services or your rights under the Terms of Service at any time and with immediate effect (i) for legal, regulatory, fraud and abuse prevention, or security reasons; or (ii) to restrict products or activities that we deem unsafe, inappropriate, or offensive. Unless we indicate otherwise in our notice (if applicable), any changes to the Terms of Service will be effective immediately upon posting of such updated terms at this location. Your continued access to or use of the Services after we provide such notice, if applicable, or after we post such updated terms, constitutes your acceptance of the changes and consent to be bound by the Terms of Service as amended. If you do not agree to the amended Terms of Service, you must stop accessing and using the Services.

      FerryPal may change the Fees for the Services from time-to-time. We will provide you with 30 days advanced notice prior to any changes in Fees by sending an email to the Primary Email Account, providing notice through the FerryPal administrative console, or by similar means. FerryPal will not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Services (or any part thereof).


      16. General Conditions

      The Terms of Service, including the documents it incorporates by reference, constitute the entire agreement between you and FerryPal and govern your use of the Services and your Account, superseding any prior agreements between you and FerryPal (including, but not limited to, any prior versions of the Terms of Service).

      The failure of FerryPal to exercise or enforce any right or provision of the Terms of Service will not constitute a waiver of such right or provision. If any provision of the Terms of Service, including all terms and conditions and other documents it incorporates by reference, is held by a court of competent jurisdiction to be contrary to law, such provision will be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law, and the remaining provision of the Terms of Service will remain in full force and effect.

      Save for FerryPal and its affiliates, you or anyone accessing FerryPal Services pursuant to these Terms of Service, unless otherwise provided in these Terms of Service, no person or entity who is not a party to these Terms of Service will have any right to enforce any term of these Terms of Service, regardless of whether such person or entity has been identified by name, as a member of a class or as answering a particular description. For the avoidance of doubt, this will not affect the rights of any permitted assignee or transferee of these Terms.

      The Terms of Service will be governed by and interpreted in accordance with the laws of Massachusetts and the laws of United States applicable therein, without regard to principles of conflicts of laws.

      The Terms of Service may be available in languages other than English. To the extent of any inconsistencies or conflicts between these English Terms of Service and FerryPal’s Terms of Service available in another language, the most current English version of the Terms of Service at https://www.ferrypal.com/terms-and-conditions will prevail. Any disputes arising out of these Terms of Service will be resolved in English unless otherwise determined by FerryPal (acting in its sole discretion) or as required by applicable law.

      All the terms and provisions of the Terms of Service will be binding upon and inure to the benefit of the parties to the Terms of Service and to their respective heirs, successors, permitted assigns and legal representatives. FerryPal will be permitted to assign these Terms of Service without notice to you or consent from you. You will have no right to assign or otherwise transfer the Terms of Service, or any of your rights or obligations hereunder, to any third party without FerryPal’s prior written consent, to be given or withheld in FerryPal’s sole discretion.

      If any provision, or portion of the provision, in these Terms of Service is, for any reason, held to be invalid, illegal or unenforceable in any respect, then such invalidity, illegality or unenforceability will not affect any other provision (or the unaffected portion of the provision) of the Terms of Service, and the Terms of Service will be construed as if such invalid, illegal or unenforceable provision, or portion of the provision, had never been contained within the Terms of Service.

      On termination, all related rights and obligations under the Terms of Service immediately terminate, except that (a) you will remain responsible for performing all of your obligations in connection with transactions entered into before termination and for any liabilities that accrued before or as a result of termination; and Sections 1 (Account Terms), 5 (Payment of Fees), 6 (Confidentiality), 7 (Limitation of Liability and Indemnification), 8.1 (Intellectual Property and Your Materials), 9.7(8)-(10) (Third Party Services, Experts, and Experts Marketplace), 10 (Feedback and Reviews), 12 (Privacy and Data Protection), 13 (FerryPal Contracting Party), 14 (Cancellation and Termination), 15(1) (Modifications), and 16 (General Conditions) will survive the termination or expiration of these Terms of Service.

    </p>
    <p>

      iShipd LLC.<br />
      16192 Coastal Highway<br />
      Lewes, DE 19958<br />
      USA<br />

    </p>
  </>
);

export default TocContent;
