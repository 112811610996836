import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { resetPassword, signIn } from '../actions';
import { StateContext } from '../../../data/state/state-context';
import { validateEmail } from '../../../utils';

const Messages = ({ emailVerified, requestedResetPassword }) => {
  if (/*emailVerified === false || */ requestedResetPassword === true) {
    return (
      <div className="alert alert-success" role="alert">
        Please check your email
      </div>
    );
  }

  return null;
};

const Content = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { user } = rootState.main;
  const history = useHistory();
  const [show1, setShow1] = useState(false);
  const [requestedResetPassword, setRequestedResetPassword] = useState(false);
  const [userName, setUserName] = useState('');
  const [isUserNameValid, setUserNameValid] = useState(''); // 'valid' || 'invalid'
  const [userPwd, setUserPwd] = useState('');
  const [isUserPwdValid, setUserPwdValid] = useState(''); // 'valid' || 'invalid'

  useEffect(() => {
    window.addEventListener(
      'load',
      function () {
        // // Fetch all the forms we want to apply custom Bootstrap validation styles to
        // var forms = document.getElementsByClassName('needs-validation');
        // // Loop over them and prevent submission
        // var validation = Array.prototype.filter.call(forms, function (form) {
        //     form.addEventListener('submit', function (event) {
        //         if (form.checkValidity() === false) {
        //             event.preventDefault();
        //             event.stopPropagation();
        //         }
        //         form.classList.add('was-validated');
        //     }, false);
        // });
      },
      false
    );
  }, []);

  const handleShow1 = () => {
    setShow1(true);
  };

  const handleClose = () => {
    setShow1(false);
  };

  const onResetPassword = () => {
    const email = document.getElementById('forgot-password').value;
    resetPassword(email, setRequestedResetPassword);
    handleClose();
  };

  const onSignIn = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const isValidEmail = validateEmail(userName);
    if (isValidEmail && userPwd) {
      signIn(dispatch, history, userName, userPwd);
    } else {
      if (!isValidEmail) {
        setUserNameValid('invalid');
      }
      if (!userPwd) {
        setUserPwdValid('invalid');
      }
    }
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col ">
          <div className="ms-auth-bg-with-logo" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form ">
            <form className="needs-validation" id="login-form" noValidate>
              <Messages
                emailVerified={user?.emailVerified}
                requestedResetPassword={requestedResetPassword}
              />

              <Link
                className="btn btn-primary  text-white w-50"
                to="/default-login"
                style={{ height: '40px', borderRadius: '36px' }}>
                Login
              </Link>

              <Link
                className=" btn text-dark w-50"
                to="/default-register"
                style={{ height: '40px', borderRadius: '36px' }}>
                Register
              </Link>

              <h3 className="my-3">Welcome</h3>
              <div className="mb-3">
                <label htmlFor="login-user-email">User Name or Email </label>
                <div className="input-group border-0 ">
                  <input
                    type="email"
                    className={` is-${isUserNameValid} inp-field `}
                    id="login-user-email"
                    placeholder="Email Address"
                    required
                    value={userName}
                    onChange={(e) => {
                      const value = e.target.value;
                      const isValid = validateEmail(value);
                      if (isValid) {
                        setUserNameValid('valid');
                      } else {
                        setUserNameValid('');
                      }
                      setUserName(value);
                    }}
                  />
                  <div className={`invalid-feedback is-${isUserNameValid}`}>
                    Please provide a valid email.
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="login-user-password">Password</label>
                <div className="input-group border-0">
                  <input
                    type="password"
                    className={` is-${isUserPwdValid}  inp-field`}
                    id="login-user-password"
                    placeholder="Password"
                    required
                    value={userPwd}
                    onChange={(e) => {
                      setUserPwd(e.target.value);
                      setUserPwdValid(''); // remove error display
                    }}
                  />
                  <div className={`invalid-feedback is-${isUserPwdValid} `}>
                    Please provide a password.
                  </div>
                </div>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="d-block mt-3 ">
                  <Link to="/default-login" className="btn-link" onClick={handleShow1}>
                    Forgot Password?
                  </Link>
                </label>
                <button
                  className="btn btn-primary mt-4 d-block"
                  id="login-user-submit"
                  onClick={onSignIn}
                  style={{ width: '120px', height: '40px', borderRadius: '36px' }}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        className="modal-min"
        show={show1}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
          <i className="flaticon-secure-shield d-block" />
          <h1>Forgot Password?</h1>
          <p>Enter your email to recover your password</p>
          <div className="ms-form-group has-icon">
            <input
              type="text"
              placeholder="Email Address"
              className="form-control"
              id="forgot-password"
            />{' '}
            <i className="material-icons">email</i>
          </div>
          <button onClick={onResetPassword} className="btn btn-primary shadow-none">
            Reset Password
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Content;
