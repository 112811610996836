import * as ActionTypes from '../../data/state/action-types';

const initialState = {
  user: {
    /* isAnonymous, emailVerified, tempUserEmail, popupSwitchFromTempUser */
  },
  placeId: '',
  placeName: '',
  workingHoursText: '',
  imageSrc: '',
  verified: false,
  locationInfo: {
    geocodedAddress: '',
    city: '',
    state: '', // or province
    countryCode: '',
    lat: 0,
    lng: 0
  },
  phoneNumber: undefined, // default for PhnInput library is undefined
  geohash: '',
  address: '',
  hasOnlineOrdering: true,
  onlineOrderingSchedule: {},
  hasOwnDelivery: false,
  deliveryOptions: [],
  hasDunzoDelivery: false,
  urlHost: '',
  currency: 'INR',
  theme: 'custom',
  colors: [], // logo colors - this is stored in redux and db coz we need to extract color in one page and access it in another page to avoid cors issue in ColorExtractor
  primaryColor: '',
  secondaryColor: '',
  subscriptionType: 1, // 0 - Free, 1 - Pro, 2 - Premium, 3 - DigitalMenu & WhatsApp ordering
  localLanguages: [], // launguages other than 'en'
  lng: 'en',
  progressModalShow: false,
  progressModalPercentDone: 0,
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          isAnonymous: action.isAnonymous,
          emailVerified: action.emailVerified,
          tempUserEmail: action.tempUserEmail
        }
      };
    case ActionTypes.POPUP_SWITCH_TEMP_USER:
      return {
        ...state,
        user: {
          ...state.user,
          popupSwitchFromTempUser: action.popupSwitchFromTempUser
        }
      };
    case ActionTypes.SET_STORE:
      return {
        ...state,
        placeId: action.placeId,
        placeName: action.placeName,
        workingHoursText: action.workingHoursText,
        imageSrc: action.imageSrc,
        verified: action.verified,
        locationInfo: action.locationInfo,
        geohash: action.geohash,
        phoneNumber: action.phoneNumber,
        address: action.address,
        hasOnlineOrdering: action.hasOnlineOrdering,
        onlineOrderingSchedule: action.onlineOrderingSchedule,
        hasOwnDelivery: action.hasOwnDelivery,
        deliveryOptions: action.deliveryOptions,
        hasDunzoDelivery: action.hasDunzoDelivery,
        urlHost: action.urlHost,
        currency: action.currency,
        theme: action.theme,
        colors: action.colors,
        primaryColor: action.primaryColor,
        secondaryColor: action.secondaryColor,
        subscriptionType: action.subscriptionType,
        localLanguages: action.localLanguages
      };
    case ActionTypes.UPDATE_STORE:
      return {
        ...state,
        placeName: action.placeName,
        workingHoursText: action.workingHoursText,
        imageSrc: action.imageSrc,
        locationInfo: action.locationInfo,
        geohash: action.geohash,
        phoneNumber: action.phoneNumber,
        address: action.address,
        hasOnlineOrdering: action.hasOnlineOrdering,
        onlineOrderingSchedule: action.onlineOrderingSchedule,
        hasOwnDelivery: action.hasOwnDelivery,
        deliveryOptions: action.deliveryOptions,
        hasDunzoDelivery: action.hasDunzoDelivery,
        currency: action.currency,
        colors: action.colors
      };
    case ActionTypes.UPDATE_THEME:
      return {
        ...state,
        theme: action.theme,
        primaryColor: action.primaryColor,
        secondaryColor: action.secondaryColor
      };
    case ActionTypes.PROGRESS_MODAL:
      return {
        ...state,
        progressModalShow: action.progressModalShow,
        progressModalPercentDone: action.progressModalPercentDone
      };
    case ActionTypes.TOGGLE_ISLOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case ActionTypes.UPDATE_LOCAL_LANGUAGE:
      return {
        ...state,
        localLanguages: action.localLanguages
      };
    case ActionTypes.SET_LANGUAGE:
      return {
        ...state,
        lng: action.lng
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
