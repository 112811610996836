import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { StateContext } from '../../../data/state/state-context';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

const TopnavigationEx = ({ slug }) => {
  const [rootState] = useContext(StateContext);
  const { placeName, urlHost, imageSrc } = rootState.main;
  return (
    <>
      <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
        <Navbar.Brand href={`https://${urlHost}`}>
          <img
            src={imageSrc}
            style={{ width: 30, height: 30, verticalAlign: 'middle' }}
            className="d-inline-block rounded-circle"
            alt=""
          />
          <span className="h4 p-2 align-middle text-white">{placeName}</span>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav>
            <NavLink eventKey="1" as={Link} to={`/m/${slug}`} className="h6 text-white m-1 mr-3">
              Printable Menu
            </NavLink>
            <NavLink eventKey="2" as={Link} to={`/so/${slug}`} className="h6 text-white m-1 mr-3 ">
              Smart Qr Card
            </NavLink>
            <NavLink eventKey="3" as={Link} to={`/st/${slug}`} className="h6 text-white m-1 mr-3">
              Smart Table Card
            </NavLink>
            <a
              eventKey="4"
              className="h6 text-white m-1"
              href={`https://${urlHost}`}
              target="_PARENT">
              Digital Menu (not activated)
            </a>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default TopnavigationEx;
