import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import MultiStep from './react-multistep';
import SelectStore from './SelectStore';
import PreviewMenu from './PreviewMenu';
import ImportConfirmation from './ImportConfirmation';
import { StateContext } from '../../../data/state/state-context';
import { doImport } from '../../ImportMenu/actions';

const prevStyle = {};
const nextStyle = {};
const Steps = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const history = useHistory();
  const [storesDetails, setStoresDetails] = useState([]);
  const [selectedStore, setSelectedStore] = useState(-1);
  const gotoManualAdd = () => history.push('/add-multiple-product');

  const steps = [
    {
      component: (
        <SelectStore
          storesDetails={storesDetails}
          setStoresDetails={setStoresDetails}
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
        />
      )
    },
    {
      component: (
        <PreviewMenu
          storesDetails={storesDetails}
          selectedStore={selectedStore}
          gotoManualAdd={gotoManualAdd}
        />
      )
    },
    { component: <ImportConfirmation /> }
  ];

  const onFinish = () =>
    doImport(history, dispatch, rootState.main.placeId, rootState.importMenu.menuItems);

  return (
    <MultiStep steps={steps} prevStyle={prevStyle} nextStyle={nextStyle} onFinish={onFinish} />
  );
};

export default Steps;
