import React, { useEffect, useState, useRef } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { saveLead } from '../actions';
import { getCountryCode } from '../../../utils';

const ContentBasic = ({ setPage, setPlaceName, setPlacePhone }) => {
  const [placeName1, setPlaceName1] = useState('');
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState('IN');
  const [phNo, setPhNo] = useState();
  const [isPlaceNameValid, setIsPlaceNameValid] = useState(true);
  const [videoOpen, setVideoOpen] = useState(false);
  const phoneInputRef = useRef();

  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.style.borderWidth = 0; // style prop on the component gets ignored
    }

    setCountryCode(getCountryCode());
  }, []);

  const onContinue = (event) => {
    // TODO: there is bootstrap validation that kicks in during submit
    // Need to see how to make it work even when we handle submit here
    event.preventDefault();
    event.stopPropagation();

    setPlaceName(placeName1);
    setPlacePhone(phNo);
    saveLead(placeName1, phNo, email);
    setPage('create-more');
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <form className="needs-validation" noValidate>
              <h3>Create Store</h3>
              <div className="mb-3">
                <label htmlFor="create-store-name">Name of the store *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="create-store-name"
                    style={isPlaceNameValid ? {} : { borderColor: 'red' }}
                    onFocus={() => setIsPlaceNameValid(true)}
                    value={placeName1}
                    onChange={(e) => {
                      const _placeName = e.target.value;
                      setPlaceName1(_placeName);
                    }}
                    placeholder="Store name"
                    autoComplete="new-name"
                    required
                  />
                  <div className="invalid-feedback">Please provide a valid name.</div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="create-store-phone">Phone</label>
                <div className="form-control">
                  <PhoneInput
                    ref={phoneInputRef}
                    placeholder="Enter phone number"
                    value={phNo}
                    onChange={setPhNo}
                    defaultCountry={countryCode}
                    international={true}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="create-store-email">Email</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="create-store-email"
                    onFocus={() => setIsPlaceNameValid(true)}
                    value={email}
                    onChange={(e) => {
                      const _email = e.target.value;
                      setEmail(_email);
                    }}
                    placeholder="Store email"
                    autoComplete="new-email"
                  />
                  <div className="invalid-feedback">Please provide a valid name.</div>
                </div>
              </div>
              <button className="btn btn-primary mt-4 d-block w-100" onClick={onContinue}>
                Continue
              </button>
              <p className="mb-0 mt-4 text-center text-lg">
                <ModalVideo
                  channel="youtube"
                  autoplay
                  isOpen={videoOpen}
                  videoId={'7U-cP6ek_uI'}
                  onClose={() => setVideoOpen(false)}
                />
                <span role="button" className="btn-link" onClick={() => setVideoOpen(true)}>
                  Watch this quick video for help
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentBasic;
