import React, { Component } from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import Homecontent from './Homecontent';

class Dashboard extends Component {
  render() {
    return (
      <div className="ms-body ms-aside-left-open ms-primary-theme">
        <Sidenavigation />
        <main className="body-content">
          <Topnavigation />
          <Homecontent />
        </main>
        <Quickbar />
      </div>
    );
  }
}

export default Dashboard;
