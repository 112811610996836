import React from 'react';
import { SwatchesPicker } from 'react-color';

const ColorPicker = ({ show, onChangeComplete, handleClose }) => {
  if (show !== true) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: '2'
      }}>
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }}
        onClick={handleClose}
      />
      <SwatchesPicker onChangeComplete={onChangeComplete} />
    </div>
  );
};

export default ColorPicker;
