import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { db } from '../../../lib/firebase';
import { ArrowBack } from '@mui/icons-material';

const StoreUrl = ({ prevPage, setPrevPage, setPage, leadId, aggrUrl, setAggrUrl }) => {
  console.log(prevPage);
  const [videoOpen, setVideoOpen] = useState(false);
  const [isUrl, setIsUrl] = useState(true);
  const handleNoUrl = () => {
    if (aggrUrl.length === 0) {
      setPrevPage([...prevPage, 'store-url']);
      setPage('menu-logo');
    }
  };
  const handleTheirContact = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (aggrUrl.length === 0) {
      alert('please Enter url');
      setIsUrl(false);
      return;
    }
    const docRef = doc(db, 'leads', leadId);

    try {
      await updateDoc(docRef, {
        onlineStoreURL: aggrUrl
      });
      console.log('Document updated with ID: ', leadId);
      setPrevPage([...prevPage, 'store-url']);
      setPage('store-contacts');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };
  const handleBack = () => {
    const prev = prevPage[prevPage.length - 1];
    setPrevPage((prevItems) => prevItems.filter((item, i) => i !== prevItems.length - 1));
    setPage(prev);
  };
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <div className="m-3 pt-5">
              <ArrowBack className="mb-5" onClick={handleBack} />

              <label htmlFor="create-store-aggr-url">
                Do you currently have an online store? (
                <span role="button" className="btn-link" onClick={() => setVideoOpen(true)}>
                  See how to find the link
                </span>
                )
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="create-store-aggr-url"
                  value={aggrUrl}
                  style={isUrl ? {} : { borderColor: 'red' }}
                  onFocus={() => setIsUrl(true)}
                  onChange={(e) => {
                    setAggrUrl(e.target.value);
                  }}
                  placeholder="Online store link"
                  autoComplete="new-name"
                  required
                />

                <div className="invalid-feedback">Please provide a valid name.</div>
              </div>
              <div className="d-flex justify-content-center">
                {aggrUrl.length > 0 ? (
                  <button className="btn btn-primary text-light" onClick={handleTheirContact}>
                    Submit
                  </button>
                ) : (
                  <button className="btn btn-primary text-light" onClick={handleNoUrl}>
                    I do not have one{' '}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <p className="mb-0 mt-4 text-center text-lg">
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={videoOpen}
            videoId={'7U-cP6ek_uI'}
            onClose={() => setVideoOpen(false)}
          />
        </p>
      </div>
    </div>
  );
};

export default StoreUrl;
