import React, {useContext} from 'react'

import { StateContext } from '../../../data/state/state-context';

const StepThree = () => {
  const [rootState] = useContext(StateContext);

  if(rootState.importMenu.menuItems.length === 0){
    return (
      <div className="alert alert-danger" role="alert">
        There is nothing to import
      </div>
    );
  }

  return (
    <div className="ms-wizard-step">
      <p class="h6 px-4 py-4">
        Your menu is ready to be imported. After you hit Finish,
        you can view them and make more changes from the Menu List.
      </p>
    </div>
  )
};

export default StepThree;
