import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import Breadcrumb from './Breadcrumb';
import Ordertable from './Ordertable';
import { StateContext } from '../../../data/state/state-context';
import i18n from '../../../i18n';

const Orders = () => {
  const [rootState] = useContext(StateContext);

  const history = useHistory();
  const isBasicPlan = rootState.main.subscriptionType === 0;
  const showItemsAlert = !rootState.items.items || rootState.items.items.length === 0;
  const showVerificationAlert = showItemsAlert !== true && rootState.main.verified !== true;

  if (!rootState?.main?.user) {
    history.push('/');
  }

  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <div className="ms-content-wrapper">
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb />
              {showItemsAlert === true && (
                <div className="alert alert-success" role="alert">
                  {i18n.t('orders:not_added_any_item')} <Link to="/add-product">Add them now.</Link>
                  {i18n.t('orders:you_may_also')}{' '}
                  <Link to="/import-menu">Import from menu image or PDF file.</Link>
                </div>
              )}
              {showVerificationAlert === true && (
                <div className="alert alert-primary" role="alert">
                  {i18n.t('orders:your_store_is_being_verified')}
                </div>
              )}
              {isBasicPlan && (
                <div className="alert alert-success" role="alert">
                  Upgrade to Pro plan and benefit from online orders and more.{' '}
                  <Link to={{ pathname: '/store-subscribe', state: { isUpgrading: true } }}>
                    Upgrade Now.
                  </Link>
                </div>
              )}
              {isBasicPlan !== true && <Ordertable />}
            </div>
          </div>
        </div>
      </main>
      <Quickbar />
    </div>
  );
};

export default Orders;
