import React, { useContext, useEffect, useState, useRef } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { ColorExtractor } from 'react-color-extractor';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { StateContext } from '../../../data/state/state-context';
import PlacesAutocomplete from '../../CreateStore/components/PlaceAutocomplete';
import OwnDelivery from '../../CreateStore/components/OwnDelivery';
import StoreQRCode from './StoreQRCode';
import { currencies, defaultDeliveryCharge, languages } from '../../../data/constants';
import CurrencySelect from '../../CreateStore/components/Currency';
import ConfigureWebsiteForm from '../../ConfigureWebsite/components/ConfigureWebsiteForm';
import DunzoDelivery from '../../CreateStore/components/DunzoDelivery';

const SelectImage = ({ onImageChange }) => (
  <div className="mb-2">
    <label htmlFor="update-store-image">Image of the store</label>
    <div className="input-group">
      <input
        type="file"
        className="form-control"
        id="update-store-image"
        placeholder="Select image..."
        required
        onChange={onImageChange}
      />
    </div>
    <div className="invalid-feedback">Invalid image</div>
  </div>
);

const StoreImage = ({ storeImgSrc, onRemoveImage }) => (
  <div className="mb-2">
    <div>
      <img className="d-block w-100" src={storeImgSrc} alt="Store" />
    </div>

    <div className="new">
      <button type="button" className="btn btn-secondary" onClick={onRemoveImage}>
        Remove
      </button>
    </div>
  </div>
);
const DaySchedule = ({
  dayObject,
  Day,
  dayNumber,
  handleFromTimeChange,
  handleToTimeChange,
  handleEnabling
}) => (
  <div className="my-2">
    <div className="d-md-inline-flex">
      <label className="ms-switch">
        <input
          type="checkbox"
          checked={dayObject?.enabled}
          defaultChecked={dayObject?.enabled === true}
          onClick={() => handleEnabling(dayNumber, !dayObject?.enabled)}
        />
        <span className="ms-switch-slider ms-switch-success round" />
      </label>
      <h6 className="mt-2">{Day}</h6>
      <div className="d-flex">
        <input
          type="time"
          class="form-control ml-2"
          pattern="[0-9]{2}:[0-9]{2}"
          value={dayObject?.fromTime}
          required
          onChange={(e) => {
            handleFromTimeChange(dayNumber, e.target.value);
          }}
        />
        <span className="m-2">—</span>
        <input
          type="time"
          class="form-control"
          pattern="[0-9]{2}:[0-9]{2}"
          value={dayObject?.toTime}
          required
          onChange={(e) => {
            handleToTimeChange(dayNumber, e.target.value);
          }}
        />
      </div>
    </div>
  </div>
);
const Content = ({ placeId, placeInfo, onUpdateStore, updateLocalLang }) => {
  const [rootState] = useContext(StateContext);
  const noDeliveryOption =
    rootState.main.subscriptionType === 0 || rootState.main.subscriptionType === 3;
  const [storeImgSrc, setStoreImgSrc] = useState(null);
  const [removeImgSrc, setRemoveImgSrc] = useState(null);
  const [hasImageModified, setHasImageModified] = useState(true);
  const [storeImgFile, setStoreImgFile] = useState(null);
  const [placeName, setPlaceName] = useState('');
  const [workingHoursText, setWorkingHoursText] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [currency, setCurrency] = useState('INR');
  const [countryCode, setCountryCode] = useState('IN');
  const [phNo, setPhNo] = useState();
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [isPlaceNameValid, setIsPlaceNameValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [hasOwnDelivery, setHasOwnDelivery] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState([defaultDeliveryCharge[currency]]);
  const [hasDunzoDelivery, setHasDunzoDelivery] = useState(false);
  const [hasOnlineOrdering, setHasOnlineOrdering] = useState(true);
  const [onlineOrderingScheduleLocal, setOnlineOrderingScheduleLocal] = useState({
    0: { enabled: false, fromTime: '', toTime: '' }, // Sunday
    1: { enabled: false, fromTime: '', toTime: '' }, // Monday
    2: { enabled: false, fromTime: '', toTime: '' }, // Tuesday
    3: { enabled: false, fromTime: '', toTime: '' }, // Wednesday
    4: { enabled: false, fromTime: '', toTime: '' }, // Thursday
    5: { enabled: false, fromTime: '', toTime: '' }, // Friday
    6: { enabled: false, fromTime: '', toTime: '' } // Saturday
  });

  const [colors, setColors] = useState([]);
  const phoneInputRef = useRef();
  const convertMinutesToTime = (minutes) => {
    if (minutes === '') return '';
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${mins < 10 ? '0' : ''}${mins}`;
  };

  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.style.borderWidth = 0; // style prop on the component gets ignored
    }

    // initialise state
    console.log(placeInfo);

    setHasOnlineOrdering(placeInfo.hasOnlineOrdering);
    if (
      placeInfo.onlineOrderingSchedule &&
      Object.keys(placeInfo.onlineOrderingSchedule).length > 0
    ) {
      const newObj = {};
      for (const [day, dayObj] of Object.entries(placeInfo.onlineOrderingSchedule)) {
        newObj[day] = {
          enabled: dayObj.enabled,
          fromTime: convertMinutesToTime(dayObj.fromTime),
          toTime: convertMinutesToTime(dayObj.toTime)
        };
      }
      setOnlineOrderingScheduleLocal(newObj);
    }

    setPlaceName(placeInfo.placeName);
    setWorkingHoursText(placeInfo.workingHoursText ?? '');
    setAddress(placeInfo.address);
    setColors(placeInfo.colors);
    const _currency = placeInfo.currency ? placeInfo.currency : 'INR';
    setCurrency(_currency);
    if (_currency !== 'INR') {
      setDeliveryOptions([defaultDeliveryCharge[_currency]]);
      const currencyInfo = currencies.find((x) => x.id === _currency);
      if (currencyInfo) {
        setCountryCode(currencyInfo.countryCode);
      }
    }
    setPhNo(placeInfo.phoneNumber ? `+${placeInfo.phoneNumber}` : undefined); // default is undefined
    if (placeInfo.locationInfo) {
      setLatLng({
        lat: placeInfo.locationInfo.lat ? placeInfo.locationInfo.lat : 0,
        lng: placeInfo.locationInfo.lng ? placeInfo.locationInfo.lng : 0
      });
    }
    if (placeInfo.imageSrc) {
      setStoreImgSrc(placeInfo.imageSrc);
      setRemoveImgSrc(placeInfo.imageSrc);
      setHasImageModified(false);
    }

    setHasOwnDelivery(placeInfo.hasOwnDelivery === true);
    setHasDunzoDelivery(placeInfo.hasDunzoDelivery === true);

    if (placeInfo.deliveryOptions && placeInfo.deliveryOptions.length > 0) {
      setDeliveryOptions([...placeInfo.deliveryOptions]);
    }
  }, [placeInfo, placeId]);

  useEffect(() => {
    //initialize datatable
    function formValidation() {
      window.addEventListener(
        'load',
        function () {
          // Fetch all the forms we want to apply custom Bootstrap validation styles to
          // var forms = document.getElementsByClassName('needs-validation');
          // Loop over them and prevent submission
          // var validation = Array.prototype.filter.call(forms, function (form) {
          //     form.addEventListener('submit', function (event) {
          //         if (form.checkValidity() === false) {
          //             event.preventDefault();
          //             event.stopPropagation();
          //         }
          //         form.classList.add('was-validated');
          //     }, false);
          // });
        },
        false
      );
    }
    formValidation();
  }, []);

  const handleFromTimeChange = (dayNumber, value) => {
    setOnlineOrderingScheduleLocal((prevObj) => ({
      ...prevObj,
      [dayNumber]: {
        ...prevObj[dayNumber],
        fromTime: value
      }
    }));
  };

  const handleToTimeChange = (dayNumber, value) => {
    setOnlineOrderingScheduleLocal((prevObj) => ({
      ...prevObj,
      [dayNumber]: {
        ...prevObj[dayNumber],
        toTime: value
      }
    }));
  };
  const handleEnabling = (dayNumber, value) => {
    setOnlineOrderingScheduleLocal((prevObj) => ({
      ...prevObj,
      [dayNumber]: {
        ...prevObj[dayNumber],
        enabled: value
      }
    }));
  };
  useEffect(() => {
    let _currency = 'INR';
    const currencyInfo = currencies.find((x) => x.countryCode === countryCode);
    if (currencyInfo) {
      _currency = currencyInfo.id;
    }
    setCurrency(_currency);
  }, [countryCode]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function () {
        setStoreImgSrc(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setStoreImgFile(event.target.files[0]);
      event.target.value = null; // clear the input field
    }
  };

  const onRemoveImage = () => {
    setStoreImgSrc(null);
    setStoreImgFile(null);
    setHasImageModified(true);
  };
  const convertTimeToMinutes = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  };
  const onUpdate = (event) => {
    // TODO: there is bootstrap validation that kicks in during submit
    // Need to see how to make it work even when we handle submit here
    event.preventDefault();
    event.stopPropagation();
    const onlineOrderingSchedule = {};
    for (const [day, dayObj] of Object.entries(onlineOrderingScheduleLocal)) {
      onlineOrderingSchedule[day] = {
        enabled: dayObj.enabled,
        fromTime: convertTimeToMinutes(dayObj.fromTime),
        toTime: convertTimeToMinutes(dayObj.toTime)
      };
    }
    const locationInfo = {
      ...latLng,
      city,
      state,
      countryCode,
      geocodedAddress: address
    };
    const _storeImgFile = storeImgSrc ? storeImgFile : null;
    const _removeImgSrc = removeImgSrc && hasImageModified ? removeImgSrc : '';
    if (placeName.trim() !== '' && address.trim() !== '') {
      onUpdateStore(
        placeName,
        workingHoursText,
        locationInfo,
        _storeImgFile,
        phNo,
        placeInfo.imageSrc,
        _removeImgSrc,
        hasImageModified,
        hasOnlineOrdering,
        onlineOrderingSchedule,
        hasOwnDelivery,
        deliveryOptions,
        hasDunzoDelivery,
        currency,
        colors
      );
    } else {
      setIsPlaceNameValid(placeName.trim().length > 0);
      setIsAddressValid(address.trim().length > 0);
    }
  };

  const scheduleStyles = hasOnlineOrdering
    ? { visibility: 'visible' }
    : { visibility: 'hidden', height: 1 };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12 ms-panel">
          <div className="ms-auth-form my-2">
            <form className="needs-validation" noValidate>
              <div>
                <h3>Online Ordering</h3>
                <div className="my-2">
                  <div className="d-md-inline-flex">
                    <label className="ms-switch">
                      <input
                        type="checkbox"
                        checked={hasOnlineOrdering}
                        defaultChecked={hasOnlineOrdering === true}
                        onClick={(e) => {
                          setHasOnlineOrdering(e.target.checked);
                        }}
                      />
                      <span className="ms-switch-slider ms-switch-success round" />
                    </label>
                    <span> Online Ordering Available </span>
                  </div>
                </div>
              </div>
              <div style={scheduleStyles}>
                <h3>Schedule</h3>
                <p>
                  If no schedule is set, that would indicate Online Ordering is currently available
                </p>
                <DaySchedule
                  dayObject={onlineOrderingScheduleLocal[0]}
                  Day="Sunday"
                  dayNumber="0"
                  handleFromTimeChange={handleFromTimeChange}
                  handleToTimeChange={handleToTimeChange}
                  handleEnabling={handleEnabling}
                />
                <DaySchedule
                  dayObject={onlineOrderingScheduleLocal[1]}
                  Day="Monday"
                  dayNumber="1"
                  handleFromTimeChange={handleFromTimeChange}
                  handleToTimeChange={handleToTimeChange}
                  handleEnabling={handleEnabling}
                />
                <DaySchedule
                  dayObject={onlineOrderingScheduleLocal[2]}
                  Day="Tuesday"
                  dayNumber="2"
                  handleFromTimeChange={handleFromTimeChange}
                  handleToTimeChange={handleToTimeChange}
                  handleEnabling={handleEnabling}
                />
                <DaySchedule
                  dayObject={onlineOrderingScheduleLocal[3]}
                  Day="Wednesday"
                  dayNumber="3"
                  handleFromTimeChange={handleFromTimeChange}
                  handleToTimeChange={handleToTimeChange}
                  handleEnabling={handleEnabling}
                />
                <DaySchedule
                  dayObject={onlineOrderingScheduleLocal[4]}
                  Day="Thursday"
                  dayNumber="4"
                  handleFromTimeChange={handleFromTimeChange}
                  handleToTimeChange={handleToTimeChange}
                  handleEnabling={handleEnabling}
                />
                <DaySchedule
                  dayObject={onlineOrderingScheduleLocal[5]}
                  Day="Friday"
                  dayNumber="5"
                  handleFromTimeChange={handleFromTimeChange}
                  handleToTimeChange={handleToTimeChange}
                  handleEnabling={handleEnabling}
                />
                <DaySchedule
                  dayObject={onlineOrderingScheduleLocal[6]}
                  Day="Saturday"
                  dayNumber="6"
                  handleFromTimeChange={handleFromTimeChange}
                  handleToTimeChange={handleToTimeChange}
                  handleEnabling={handleEnabling}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      {!noDeliveryOption && (
        <div className="row">
          <div className="col-md-12 ms-panel">
            <div className="ms-auth-form my-2">
              <form className="needs-validation" noValidate>
                <h3>Delivery options</h3>
                <DunzoDelivery
                  hasDunzoDelivery={hasDunzoDelivery}
                  setHasDunzoDelivery={setHasDunzoDelivery}
                />
                <OwnDelivery
                  hasOwnDelivery={hasOwnDelivery}
                  setHasOwnDelivery={setHasOwnDelivery}
                  deliveryOptions={deliveryOptions}
                  setDeliveryOptions={setDeliveryOptions}
                  currency={currency}
                />
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12 ms-panel">
          <div className="ms-auth-form my-2">
            <form className="needs-validation" noValidate>
              <h3>Website</h3>
              <div className="p-2">
                <a
                  href={`https://${placeInfo.urlHost}`}
                  className="link-primary"
                  rel="noopener noreferrer"
                  target="_blank">
                  {`https://${placeInfo.urlHost}`}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-box-arrow-up-right m-2 mb-3"
                      viewBox="0 0 16 16">
                      <path
                        fill-rule="evenodd"
                        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <StoreQRCode placeName={placeName} urlHost={placeInfo.urlHost} />
            </form>
            <ConfigureWebsiteForm />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 ms-panel">
          <div className="ms-auth-form my-2">
            <form className="needs-validation" noValidate>
              <h3>Store info</h3>
              <div className="mb-3">
                <label htmlFor="update-store-name">Name of the store *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="update-store-name"
                    placeholder="Store name"
                    style={isPlaceNameValid ? {} : { borderColor: 'red' }}
                    onFocus={() => setIsPlaceNameValid(true)}
                    value={placeName}
                    onChange={(e) => {
                      setPlaceName(e.target.value);
                    }}
                    required
                  />
                  <div className="invalid-feedback">Please provide a valid name.</div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="update-store-name">Working hours</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="update-working-hours-text"
                    placeholder="11am-4pm, 6:30pm-11pm"
                    value={workingHoursText}
                    onChange={(e) => {
                      setWorkingHoursText(e.target.value);
                    }}
                  />
                  <div className="invalid-feedback">Please provide a valid text.</div>
                </div>
              </div>
              <PlacesAutocomplete
                setAddress={setAddress}
                setCity={setCity}
                setState={setState}
                placeholder="Address"
                setLatLng={setLatLng}
                defaultValue={placeInfo.address}
                isValid={isAddressValid}
                onFocusInput={() => setIsAddressValid(true)}
                setCountryCode={setCountryCode}
              />
              <div className="mb-3">
                <label htmlFor="update-store-phone">Phone</label>
                <div className="form-control">
                  <PhoneInput
                    ref={phoneInputRef}
                    placeholder="Enter phone number"
                    value={phNo}
                    onChange={setPhNo}
                    defaultCountry={countryCode}
                    international={true}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="storecurrency">Currency</label>
                <div className="input-group">
                  <CurrencySelect selectedId={currency} onSelect={setCurrency} />
                  <div className="invalid-feedback">Please select a currency</div>
                </div>
              </div>
              {storeImgSrc !== null ? (
                <StoreImage storeImgSrc={storeImgSrc} onRemoveImage={onRemoveImage} />
              ) : (
                <SelectImage onImageChange={onImageChange} />
              )}
              <div className="mb-3">
                {storeImgSrc !== null && <ColorExtractor src={storeImgSrc} getColors={setColors} />}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 ms-panel">
          <div className="ms-auth-form my-2">
            <form className="needs-validation" noValidate>
              <h3>Local Languages</h3>
              <Box alignItems="flex-start">
                {languages.map((obj, idx) => (
                  <Box display="flex" alignItems="center" key={`lang_${idx}`}>
                    <Checkbox
                      checked={obj.lng === 'en' || placeInfo.localLanguages.indexOf(obj.lng) !== -1}
                      disabled={obj.lng === 'en'}
                      onChange={() => updateLocalLang(obj.lng)}
                    />
                    <Typography>{obj.name}</Typography>
                  </Box>
                ))}
              </Box>
            </form>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <button className="btn btn-primary col-4" onClick={onUpdate}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Content;
