import React from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Content from './Content';
import LoadingSpinner from '../../../shared/loadingspinner';

const ImportMenu = () => {
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <Content />
        <LoadingSpinner asOverlay />
      </main>
    </div>
  );
};

export default ImportMenu;
