import React, { useContext, useState } from 'react';
import { logEvent } from 'firebase/analytics';
import ContentBasic from './ContentBasic';
import { StateContext } from '../../../data/state/state-context';

import LoadingSpinner from '../../../shared/loadingspinner';
import ProductFeatures from './ProductFeatures';
import { analytics } from '../../../lib/firebase';
import ShowStores from './ShowStores';
import StoreUrl from './StoreUrl';
import MenuAndLogo from './MenuAndLogo';
import StoreContacts from './StoreContacts';
import Payment from './Payment';
import ContactUs from './ContactUs';

const Content = () => {
  const [page, setPage] = useState('intro');
  const [prevPage, setPrevPage] = useState(['intro']);
  const [leadId, setLeadId] = useState(null);
  const [placeName, setPlaceName] = useState('');
  const [location, setLocation] = useState('');
  const [placePhone, setPlacePhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [aggrUrl, setAggrUrl] = useState('');
  const [storeImgSrc, setStoreImgSrc] = useState(null);
  const [storeMenuSrc, setStoreMenuSrc] = useState(null);
  const [storeImgFile, setStoreImgFile] = useState(null);
  const [menuLogoLater, setMenuLogoLater] = useState(false);
  const [colors, setColors] = useState([]);
  const [, dispatch] = useContext(StateContext);

  logEvent(analytics, 'create_store_ex', { page });
  switch (page) {
    case 'intro':
      return <ProductFeatures setPage={setPage} />;

    case 'create-basic':
      return (
        <ContentBasic
          prevPage={prevPage}
          setPrevPage={setPrevPage}
          setPage={setPage}
          placeName={placeName}
          location={location}
          setPlaceName={setPlaceName}
          setLocation={setLocation}
          setLeadId={setLeadId}
        />
      );
    case 'show-stores':
      return (
        <ShowStores
          prevPage={prevPage}
          setPrevPage={setPrevPage}
          placeName={placeName}
          leadId={leadId}
          location={location}
          setPage={setPage}
        />
      );
    case 'store-url':
      return (
        <StoreUrl
          prevPage={prevPage}
          setPrevPage={setPrevPage}
          setPage={setPage}
          leadId={leadId}
          aggrUrl={aggrUrl}
          setAggrUrl={setAggrUrl}
        />
      );
    case 'menu-logo':
      return (
        <MenuAndLogo
          prevPage={prevPage}
          setPrevPage={setPrevPage}
          setPage={setPage}
          leadId={leadId}
          storeImgSrc={storeImgSrc}
          setStoreImgSrc={setStoreImgSrc}
          storeMenuSrc={storeMenuSrc}
          setStoreMenuSrc={setStoreMenuSrc}
          storeImgFile={storeImgFile}
          setStoreImgFile={setStoreImgFile}
          menuLogoLater={menuLogoLater}
          setMenuLogoLater={setMenuLogoLater}
          setColors={setColors}
        />
      );
    case 'store-contacts':
      return (
        <StoreContacts
          prevPage={prevPage}
          setPrevPage={setPrevPage}
          setPage={setPage}
          leadId={leadId}
          placePhone={placePhone}
          setPlacePhone={setPlacePhone}
          userEmail={userEmail}
          setUserEmail={setUserEmail}
        />
      );
    case 'payment':
      return (
        <Payment prevPage={prevPage} setPrevPage={setPrevPage} setPage={setPage} leadId={leadId} />
      );

    case 'contact-us':
      return <ContactUs setPage={setPage} />;
    default:
      return <ProductFeatures setPage={setPage} />;
  }
};

const CreateStoreDm = () => {
  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
      <main className="body-content">
        <Content />
        <LoadingSpinner asOverlay />
      </main>
    </div>
  );
};

export default CreateStoreDm;
