import React, { useState, useEffect, useContext, useCallback } from 'react';
import { StateContext } from '../../../data/state/state-context';
import OnlineStores from './OnlineStores';
import settings from '../../../../config/settings';
import SearchStores from './SearchStores';
// import data from './data.json';

const SelectStore = ({ storesDetails, setStoresDetails, selectedStore, setSelectedStore }) => {
  const [rootState, dispatch] = useContext(StateContext);
  const { placeName, locationInfo } = rootState.main;
  const { city, state } = locationInfo;
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(4);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingProgress((prevCount) => (prevCount < 99 ? prevCount + 1 : 99));
    }, 800);

    return () => clearInterval(intervalId);
  }, []);

  const fetchOnlineStores = useCallback(
    async (query) => {
      try {
        // test data start //
        // setLoading(false);
        // setStoresDetails(data);
        // test data end  //

        setLoadingProgress(4); // reset
        setLoading(true);
        const response = await fetch(
          `${settings.croquetUrl}/matchingstores-details?q=${encodeURIComponent(query)}`
        );
        setLoading(false);
        if (response.status === 200) {
          let data = await response.json();
          setStoresDetails(data);
        } else {
          setLoadingError(true);
        }
      } catch (error) {
        console.log('fetchOnlineStores Error => ', error.message);
        setLoading(false);
        setLoadingError(true);
      }
    },
    [setStoresDetails]
  );

  useEffect(() => {
    fetchOnlineStores(`${placeName}, ${city}, ${state}`);
  }, [fetchOnlineStores, placeName, city, state]);

  if (loadingError === true) {
    return (
      <div className="alert alert-danger" role="alert">
        Error finding your store's menu online
      </div>
    );
  }

  return (
    <div className="ms-wizard-step">
      <div className="form-row">
        <div className="align-self-center col-12">
          <SearchStores fetchOnlineStores={fetchOnlineStores} disabled={loading} />
        </div>
      </div>
      {loading === true ? (
        <div className="m-4 py-4">
          <span className="my-2">
            Finding your store/menu online and analyzing. This may take a couple of minutes.
          </span>
          <div className="progress">
            <div
              className="progress-bar bg-info"
              role="progressbar"
              style={{ width: `${loadingProgress}%` }}
              aria-valuenow={loadingProgress}
              aria-valuemin={4}
              aria-valuemax={100}>
              {`${loadingProgress}%`}
            </div>
          </div>
        </div>
      ) : (
        <div className="form-row">
          <div className="mb-2 px-4 py-4 align-self-center col-12">
            <label htmlFor="menu-file">
              We found the following stores online which sorta match the details you provided.
              <br />
              Select the one that is yours or search again with more specific keywords.
            </label>
            <OnlineStores
              storesDetails={storesDetails}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectStore;
