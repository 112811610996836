import {
  EmailAuthProvider,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  reauthenticateWithCredential
} from 'firebase/auth';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';

import { auth, db } from '../../lib/firebase';
import * as ActionTypes from '../../data/state/action-types';
import { setStoreAction } from '../CreateStore/actions';
import { defaultCategories } from '../../data/constants';
import settings from '../../../config/settings';

const setUserAction = ({ isAnonymous, emailVerified }, tempUserEmail = '') => {
  return {
    type: ActionTypes.SET_USER,
    isAnonymous,
    emailVerified,
    tempUserEmail
  };
};

const popupSwitchFromTempUserAction = (popupSwitchFromTempUser) => {
  return {
    type: ActionTypes.POPUP_SWITCH_TEMP_USER,
    popupSwitchFromTempUser
  };
};

const loadItemsAction = (items) => ({
  type: ActionTypes.LOAD_ITEMS,
  items
});

const loadCategoriesAction = (categories) => ({
  type: ActionTypes.LOAD_CATEGORIES,
  categories
});

const sortItemsByCategoryOrder = (items, categories) => {
  items.sort((a, b) => {
    const categA = categories.find(({ id }) => id === a.category);
    const categB = categories.find(({ id }) => id === b.category);
    if (categA?.order && categB?.order) {
      return categA.order - categB.order;
    }
    return 0;
  });
  return items;
};

const postLoginChores = async (dispatch, history, user) => {
  dispatch(setUserAction(user ?? {})); // TODO: check and set tempUserEmail (ie, email has @myferrypal.com)
  // NOTE: temporarily not enforcing email verification (the check is commented below)
  // TODO: When we enable it back, all the users who are not 2FA'd will need proper handling to re-do the verification
  // Otherwise the user will see just "Please check your email" - there won't be any email other than the one sent during initial signup
  // ALSO NOTE that we now have phone number based users
  if (!user /* || user.emailVerified !== true */) {
    history.push('/default-login');
    return;
  }

  // get store info
  let doc = null;
  const q = query(collection(db, 'places'), where('createdBy', '==', user.uid));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    doc = querySnapshot.docs[0];
  }

  if (doc === null) {
    const q0 = query(collection(db, 'places'), where('storeAdmins', 'array-contains', user.uid));
    const querySnapshot0 = await getDocs(q0);
    if (!querySnapshot0.empty) {
      doc = querySnapshot0.docs[0];
    }
  }

  if (doc === null) {
    history.push('/create-store');
    return;
  }

  const placeId = doc.id;
  const {
    placeName,
    workingHoursText,
    imageSrc,
    verified,
    locationInfo,
    phoneNumber,
    geohash,
    address,
    hasOnlineOrdering,
    onlineOrderingSchedule,
    hasOwnDelivery,
    deliveryOptions,
    hasDunzoDelivery,
    urlHost,
    currency,
    theme,
    colors,
    primaryColor,
    secondaryColor,
    hasSubscribed,
    subscriptionEndDate,
    subscriptionType,
    localLanguages
  } = doc.data();
  dispatch(
    setStoreAction(
      placeId,
      placeName,
      workingHoursText,
      imageSrc,
      verified,
      locationInfo,
      geohash,
      phoneNumber,
      address,
      hasOnlineOrdering ?? true,
      onlineOrderingSchedule ?? {},
      hasOwnDelivery,
      deliveryOptions,
      hasDunzoDelivery,
      urlHost,
      currency,
      theme,
      colors ?? [],
      primaryColor ?? '',
      secondaryColor ?? '',
      subscriptionType ?? 1,
      localLanguages ?? []
    )
  );

  const q3 = query(collection(db, 'categories'), where('placeId', '==', placeId), orderBy('order'));
  const querySnapshot3 = await getDocs(q3);
  const categories = [];
  querySnapshot3.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, ' => ', doc.data());
    const { categoryId, categoryName, dealValidFrom, dealValidTo, discountPercentage, order } =
      doc.data();
    categories.push({
      id: categoryId,
      name: categoryName,
      dealValidFrom,
      dealValidTo,
      discountPercentage,
      order
    });
  });
  dispatch(loadCategoriesAction(categories.length > 0 ? categories : defaultCategories));

  const q2 = query(collection(db, 'items'), where('placeId', '==', placeId));
  const querySnapshot2 = await getDocs(q2);
  const items = [];
  querySnapshot2.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, ' => ', doc.data());
    const {
      itemName,
      description,
      imageSrc,
      available,
      ourSpecial,
      price,
      category,
      dealPrice,
      dealValidFrom,
      dealValidTo
    } = doc.data();
    items.push({
      itemId: doc.id,
      itemName,
      description,
      imageSrc,
      available,
      ourSpecial,
      price,
      category,
      dealPrice,
      dealValidFrom,
      dealValidTo
    });
  });
  dispatch(loadItemsAction(sortItemsByCategoryOrder(items, categories)));

  if (subscriptionType === 0) {
    history.push('/menu-list');
  } else if (hasSubscribed && subscriptionEndDate && subscriptionEndDate.toDate() > new Date()) {
    history.push('/orders');
  } else {
    history.push('/store-subscribe');
  }
};

const signIn = (dispatch, history, email, password) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // ...
      console.log('yaay..', user);

      // NOTE: even though onAuthStateChanged() does call postLoginChores, we need to do this here
      // to cover cases when user goes to login page and hit login button (auth state doesn't change in that case)
      postLoginChores(dispatch, history, user);
    })
    .catch((error) => {
      const errorCode = error.code;
      let errorMessage = error.message;
      if (errorCode === 'auth/user-not-found') {
        errorMessage = `The email-id '${email}' is not verified. Please check your email`;
      }
      console.log('errors..', errorCode, errorMessage);
      alert(errorMessage);
    });
};

const resetPassword = (email, setRequestedResetPassword) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      setRequestedResetPassword(true);
    })
    .catch((error) => {
      console.log(error.toString());
      setRequestedResetPassword(false);
    });
};

const updateUserCreds = async (dispatch, oldEmail, newEmail, password) => {
  try {
    const result = await fetch(`${settings.croquetUrl}/matchingstores-genpass`);
    let p = '';
    if (result.status === 200) {
      let data = await result.json();
      p = data.p;
    } else {
      alert('An error occurred');
      return false;
    }

    await reauthenticateWithCredential(auth.currentUser, EmailAuthProvider.credential(oldEmail, p));
    await updateEmail(auth.currentUser, newEmail);
    await updatePassword(auth.currentUser, password);

    dispatch(popupSwitchFromTempUserAction(false));
    return true;
  } catch (error) {
    alert(error.toString());
    return false;
  }
};

export {
  signIn,
  popupSwitchFromTempUserAction,
  resetPassword,
  updateUserCreds,
  postLoginChores,
  setUserAction,
  sortItemsByCategoryOrder,
  loadItemsAction,
  loadCategoriesAction
};
