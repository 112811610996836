import * as ActionTypes from '../../data/state/action-types';

const initMenuData = () => {
  const menuItems = [];
  for (let i = 0; i < 10; i++) {
    menuItems.push({
      id: i,
      include: false,
      itemName: '',
      description: '',
      category: { id: 'main', name: 'Main' },
      price: 0.0
    });
  }
  return menuItems;
};

const initialState = {
  menuItems: initMenuData() /* {id, include, itemName, description, category, price} */
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_MULTI_MENU_ITEMS:
      return {
        ...state,
        menuItems: action.menuItems
      };
    case ActionTypes.INIT_MULTI_MENU_ITEMS:
      return {
        ...state,
        menuItems: initMenuData()
      };
    case ActionTypes.UPDATE_MULTI_MENU_ITEM:
      return {
        ...state,
        menuItems: state.menuItems.map((mi) =>
          mi.id === action.menuItem.id ? action.menuItem : mi
        )
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
