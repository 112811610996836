import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addCategoryDeal, removeCategoryDeal } from '../../Items/actions';
const AddCategoryDeal = ({
  toggleAddNew,
  dispatch,
  placeId,
  categories,
  dealCategories,
  dealItems
}) => {
  const [newItemInfo, setNewItemInfo] = useState({});
  const [discountPercentage, setDiscountPercentage] = useState({
    value: '',
    isValid: 'valid',
    errMsg: ''
  });
  const [validFrom, setValidFrom] = useState(new Date());
  const [validTo, setValidTo] = useState({
    value: new Date(),
    isValid: 'valid',
    errMsg: ''
  });

  const onChangeNewItem = useCallback(
    (name) => {
      // finding by name, TODO: if possible find by itemId
      const _newItemInfo = categories.find((category) => category.name === name);
      const foundItem = dealCategories.find((item) => item.name === name);
      if (_newItemInfo) {
        setNewItemInfo(_newItemInfo);
        if (foundItem) {
          const confirmBox = window.confirm(`do you want to replace existing'${name} deal'?`);
          if (confirmBox === true) {
            removeCategoryDeal(dispatch, foundItem, placeId);
          } else {
            setNewItemInfo({});
            return;
          }
        }
      } else {
        setNewItemInfo({});
      }
    },
    [categories, dealCategories, dispatch, placeId]
  );

  const validateAndSaveData = useCallback(
    async (e) => {
      e.preventDefault();
      const _validTo = { isValid: 'valid', errMsg: '' };
      const _discountPercentage = { isValid: 'valid', errMsg: '' };
      const discountPercentageVal = Number(discountPercentage.value);
      const ExistingCategoryDeal = dealItems.find((item) => item.category === newItemInfo.id);
      if (ExistingCategoryDeal) {
        alert(
          `There is existing deal(s) for Item(s) of this Category. Example "${ExistingCategoryDeal.itemName}". Please note, Item discount will overrite Category discount.`
        );
      }
      // validate discount percentage
      if (!discountPercentage.value) {
        _discountPercentage.isValid = 'invalid';
        _discountPercentage.errMsg = 'Please enter the discount';
      } else {
        if (discountPercentageVal >= 0 && discountPercentageVal <= 100) {
          _discountPercentage.isValid = 'valid';
          _discountPercentage.errMsg = '';
        } else {
          _discountPercentage.isValid = 'invalid';
          _discountPercentage.errMsg = 'discount should be between 0 to 100';
        }
      }
      // validate todate
      const isToDateValid = new Date(validFrom).getTime() < new Date(validTo.value).getTime();
      if (!isToDateValid) {
        _validTo.isValid = 'invalid';
        _validTo.errMsg = 'Valid to <= Valid from';
      }
      setDiscountPercentage({ ...discountPercentage, ..._discountPercentage });
      setValidTo({ ...validTo, ..._validTo });

      if (_validTo.isValid === 'valid' && _discountPercentage.isValid === 'valid') {
        await addCategoryDeal(
          dispatch,
          placeId,
          newItemInfo,
          parseFloat(discountPercentage.value) || 0,
          validFrom,
          validTo.value
        );
        toggleAddNew(false);
      }
    },
    [
      dispatch,
      discountPercentage,
      newItemInfo,
      validFrom,
      validTo,
      toggleAddNew,
      placeId,
      dealItems
    ]
  );

  return (
    <tr>
      <td>
        <input
          className="form-control"
          list="datalistOptions"
          id="addNewItemInput"
          placeholder="Type to search..."
          onChange={(e) => {
            onChangeNewItem(e.target.value);
          }}
        />
        <datalist id="datalistOptions">
          {categories.map((item, idx) => (
            <option key={`data-${idx}`} value={item.name} />
          ))}
        </datalist>
      </td>

      <td>
        <div className="input-group mb-0">
          <input
            type="text"
            className={`form-control ${discountPercentage.isValid === 'invalid' && 'is-invalid'}`}
            id="deal-price"
            autoComplete="new-price"
            required
            onChange={(e) => {
              setDiscountPercentage({
                value: e.target.value,
                isValid: 'valid'
              });
            }}
          />
          <div className="invalid-feedback">{discountPercentage.errMsg}</div>
        </div>
      </td>
      <td>
        <DatePicker selected={validFrom} onChange={setValidFrom} showTimeSelect dateFormat="Pp" />
      </td>
      <td>
        <div
          className={`form-control ${validTo.isValid === 'invalid' && 'is-invalid'} p-0`}
          style={{ height: 'auto' }}>
          <DatePicker
            selected={validTo.value}
            onChange={(date) => {
              setValidTo({ value: date, isValid: 'valid' });
            }}
            showTimeSelect
            dateFormat="Pp"
          />
        </div>
        <div className="invalid-feedback">{validTo.errMsg}</div>
      </td>
      <td>
        <div>
          <button
            style={{
              margin: 1,
              outline: 'none',
              paddingTop: 2,
              padding: 10
            }}
            className="btn-secondary btn-sm"
            onClick={() => toggleAddNew(false)}>
            Cancel
          </button>
          <button
            style={{
              margin: 1,
              outline: 'none',
              paddingTop: 2,
              padding: 10
            }}
            className="btn-primary btn-sm"
            onClick={validateAndSaveData}>
            Save
          </button>
        </div>
      </td>
    </tr>
  );
};

export default AddCategoryDeal;
