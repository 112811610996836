import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import OtpInput from 'react-otp-input';
import { postLoginChores } from '../../Login/actions';
import { StateContext } from '../../../data/state/state-context';
import { getCountryCode } from '../../../utils';
import { auth } from '../../../lib/firebase';

const countryCode = getCountryCode();

const PhoneSection = ({ setOtpSent }) => {
  const phoneInputRef = useRef();
  const [phoneNumber, setPhoneNumber] = useState('');

  const onSignIn = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isPossiblePhoneNumber(phoneNumber)) {
      return;
    }

    const appVerifier = window.recaptchaVerifier;
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            setOtpSent(true);
          })
          .catch((error) => {
            // Error; SMS not sent
            console.log('signInWithPhoneNumber error', error.toString());

            // // recaptcha issue? reset it
            // window.recaptchaVerifier.render().then(function(widgetId) {
            //   grecaptcha.reset(widgetId);
            // });
          });
      })
      .catch((error) => {
        console.log('setPersistence error', error.toString());
      });
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const phone = urlSearchParams.get('p');
    setPhoneNumber(phone);

    if (phoneInputRef.current) {
      phoneInputRef.current.style.border = 0; // this is on the input filed itself
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      'submit-phone',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log('recaptch response', response);
        }
      },
      auth
    );
  }, []);

  return (
    <form className="needs-validation" noValidate>
      <h3>Verify phone number</h3>
      <p>For additional security we will be sending one more verification code</p>
      <div className="mb-3">
        <label htmlFor="login-user-phone">Phone number</label>
        <div className="input-group">
          <PhoneInput
            ref={phoneInputRef}
            style={{
              border: 'solid',
              borderRadius: '4px',
              borderWidth: '1px',
              borderColor: '#d5d3d3',
              height: '2.5em',
              borderStyle: 'solid',
              paddingLeft: '4px'
            }}
            defaultCountry={countryCode}
            international={true}
            placeholder="Phone number"
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
        </div>
      </div>
      <button id="submit-phone" className="btn btn-primary mt-4 d-block w-100" onClick={onSignIn}>
        Send Code
      </button>
    </form>
  );
};

const OtpSection = ({ setOtpSent }) => {
  const [, dispatch] = useContext(StateContext);
  const history = useHistory();
  const [otp, setOtp] = useState('');
  const [otpProblem, setOtpProblem] = useState(false);
  const onBack = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setOtpSent(false);
  };

  const verifyCode = (event) => {
    event.preventDefault();
    event.stopPropagation();

    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        postLoginChores(dispatch, history, user);
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        console.log('confirmationResult error', error.toString());
        setOtpProblem(true);
      });
  };

  return (
    <form className="needs-validation" noValidate>
      <h3>Verification code</h3>
      <p> A verification code has been sent, please enter it</p>
      <div className="mb-3">
        <label htmlFor="login-user-phone">Verification code</label>
        <div className="input-group">
          <OtpInput
            value={otp}
            onChange={(value) => {
              setOtpProblem(false);
              setOtp(value);
            }}
            numInputs={6}
            isInputNum={true}
            shouldAutoFocus={true}
            containerStyle={{ justifyContent: 'space-evenly', marginTop: 10, marginBottom: 20 }}
            inputStyle={{
              marginRight: 10,
              height: 40,
              width: 32,
              border: 'solid',
              borderWidth: 1,
              borderRadius: 8
            }}
          />
          {otpProblem === true && (
            <p className="text-red-500 text-xs italic">There was a problem</p>
          )}
        </div>
      </div>
      <button className="btn btn-secondary mt-4 d-block w-100" onClick={onBack}>
        Back
      </button>
      <button className="btn btn-primary mt-4 d-block w-100" onClick={verifyCode}>
        Submit
      </button>
    </form>
  );
};

const Content = () => {
  const [otpSent, setOtpSent] = useState(false);

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            {otpSent !== true ? (
              <PhoneSection setOtpSent={setOtpSent} />
            ) : (
              <OtpSection setOtpSent={setOtpSent} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
