import React, { useEffect, useContext, useState } from 'react';
import { extractMenuData, extractMenuJson, insertMenuItem, updateMenuItem } from '../actions';
import { StateContext } from '../../../data/state/state-context';
import ProductCategorySelect from '../../AddItem/components/ProductCategorySelect';

const CopyOrPasteButton = ({
  index,
  copyActionIndex,
  setCopyActionIndex,
  hoverActionIndex,
  setHoverActionIndex,
  pasteTillIndex
}) => {
  if (copyActionIndex === -1 || index < copyActionIndex) {
    return (
      <i className="fa fa-copy" title="Copy Category" onClick={() => setCopyActionIndex(index)} />
    );
  }

  if (index === copyActionIndex) {
    return (
      <i
        className="fa fa-copy text-success"
        title="Copied"
        onClick={() => setCopyActionIndex(-1)}
      />
    );
  }

  return (
    <i
      className={`fa fa-paste ${index <= hoverActionIndex ? 'text-success' : ''}`}
      title="Paste Category"
      onMouseEnter={() => setHoverActionIndex(index)}
      onMouseLeave={() => setHoverActionIndex(-1)}
      onClick={() => pasteTillIndex(index)}
    />
  );
};

const StepTwo = ({ src, json }) => {
  const [copyActionIndex, setCopyActionIndex] = useState(-1);
  const [hoverActionIndex, setHoverActionIndex] = useState(-1);
  const [rootState, dispatch] = useContext(StateContext);
  const { categories } = rootState.items;

  const pasteTillIndex = async (index) => {
    if (index <= copyActionIndex || copyActionIndex < 0) {
      return;
    }

    const prevCateg = rootState.importMenu.menuItems[copyActionIndex].category;
    for (let l = copyActionIndex + 1; l <= index; l++) {
      const mi = rootState.importMenu.menuItems[l];
      updateMenuItem(dispatch, { ...mi, category: prevCateg });
    }
    setCopyActionIndex(-1);
    setHoverActionIndex(-1);
  };

  useEffect(() => {
    if (json) {
      extractMenuJson(dispatch, json);
    } else {
      extractMenuData(dispatch, src);
    }
  }, [src, json]);

  return (
    <div className="ms-wizard-step">
      {!src && !json && (
        <div className="alert alert-danger" role="alert">
          Please go back and select a valid file to import
        </div>
      )}
      <div className="form-row">
        <div className="col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Items List</h6>
            </div>
            <div className="ms-panel-body px-1 py-1">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Import</th>
                      <th scope="col">Item Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Price</th>
                      <th scope="col" colSpan={2}>
                        Category
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rootState.importMenu.menuItems.length === 0 ? (
                      <tr>
                        <td> Please wait.. </td>
                      </tr>
                    ) : (
                      rootState.importMenu.menuItems.map((mi, index) => (
                        <tr key={mi.id}>
                          <td>
                            <label className="ms-switch">
                              <input
                                type="checkbox"
                                defaultChecked={mi.include}
                                onClick={() => {
                                  updateMenuItem(dispatch, { ...mi, include: !mi.include });
                                }}
                              />
                              <span className="ms-switch-slider ms-switch-success round" />
                            </label>
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={mi.itemName}
                              onChange={(e) => {
                                updateMenuItem(dispatch, { ...mi, itemName: e.target.value });
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={mi.description}
                              onChange={(e) => {
                                updateMenuItem(dispatch, { ...mi, description: e.target.value });
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="number"
                              value={mi.price}
                              onChange={(e) => {
                                updateMenuItem(dispatch, { ...mi, price: e.target.value });
                              }}
                            />
                          </td>
                          <td colSpan={2}>
                            <div className="row">
                              <div className="col-6">
                                <ProductCategorySelect
                                  className="form-control"
                                  categories={categories}
                                  selected={mi.category}
                                  onSelect={(category) => {
                                    updateMenuItem(dispatch, { ...mi, category });
                                  }}
                                />
                              </div>
                              <div className="col">
                                <CopyOrPasteButton
                                  index={index}
                                  copyActionIndex={copyActionIndex}
                                  setCopyActionIndex={setCopyActionIndex}
                                  hoverActionIndex={hoverActionIndex}
                                  setHoverActionIndex={setHoverActionIndex}
                                  pasteTillIndex={pasteTillIndex}
                                />
                              </div>
                              <div className="col">
                                <i
                                  className="fa fa-clone"
                                  title="Duplicate"
                                  onClick={() =>
                                    insertMenuItem(
                                      dispatch,
                                      rootState.importMenu.menuItems,
                                      mi,
                                      index + 1
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
