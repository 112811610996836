import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Preloader from '../../../components/layouts/Preloader';
import Dashboard from '../../Dashboard/components/Dashboard';
import Accordions from '../../../components/pages/Accordions';
import AddItem from '../../AddItem';
import AddMultipleItems from '../../AddMultipleItems';
import ImportMenu from '../../ImportMenu';
import Alerts from '../../../components/pages/Alerts';
import Animations from '../../../components/pages/Animations';
import Badges from '../../../components/pages/Badges';
import Basictables from '../../../components/pages/Basictables';
import Breadcrumbs from '../../../components/pages/Breadcrumbs';
import Buttons from '../../../components/pages/Buttons';
import Cards from '../../../components/pages/Cards';
import Chartjs from '../../../components/pages/Chartjs';
import Chat from '../../../components/pages/Chat';
import Cropper from '../../../components/pages/Cropper';
import Customerlist from '../../../components/pages/Customerlist';
import Customerreview from '../../../components/pages/Customerreview';
import Datatables from '../../../components/pages/Datatables';
import Draggables from '../../../components/pages/Draggables';
import Email from '../../../components/pages/Email';
import Flaticons from '../../../components/pages/Flaticons';
import Fontawesome from '../../../components/pages/Fontawesome';
import Formelements from '../../../components/pages/Formelements';
import Formlayouts from '../../../components/pages/Formlayouts';
import Formvalidation from '../../../components/pages/Formvalidation';
import Formwizard from '../../../components/pages/Formwizard';
import Googlemaps from '../../../components/pages/Googlemaps';
import Invoicedetail from '../../../components/pages/Invoicedetail';
import Invoicelist from '../../../components/pages/Invoicelist';
import Materialize from '../../../components/pages/Materialize';
import Menucatalogue from '../../../components/pages/Menucatalogue';
import Menugrid from '../../../components/pages/Menugrid';
import Items from '../../Items';
import Modals from '../../../components/pages/Modals';
import Googlechart from '../../../components/pages/Googlechart';
import Orders from '../../Orders';
import Customer from '../../Customer';
import Pagination from '../../../components/pages/Pagination';
import Preloaders from '../../../components/pages/Preloaders';
import Productdetail from '../../Productdetail';
import Progressbars from '../../../components/pages/Progressbars';
import Rangeslider from '../../../components/pages/Rangeslider';
import Rating from '../../../components/pages/Rating';
import Restaurantlist from '../../../components/pages/Restaurantlist';
import Sales from '../../../components/pages/Sales';
import Sliders from '../../../components/pages/Sliders';
import Socialactivity from '../../../components/pages/Socialactivity';
import Sweetalerts from '../../../components/pages/Sweetalerts';
import Tabs from '../../../components/pages/Tabs';
import Toast from '../../../components/pages/Toast';
import Todolist from '../../../components/pages/Todolist';
import Tour from '../../../components/pages/Tour';
import Typography from '../../../components/pages/Typography';
import Vectormaps from '../../../components/pages/Vectormaps';
import Widgets from '../../../components/pages/Widgets';
import Clientmanagement from '../../../components/pages/Clientmanagement';
import Comingsoon from '../../../components/pages/Comingsoon';
import Login from '../../Login';
import CreateAccount from '../../CreateAccount';
import CreateStore from '../../CreateStore';
import CreateStoreEx from '../../CreateStoreEx';
import CreateStoreDm from '../../CreateStoreDm';
import ConfigureWebsite from '../../ConfigureWebsite';
import ImportOnlineStore from '../../ImportOnlineStore';
import Error from '../../../components/pages/Error';
import Faq from '../../../components/pages/Faq';
import Invoice from '../../../components/pages/Invoice';
import Lockscreen from '../../../components/pages/Lockscreen';
import Modallogin from '../../../components/pages/Modallogin';
import Modalregister from '../../../components/pages/Modalregister';
import Portfolio from '../../../components/pages/Portfolio';
import Stockmanagement from '../../../components/pages/Stockmanagement';
import Userprofile from '../../../components/pages/Userprofile';
import Webanalytics from '../../../components/pages/Webanalytics';
import Loading from '../../Loading';
import Editproduct from '../../EditItem';
import EditStore from '../../EditStore';
import StoreSubscription from '../../StoreSubscription';
import Deals from '../../Deals';
import GreetingCard from '../../GreetingCard';
import PrintableMenu from '../../PrintableMenu';
import SmartTableCard from '../../SmartTableCard';
import SmartOrderCard from '../../SmartOrderCard';
import PrintableMenu6 from '../../PrintableMenu/components/PrintableMenu6';
import PrintableMenuEx from '../../PrintableMenuEx';
import SmartOrderCardEx from '../../SmartOrderCardEx';
import SmartTableCardEx from '../../SmartTableCardEx';
import HomeEx from '../../HomeEx';
import Ratings from '../../Ratings';
import PhoneLogin from '../../PhoneLogin';

function App() {
  return (
    <Router basename={'/'}>
      <Preloader />
      <Switch>
        <Route exact path="/" component={Loading} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/accordions" component={Accordions} />
        <Route path="/add-product" component={AddItem} />
        <Route path="/add-multiple-product" component={AddMultipleItems} />
        <Route path="/import-menu" component={ImportMenu} />
        <Route path="/edit-product" component={Editproduct} />
        <Route path="/alerts" component={Alerts} />
        <Route path="/animations" component={Animations} />
        <Route path="/badges" component={Badges} />
        <Route path="/basic-tables" component={Basictables} />
        <Route path="/breadcrumbs" component={Breadcrumbs} />
        <Route path="/buttons" component={Buttons} />
        <Route path="/cards" component={Cards} />
        <Route path="/chartjs" component={Chartjs} />
        <Route path="/chat" component={Chat} />
        <Route path="/cropper" component={Cropper} />
        <Route path="/customer-list" component={Customerlist} />
        <Route path="/customer-review" component={Customerreview} />
        <Route path="/data-tables" component={Datatables} />
        <Route path="/draggables" component={Draggables} />
        <Route path="/email" component={Email} />
        <Route path="/flaticons" component={Flaticons} />
        <Route path="/fontawesome" component={Fontawesome} />
        <Route path="/form-elements" component={Formelements} />
        <Route path="/form-layouts" component={Formlayouts} />
        <Route path="/form-validation" component={Formvalidation} />
        <Route path="/form-wizard" component={Formwizard} />
        <Route path="/google-maps" component={Googlemaps} />
        <Route path="/invoice-detail" component={Invoicedetail} />
        <Route path="/invoice-list" component={Invoicelist} />
        <Route path="/materialize" component={Materialize} />
        <Route path="/menu-catalogue" component={Menucatalogue} />
        <Route path="/menu-grid" component={Menugrid} />
        <Route path="/menu-list" component={Items} />
        <Route path="/modals" component={Modals} />
        <Route path="/google-chart" component={Googlechart} />
        <Route path="/orders" component={Orders} />
        <Route path="/customers" component={Customer} />
        <Route path="/pagination" component={Pagination} />
        <Route path="/preloaders" component={Preloaders} />
        <Route path="/product-detail" component={Productdetail} />
        <Route path="/progress-bars" component={Progressbars} />
        <Route path="/range-slider" component={Rangeslider} />
        <Route path="/rating" component={Rating} />
        <Route path="/restaurant-list" component={Restaurantlist} />
        <Route path="/sales" component={Sales} />
        <Route path="/sliders" component={Sliders} />
        <Route path="/social-activity" component={Socialactivity} />
        <Route path="/sweet-alerts" component={Sweetalerts} />
        <Route path="/tabs" component={Tabs} />
        <Route path="/toast" component={Toast} />
        <Route path="/todo-list" component={Todolist} />
        <Route path="/tour" component={Tour} />
        <Route path="/typography" component={Typography} />
        <Route path="/vector-maps" component={Vectormaps} />
        <Route path="/widgets" component={Widgets} />
        <Route path="/client-management" component={Clientmanagement} />
        <Route path="/coming-soon" component={Comingsoon} />
        <Route path="/default-login" component={Login} />
        <Route path="/phone-login" component={PhoneLogin} />
        <Route path="/default-register" component={CreateAccount} />
        <Route path="/create-store" component={CreateStore} />
        <Route path="/edit-store" component={EditStore} />
        <Route path="/configure-website" component={ConfigureWebsite} />
        <Route path="/import-online-store" component={ImportOnlineStore} />
        <Route path="/store-subscribe" component={StoreSubscription} />
        <Route path="/error" component={Error} />
        <Route path="/faq" component={Faq} />
        <Route path="/invoice" component={Invoice} />
        <Route path="/lock-screen" component={Lockscreen} />
        <Route path="/modal-login" component={Modallogin} />
        <Route path="/modal-register" component={Modalregister} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/stock-management" component={Stockmanagement} />
        <Route path="/user-profile" component={Userprofile} />
        <Route path="/web-analytics" component={Webanalytics} />
        <Route path="/deal-list" component={Deals} />
        <Route path="/greeting-card" component={GreetingCard} />
        <Route path="/printable-menu" component={PrintableMenu} />
        <Route path="/smart-table-card" component={SmartTableCard} />
        <Route path="/smart-order-card" component={SmartOrderCard} />
        <Route path="/printable-menu-6" component={PrintableMenu6} />
        <Route path="/u/home" component={HomeEx} />
        <Route path="/m/:slug" component={PrintableMenuEx} />
        <Route path="/so/:slug" component={SmartOrderCardEx} />
        <Route path="/st/:slug" component={SmartTableCardEx} />
        <Route path="/s/create" component={CreateStoreEx} />
        <Route path="/create-a-free-menu" component={CreateStoreEx} />
        <Route path="/create-digital-menu" component={CreateStoreDm} />
        <Route path="/rating-list" component={Ratings} />
      </Switch>
    </Router>
  );
}

export default App;
