import React from "react";
import { Modal, Button } from "react-bootstrap";
import TocContent from "./TocContent";

const TermsAndConditons = ({ isVisible, onClose }) => {
  return (
    <Modal scrollable show={isVisible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TocContent />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsAndConditons;
