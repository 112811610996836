import React, { useState, useMemo, useCallback, useEffect } from 'react';
import StoreCard from './StoreCard';
import { collection, query, getDocs, startAt, endAt, orderBy } from 'firebase/firestore';
import Topnavigation from './Topnavigation';
import { db } from '../../../lib/firebase';
import { debounce } from 'lodash';

const HomeEx = () => {
  const [storeName, setStoreName] = useState('');
  const [stores, setStores] = useState([]);
  const [userLat, setUserLat] = useState(null);
  const [userLng, setUserLng] = useState(null);

  function getDistance(lat1, lng1, lat2, lng2) {
    const R = 6371; // Earth's radius in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLng = ((lng2 - lng1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }

  const getStores = useCallback(
    async (place) => {
      if (!place || place.length < 3) {
        return;
      }

      const sortByDistance = userLat && userLng;
      let resList = [];
      let lowerCasePlace = place.toLowerCase();
      let upperCasePlace = place.toUpperCase();
      const q = query(
        collection(db, 'places'),
        orderBy('placeName'),
        startAt(upperCasePlace),
        endAt(lowerCasePlace + '\uf8ff')
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const { address, imageSrc, locationInfo, placeName, urlHost } = doc.data();

        if (placeName.toLowerCase().includes(lowerCasePlace)) {
          const distance = sortByDistance
            ? getDistance(locationInfo.lat, locationInfo.lng, userLat, userLng)
            : 0;
          resList.push({
            address: address,
            imageSrc: imageSrc,
            placeName: placeName,
            urlHost: urlHost,
            distance: distance
          });
        }
      });
      if (sortByDistance) {
        const sortedStores = resList.sort((a, b) => a.distance - b.distance);
        setStores(sortedStores);
      } else {
        setStores(resList);
      }
    },
    [userLat, userLng]
  );

  const getStoresDebounce = useMemo(() => debounce(getStores, 1000), [getStores]);

  const handleStore = (e) => {
    if (e?.target?.value) {
      setStoreName(e.target.value);
      getStoresDebounce(e.target.value);
    } else {
      setStoreName('');
      setStores([]);
    }
  };

  const handleSubmit = () => {
    if (stores.length === 0 && storeName) {
      getStoresDebounce(storeName);
    }
  };

  const onSuccess = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setUserLat(latitude);
    setUserLng(longitude);
  };

  const onError = (error) => {
    console.error('Error: ', error);
    // Fallback to third-party API on error - TODO
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    } else {
      // Geolocation API not supported, use third-party API - TODO
      console.log('Sorry Not available!');
    }
  }, []);

  return (
    <div>
      <div className="ms-body ms-primary-theme">
        <main className="body-content">
          <Topnavigation />

          <div style={{ marginTop: '20px' }}>
            <input
              type="text"
              id="inputName"
              style={{ width: '360px', fontSize: '20px' }}
              className="form-control mt-3 mx-auto"
              value={storeName}
              onChange={handleStore}
              placeholder="Enter Storename"
            />
            <br />
            <input
              type="button"
              className="form-control mt-3 mx-auto  bg-primary text-light "
              style={{ width: '150px', marginLeft: '100px' }}
              onClick={handleSubmit}
              value="Search"
            />
            <div className="container">
              <div className="row mt-3">
                {stores.length === 0 || storeName === ''
                  ? storeName && (
                      <div className="col">
                        <h6 className="text-center">No results found</h6>
                      </div>
                    )
                  : stores.map((item, index) => {
                      return <StoreCard item={item} key={index} />;
                    })}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default HomeEx;
