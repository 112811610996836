import React, { useState, useContext } from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Content from './Content';
import SmartQrImage from './SmartQrImage';
import { StateContext } from '../../../data/state/state-context';

const card1 = encodeURI('https://m.qrmeu.com/assets/Card Marketing/Frame 3.png');
const card2 = encodeURI('https://m.qrmeu.com/assets/Card Marketing/Frame 1.png');
const card3 = encodeURI('https://m.qrmeu.com/assets/Card Marketing/Frame 5.png');
const card4 = encodeURI('https://m.qrmeu.com/assets/Card Marketing/Frame 7.png');
const card5 = encodeURI('https://m.qrmeu.com/assets/Card Marketing/Frame 9.png');
const cardQr = encodeURI('https://m.qrmeu.com/assets/Card Marketing/bg.png');
const demoCard1 = encodeURI('https://m.qrmeu.com/assets/posts/Instagram post - 12.png');
const demoCard2 = encodeURI('https://m.qrmeu.com/assets/posts/Instagram post - 13.png');
const demoCard3 = encodeURI('https://m.qrmeu.com/assets/posts/Instagram post - 14.png');
const demoCard4 = encodeURI('https://m.qrmeu.com/assets/posts/Instagram post - 15.png');
const demoCard5 = encodeURI('https://m.qrmeu.com/assets/posts/Instagram post - 16.png');

const SmartOrder = () => {
  const [rootState] = useContext(StateContext);
  const { urlHost } = rootState.main;
  const [qrCard, setQrCard] = useState(null);
  return (
    <>
      <div className="ms-body ms-aside-left-open ms-primary-theme">
        <Sidenavigation />
        <main className="body-content">
          <Topnavigation />
          <Content
            cardImages={[
              {
                data: card1,
                orderQR: qrCard,
                demo: demoCard1
              },
              {
                data: card2,
                orderQR: qrCard,
                demo: demoCard2
              },
              {
                data: card3,
                orderQR: qrCard,
                demo: demoCard3
              },

              { data: card4, orderQR: qrCard, demo: demoCard4 },
              { data: card5, orderQR: qrCard, demo: demoCard5 }
            ]}
          />
        </main>
      </div>
      <div style={{ opacity: 0 }}>
        <SmartQrImage setQrCard={setQrCard} CardQr={cardQr} urlHost={urlHost} />
      </div>
    </>
  );
};

export default SmartOrder;
