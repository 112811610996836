import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import { StateContext } from '../../../data/state/state-context';
import { toggleIsLoading } from '../../Loading/actions';
import { updateUserCreds } from '../actions';

const NewUserChangePassword = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { user } = rootState.main;
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [cpwd, setCpwd] = useState('');

  const submit = async () => {
    if (!email) {
      alert('Please enter email');
      return;
    }
    if (!pwd || pwd.length < 6) {
      alert('Please enter password with at least 6 characters');
      return;
    }
    if (pwd !== cpwd) {
      alert('Password and Confirm Password do not match');
      return;
    }

    toggleIsLoading(dispatch, true); // start spinning
    const success = await updateUserCreds(dispatch, user.tempUserEmail, email, pwd);
    if (success === true) {
      setShowModal(false);
    }
    toggleIsLoading(dispatch, false); // stop spinning
  };

  useEffect(() => {
    setShowModal(user.popupSwitchFromTempUser === true);
  }, [user]);

  return (
    <Modal
      className="modal-min"
      show={showModal}
      onHide={() => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body className="text-center">
        <button type="button" className="close" onClick={() => setShowModal(false)}>
          <span aria-hidden="true">×</span>
        </button>
        <i className="flaticon-user d-block" />
        <h1>Your online store is now ready</h1>
        <p>Let's create an account so that you can login and manage the store</p>
        <p>
          <div class="form-group">
            <div>
              <input
                type="email"
                placeholder="Email"
                class="form-control input-lg"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div class="form-group">
            <div>
              <input
                type="password"
                placeholder="Password"
                class="form-control input-lg"
                name="password"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
              />
            </div>
          </div>
          <div class="form-group">
            <div>
              <input
                type="password"
                placeholder="Confirm Password"
                class="form-control input-lg"
                name="password"
                value={cpwd}
                onChange={(e) => setCpwd(e.target.value)}
              />
            </div>
          </div>
          <div class="form-group">
            <div>
              <button type="button" onClick={submit} class="btn btn-success">
                Submit
              </button>
            </div>
          </div>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default NewUserChangePassword;
