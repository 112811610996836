import React, { useEffect, useRef } from 'react';
import { toPng } from 'html-to-image';
import QRCode from 'qrcode.react';
import { waMeLink } from '../../../utils/wame';

// Create Document Component
const SmartQrImage = ({ setQrCard, CardQr, urlHost }) => {
  const domEl = useRef(null);
  const waLink = waMeLink({
    urlHost /*, scard: 1 - commented coz thru wame link we get user ph, no need to force login */
  });

  useEffect(() => {
    const toImage = async () => {
      // From https://www.npmjs.com/package/html-to-image
      // Safari is not supported, as it uses a stricter security model on <foreignObject> tag.
      // Suggested workaround is to use toSvg and render on the server.
      const dataUrl = await toPng(domEl.current);
      setQrCard(dataUrl);
    };
    if (domEl.current) {
      toImage();
    }
  }, [domEl]);

  return (
    <div
      id="domEl"
      ref={domEl}
      style={{
        width: 193 * 4,
        height: 113 * 4,
        backgroundImage: `url(${CardQr})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }}>
      <div class="container-fluid  ">
        <div class="row  ">
          <QRCode
            id="store-website-url"
            value={waLink}
            size={76 * 4}
            level="H"
            includeMargin={true}
            style={{
              display: 'inherit',
              marginTop: '80px',
              marginLeft: '40px',

              borderRadius: 10
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SmartQrImage;
