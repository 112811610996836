import React from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Detailcontent from './Detailcontent';
import Quickbar from '../../../components/layouts/Quickbar';

const Productdetail = () => {
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <Detailcontent />
      </main>
      <Quickbar />
    </div>
  );
};

export default Productdetail;
