import React, { useState, useContext } from 'react';

import ContentEx from './ContentEx';
import { StateContext } from '../../../data/state/state-context';
import MergeStickerImageEx from './MergeStickerImage';
import TopNavigationEx from '../../Root/components/TopNavigationEx';
import { useParams } from 'react-router-dom';

const tableQr = encodeURI('https://m.qrmeu.com/assets/QR-Poster.png');
const demoTableSticker = encodeURI('https://m.qrmeu.com/assets/demo-sticker.png');
const preview = encodeURI('https://m.qrmeu.com/assets/Vector-white.png');

const SmartTableEx = () => {
  const { slug } = useParams();
  const [rootState] = useContext(StateContext);
  const { urlHost } = rootState.main;
  const [numOfTable, setNumOFTable] = useState(1);
  const [arrayOfMergedImages, setArrayOfMergedImages] = useState([]);
  const [sortedImages, setSortedImages] = useState([]);
  const [stickerGroups, setStickerGroups] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  let additems = [];

  const handleNumOfTable = (e) => {
    setNumOFTable(e.target.value);
    setStickerGroups([]);
    additems = [];
    setArrayOfMergedImages([]);
  };

  const buildStickerGroups = () => {
    const groupsOf4 = [];
    for (let i = 1; i <= numOfTable; i += 4) {
      const subGroup = [];
      for (let j = i; j < i + 4 && j <= numOfTable; j++) {
        subGroup.push(j);
      }
      groupsOf4.push(subGroup);
    }
    setStickerGroups(groupsOf4);
    setShowOverlay(!showOverlay);
  };

  const handleStickers = () => {
    buildStickerGroups();
  };

  const addMergeImages = (img, index) => {
    additems.push({ data: img, index: index });
    if (additems.length === stickerGroups.length) {
      setArrayOfMergedImages(additems);
    }
  };

  return (
    <>
      <div className="ms-body ms-primary-theme">
        <main className="body-content">
          <TopNavigationEx slug={slug} />
          <div className="ms-panel text-center p-4 " style={{ position: 'relative' }}>
            <div
              class="card m-3"
              style={{
                width: '33rem',
                Height: '546px',
                borderRadius: '20px'
              }}>
              <img
                class="card-img-top  "
                style={{ borderRadius: '20px 20px 0 0', width: '100%', objectFit: 'contain' }}
                src={demoTableSticker}
                alt="demo-table-card"
              />
              <div
                className=" d-flex justify-content-center "
                style={{ backgroundColor: '#ff8306' }}>
                <h4 style={{ color: '#fff' }} className="mt-3">
                  No of Cards to Print:
                </h4>
                <input
                  type="Number"
                  className="bg-dark ml-3 mt-2 mb-3 text-light text-center rounded"
                  id="from"
                  min="1"
                  max="25"
                  step="1"
                  style={{ height: '2.1rem', width: '2.2rem', fontSize: '20px' }}
                  value={numOfTable}
                  onChange={handleNumOfTable}
                />
              </div>
              <div
                class=" d-flex align-items-center"
                style={{
                  borderRadius: '0 0 20px 20px',
                  background: 'linear-gradient(180deg, #242934 0%, #141414 100%)'
                }}>
                <div className="m-3" style={{ textAlign: 'justify' }}>
                  <h4 class=" card-title text-light bold">Smart Table Card</h4>
                  <p class="text-light">
                    First Enter the number of Cards you want then you prview by clicking on preview
                    print and then finally click on Download Cards button
                  </p>
                </div>
                <div className="ml-2 mb-3 mt-3 mr-2">
                  <button
                    class="btn text-light border-light rounded m-0"
                    onClick={handleStickers}
                    style={{
                      textAlign: 'center',
                      width: '230px',
                      background: 'linear-gradient(180deg, #242934 0%, #141414 100%)'
                    }}>
                    Preview Print
                    <img src={preview} alt="prview" className="ml-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showOverlay && MergeStickerImageEx && (
            <div
              className="overlay"
              style={{
                position: 'absolute',
                top: '0',
                margin: '100px 150px 0 30px',
                width: '800px',
                height: '800px',
                zIndex: '1',
                overflowY: 'scroll'
              }}>
              <ContentEx
                arrayOfMergedImages={arrayOfMergedImages}
                sortedImages={sortedImages}
                setSortedImages={setSortedImages}
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
              />
            </div>
          )}
        </main>
      </div>

      {/* placing the section below outside of ms-body panel to generate image with a size that that fits well on A4 page */}
      <div className="m-3 container" style={{ opacity: 0 }}>
        {stickerGroups.map((image, index) => (
          <MergeStickerImageEx
            image={image}
            backgroundImage={tableQr}
            urlHost={urlHost}
            addMergeImages={addMergeImages}
            key={index}
            index={index}
          />
        ))}
      </div>
    </>
  );
};

export default SmartTableEx;
