import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import logo from '../../assets/img/foodtech/ferrypal-logo-216x62.png';
import { StateContext } from '../../data/state/state-context';
import i18n from '../../i18n';
import { onChangeLanguage } from '../../screens/EditStore/actions';
import { languages } from '../../data/constants';

const LanguageOptions = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { localLanguages = [], lng } = rootState.main;

  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      onChange={(e, _lng) => {
        e.preventDefault();
        i18n.changeLanguage(_lng).then(() => {
          onChangeLanguage(dispatch, _lng);
        });
      }}
      value={lng}
      name="radio-buttons-group">
      <FormControlLabel
        value="en"
        control={<Radio />}
        label={<Typography style={{ fontSize: 12 }}>English</Typography>}
      />
      {localLanguages.map((l) => (
        <FormControlLabel
          value={l}
          control={<Radio />}
          label={
            <Typography style={{ fontSize: 12 }}>
              {languages.find((_l) => _l.lng === l)?.name}
            </Typography>
          }
        />
      ))}
    </RadioGroup>
  );
};

const Sidenavigation = () => {
  const [rootState] = useContext(StateContext);
  const { trips } = rootState.orders;
  const isBasicPlan = rootState.main.subscriptionType === 0;
  const [newOrdersCount, setNewOrdersCount] = useState(0);
  useEffect(() => {
    const count = trips.filter((trip) => !trip.acceptedByPlaceAt);
    setNewOrdersCount(count.length);
  }, [trips]);
  const removeoverlay = () => {
    $('.ms-body').toggleClass('ms-aside-left-open');
    $('#ms-side-nav').toggleClass('ms-aside-open');
    $('.ms-aside-overlay.ms-overlay-left').toggleClass('d-block');
  };

  useEffect(() => {
    function setActiveMenuItem() {
      $('.ms-main-aside .menu-item>a').on('click', function () {
        $(this).removeAttr('href');
        var element = $(this).parent('li');
        if (element.hasClass('active')) {
          element.removeClass('active');
          element.find('li').removeClass('active');
          element.find('.collapse').slideUp();
        } else {
          element.addClass('active');
          element.children('.collapse').slideDown();
          element.siblings('li').children('.collapse').slideUp();
          element.siblings('li').removeClass('active');
          element.siblings('li').find('li').removeClass('active');
          element.siblings('li').find('.collapse').slideUp();
        }
      });
    }
    setActiveMenuItem();
  }, []);

  return (
    <div>
      <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={removeoverlay}></div>
      <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
      <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left">
        {/* Logo */}
        <div className="logo-sn ms-d-block-lg">
          <Link className="pl-0 ml-0 text-center" to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        {/* Navigation */}
        <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
          {!isBasicPlan && (
            <li className="menu-item">
              <Link to="/orders">
                <span>
                  <i className="fas fa-clipboard-list fs-16" />
                  Orders
                  <span className="badge badge-primary text-white rounded-circle ml-2">
                    {newOrdersCount}
                  </span>
                </span>
              </Link>
            </li>
          )}
          {/* customers */}
          <li className="menu-item">
            <Link to="/customers">
              {' '}
              <span>
                <i className="fas fa-users fs-16"></i>
                Customers
              </span>
            </Link>
          </li>
          {/* customers end */}
          {/*Greeting Card */}
          <li className="menu-item">
            <Link to="/greeting-card">
              {' '}
              <span>
                <i className="fas  fa-handshake fs-16"></i>
                Greeting Cards
              </span>
            </Link>
          </li>
          {/*Greeting Card */}
          {/* product */}
          <li className="menu-item">
            <Link to="#" className="has-chevron">
              {' '}
              <span>
                <i className="fa fa-archive fs-16" />
                Menus{' '}
              </span>
            </Link>
            <ul
              id="product"
              className="collapse "
              aria-labelledby="product"
              data-parent="#side-nav-accordion">
              <li>
                <Link to="/menu-list">Menu List</Link>
              </li>
              <li>
                <Link to="/add-product">Add Single Item</Link>
              </li>
              <li>
                <Link to="/add-multiple-product">Add Multipe Items</Link>
              </li>
              <li>
                <Link to="/import-menu">Import Menu</Link>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <Link to="#" className="has-chevron">
              {' '}
              <span>
                <i className="fa fa-print fs-16" />
                Printables{' '}
              </span>
            </Link>
            <ul
              id="product"
              className="collapse "
              aria-labelledby="product"
              data-parent="#side-nav-accordion">
              <li>
                <Link to="/printable-menu">Printable Menu</Link>
              </li>
              <li>
                <Link to="/smart-table-card">Smart Table Card</Link>
              </li>
              <li>
                <Link to="/smart-order-card">Smart Order Card</Link>
              </li>
            </ul>
          </li>
          {/* product end */}
          {/* deals */}
          <li className="menu-item">
            <Link to="/deal-list">
              {' '}
              <span>
                <i className="fa fa-tag fs-16" />
                Deals
              </span>
            </Link>
          </li>
          {/* deals end */}
          {/* ratings */}
          <li className="menu-item">
            <Link to="/rating-list">
              {' '}
              <span>
                <i className="fa fa-star fs-16" />
                Ratings
              </span>
            </Link>
          </li>
          {/* ratings end */}
          <li className="menu-item">
            <Link to="#" className="has-chevron">
              {' '}
              <span>
                <i className="fa fa-language fs-16" />
                Language{' '}
              </span>
            </Link>
            <ul
              id="language"
              className="collapse "
              aria-labelledby="language"
              data-parent="#side-nav-accordion">
              <li>
                <LanguageOptions />
              </li>
            </ul>
          </li>
          {/* settings */}
          <li className="menu-item">
            <Link to="/edit-store">
              {' '}
              <span>
                <i className="flaticon-gear" />
                Settings
              </span>
            </Link>
          </li>
          {/* settings end */}
        </ul>
      </Scrollbar>
    </div>
  );
};

export default Sidenavigation;
