import { doc, updateDoc } from "firebase/firestore";

import { db } from "../../lib/firebase";
import * as ActionTypes from "../../data/state/action-types";

const setUpdateThemeAction = (
  theme,
  primaryColor,
  secondaryColor
) => ({
  type: ActionTypes.UPDATE_THEME,
  theme,
  primaryColor,
  secondaryColor,
});

const updateTheme = async (
  dispatch,
  placeId,
  theme,
  primaryColor,
  secondaryColor
) => {
  dispatch(setUpdateThemeAction(
    theme,
    primaryColor,
    secondaryColor
  ));

  const placeRef = doc(db, "places", placeId);
  await updateDoc(placeRef, {
    theme,
    primaryColor,
    secondaryColor
  });
};

export { updateTheme };
