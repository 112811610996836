import React, { useEffect, useState } from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import ItemDeals from './ItemDeals';
import Quickbar from '../../../components/layouts/Quickbar';
import CategoryDeals from './CategoryDeals';
const Deals = () => {
  const [activeButton, setActiveButton] = useState('items');
  const [dealCategories, setDealCategories] = useState([]);
  const [dealItems, setDealItems] = useState([]);
  const handleItemClick = () => {
    setActiveButton('items');
  };

  const handleCategoryClick = () => {
    setActiveButton('categories');
  };
useEffect(()=>{
  
})
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <div className="d-flex justify-content-center">
          <button
            className={`btn ${activeButton === 'items' ? 'btn-primary' : 'btn-light'} mr-2`}
            onClick={handleItemClick}>
            Items
          </button>
          <button
            className={`btn ${activeButton === 'categories' ? 'btn-primary' : 'btn-light'} ml-2`}
            onClick={handleCategoryClick}>
            Categories
          </button>
        </div>
        {activeButton === 'items' && (
          <ItemDeals
            dealCategories={dealCategories}
            dealItems={dealItems}
            setDealItems={setDealItems}
          />
        )}
        {activeButton === 'categories' && (
          <CategoryDeals
            dealCategories={dealCategories}
            setDealCategories={setDealCategories}
            dealItems={dealItems}
          />
        )}
      </main>
      <Quickbar />
    </div>
  );
};

export default Deals;
