import { TextractClient, DetectDocumentTextCommand } from '@aws-sdk/client-textract';
import { Buffer } from 'buffer';
import stringSimilarity from 'string-similarity';
import countryCurrency from 'iso-country-currency';

import * as ActionTypes from '../../data/state/action-types';
import { getConfigValue } from '../../../config/configurations';
import { idfy, isNumber } from '../../utils';
import { setProgressModal, toggleIsLoading } from '../Loading/actions';
import { addItem } from '../AddItem/actions.js';
import foodImages from '../../data/food-images.json';
import settings from '../../../config/settings';
import { popupSwitchFromTempUserAction } from '../Login/actions';

const loadMenuItemsAction = (menuItems) => ({
  type: ActionTypes.LOAD_IMPORTED_MENU_ITEMS,
  menuItems
});

const updateMenuItemAction = (menuItem) => ({
  type: ActionTypes.UPDATE_IMPORTED_MENU_ITEM,
  menuItem
});

const getPotentialPriceValue = (text) => {
  if (!text) {
    return '';
  }

  //1. do we have a plain and simple number?
  if (isNumber(text) === true) {
    return text;
  }

  //2. got a number with currency code/symbol before the number or after the number
  const match = text.match(/(\D*)(\d+\.?\d*)(\D*)/);
  if (match && match.length === 4) {
    // we have 3 capturing groups - in the result array, they should be index 1-3
    const potentialCurrencyOrSymbol1 = match[1].trim();
    const potentialPrice = match[2]; // this is matched as a number
    const potentialCurrencyOrSymbol2 = match[3].trim();

    // verify potentialCurrencyOrSymbol1 or potentialCurrencyOrSymbol2 is a currency or a symbol
    // In order to match "Rs."" with "Rs" do compare with no special chars as well
    const isCurrencyOrSymbol =
      potentialCurrencyOrSymbol2 === '/-' /* "75/-" format pricing */ ||
      countryCurrency.getAllISOCodes().some(({ currency, symbol }) => {
        console.log(currency.toLowerCase());
        console.log(potentialCurrencyOrSymbol1.replace(/[^a-zA-Z]/g, '').toLowerCase());

        return (
          currency === potentialCurrencyOrSymbol1 ||
          currency === potentialCurrencyOrSymbol2 ||
          symbol === potentialCurrencyOrSymbol1 ||
          symbol === potentialCurrencyOrSymbol2 ||
          currency.toLowerCase() ===
            potentialCurrencyOrSymbol1.replace(/[^a-zA-Z]/g, '').toLowerCase() ||
          currency.toLowerCase() ===
            potentialCurrencyOrSymbol2.replace(/[^a-zA-Z]/g, '').toLowerCase() ||
          symbol.toLowerCase() ===
            potentialCurrencyOrSymbol1.replace(/[^a-zA-Z]/g, '').toLowerCase() ||
          symbol.toLowerCase() ===
            potentialCurrencyOrSymbol2.replace(/[^a-zA-Z]/g, '').toLowerCase()
        );
      });

    if (isCurrencyOrSymbol === true) {
      return potentialPrice;
    }
  }

  return '';
};

const extractMenuJson = async (dispatch, json) => {
  try {
    const jsonData = JSON.parse(json);
    const menuItems = [];
    for (const rec of jsonData) {
      const { itemName, Quantity, Unit, category: categName, price } = rec;
      const description = Quantity && Unit ? `${Quantity} ${Unit}` : '';
      menuItems.push({
        include: true,
        itemName,
        description,
        category: { id: idfy(categName), name: categName },
        price
      });
    }

    dispatch(loadMenuItemsAction(menuItems.map((mi, index) => ({ id: index, ...mi }))));
  } catch (error) {
    console.log('err', error);
  }
};

const extractMenuData = async (dispatch, src) => {
  if (!src) {
    return;
  }

  const client = new TextractClient({
    region: 'us-east-1',
    credentials: {
      accessKeyId: getConfigValue('a', 'bid', true),
      secretAccessKey: getConfigValue('a', 'bkey', true)
    }
  });

  const byteArray = new Buffer(src.replace(/^[\w\d;:\/]+base64\,/g, ''), 'base64');
  const params = {
    Document: {
      Bytes: byteArray
    }
  };
  const command = new DetectDocumentTextCommand(params);
  try {
    toggleIsLoading(dispatch, true);
    const data = await client.send(command);
    console.log(
      'full-text',
      data.Blocks.filter((i) => i.BlockType === 'LINE')
        .map((i) => i.Text)
        .join('\n')
    );
    const texts = data.Blocks.filter((i) => i.BlockType === 'LINE').map((i) => i.Text);
    // a lame approach to get a sense of the data - to be improved
    let lastNumericValuePos = -1; // start from -1 so that the slice() can grab skipped texts from index 0
    let lastNonNumericValuePos = 0; // start from 0 so that if first value is a number, (lastNonNumericValuePos === idx-1) doesn't become tru below
    const menuItems = [];
    const description = '';
    for (let idx = 0; idx < texts.length; idx++) {
      const priceValue = getPotentialPriceValue(texts[idx]);
      if (priceValue === '') {
        lastNonNumericValuePos = idx;
        continue;
      }

      // if we are here, we have a numeric value
      if (lastNonNumericValuePos === idx - 1) {
        // current value is a number, and value at previous index was not a number
        const itemName = texts[lastNonNumericValuePos];
        const price = parseFloat(priceValue);
        if (lastNumericValuePos < idx - 2) {
          // add the skipped entries; but set include: false by default
          texts.slice(lastNumericValuePos + 1, idx - 1).map((skippedItem) => {
            menuItems.push({
              include: false,
              itemName: skippedItem,
              description,
              category: { id: 'main', name: 'Main' }
            });
          });
        }

        menuItems.push({
          include: true,
          itemName,
          description,
          category: { id: 'main', name: 'Main' },
          price
        });
        lastNumericValuePos = idx;
      }
    }

    // if we couldn't identify any valid item, most likely we didn't digest the menu format correctly
    // just display all text on the screen - user can modify as they want
    if (menuItems.length === 0 && texts.length > 0) {
      for (const text of texts) {
        menuItems.push({
          include: false,
          itemName: text,
          description,
          category: { id: 'main', name: 'Main' }
        });
      }
    }

    dispatch(loadMenuItemsAction(menuItems.map((mi, index) => ({ id: index, ...mi }))));
  } catch (error) {
    console.log('err', error);
  } finally {
    toggleIsLoading(dispatch, false);
  }
};

const updateMenuItem = (dispatch, menuItem) => dispatch(updateMenuItemAction(menuItem));

const insertMenuItem = (dispatch, menuItems, menuItem, at) => {
  const newItem = { ...menuItem, id: menuItems.length, include: false };
  const newList = [...menuItems.slice(0, at), newItem, ...menuItems.slice(at)];
  dispatch(loadMenuItemsAction(newList));
};

const doImport = async (history, dispatch, placeId, menuItems) => {
  for (let i = 0; i < menuItems.length; i++) {
    setProgressModal(dispatch, true, Math.ceil((100 * i) / menuItems.length));
    const menuItem = menuItems[i];
    if (menuItem.include !== true) {
      continue;
    }

    const { itemName, description, price = 0, category } = menuItem;
    let imageSrc = null;
    const lookupTerm = category.id + '-' + itemName.toLowerCase();
    const matches = stringSimilarity.findBestMatch(lookupTerm, foodImages);
    const targetImage = matches?.bestMatch?.target;
    if (targetImage) {
      imageSrc = `${settings.foodImagePath}/${targetImage}`;
    }

    await addItem(dispatch, placeId, itemName, description, price, true, imageSrc, category);
  }

  dispatch(loadMenuItemsAction([]));
  setProgressModal(dispatch, false);
  dispatch(popupSwitchFromTempUserAction(true)); // TODO: pop this up only if user.tempUserEmail is set
  history.push('/menu-list');
};

export {
  extractMenuJson,
  extractMenuData,
  updateMenuItem,
  insertMenuItem,
  doImport,
  loadMenuItemsAction
};
