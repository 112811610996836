import React, { useState, useEffect } from 'react';
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import { buildPagesFromCategAndItems } from '../actions';

const menuItemIcon = encodeURI('https://m.qrmeu.com/assets/menu-item-icon.png');

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: '#ffffff'
  },
  section: {
    width: 168,
    flexDirection: 'column',
    margin: 4,
    padding: 4
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 168,
    borderStyle: 'solid',
    borderColor: '#9f9f9f',
    borderWidth: 1,
    borderRadius: 2,
    margin: 2,
    padding: 2
  },
  item1: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 168,
    borderStyle: 'solid',
    borderColor: '#9f9f9f',
    borderWidth: 1,
    borderRadius: 2,
    marginHorizontal: 2,
    marginVertical: 4,
    padding: 2
  },
  category: {
    margin: 4,
    padding: 4,
    textDecoration: 'underline',
    textDecorationStyle: 'double',
    textDecorationColor: '#9f9f9f',
    fontWeight: 'bold',
    fontSize: 16
  },
  category1: {
    marginHorizontal: 4,
    marginVertical: 8,
    padding: 4,
    textDecoration: 'underline',
    textDecorationStyle: 'double',
    textDecorationColor: '#9f9f9f',
    fontWeight: 'bold',
    fontSize: 16
  },
  menuItemIcon: {
    paddingRight: 4,
    width: 12,
    height: 8
  },
  title: {
    flex: 1,
    marginRight: 4,
    fontWeight: 'bold',
    fontSize: 8
  },
  priceBox: {
    borderRadius: 2,
    backgroundColor: '#000000',
    padding: 4
  },
  price: {
    textAlign: 'right',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: 8
  },
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%'
  }
});

// Create Document Component
const MenuItems = ({ coverImage, categories, items }) => {
  const [pages, setPages] = useState([]);
  const [spacing, setSpacing] = useState(0);

  useEffect(() => {
    const { pages: pages1, extraSpacingUsed } = buildPagesFromCategAndItems(categories, items);
    setPages(pages1);
    setSpacing(extraSpacingUsed);
  }, [categories, items]);

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <Image src={coverImage} style={styles.pageBackground} />
      </Page>
      {pages.map((page, idx0) => (
        <Page key={idx0} size="A4" orientation="landscape" style={styles.page}>
          {page.map((section, idx1) => (
            <View key={idx1} style={styles.section}>
              {section.map(({ type, data }, idx2) =>
                type === 'category' ? (
                  <View key={idx2} style={spacing === 1 ? styles.category1 : styles.category}>
                    <Text>{data.name}</Text>
                  </View>
                ) : (
                  <View key={idx2} style={spacing === 1 ? styles.item1 : styles.item}>
                    <Image src={menuItemIcon} style={styles.menuItemIcon} />
                    <Text style={styles.title}>{data.itemName}</Text>
                    <View style={styles.priceBox}>
                      <Text style={styles.price}>{parseFloat(data.price).toFixed(2)}</Text>
                    </View>
                  </View>
                )
              )}
            </View>
          ))}
        </Page>
      ))}
    </Document>
  );
};

export default MenuItems;
