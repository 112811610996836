import React, { useCallback, useState } from 'react';

import AgentLogin from './AgentLogin';

import { addPlaceIdToSalesMan, updateAgentSubscription, verifyAgent } from '../actions';
import StripeOnlinePayment from './StripeOnlinePayment';
import { ArrowBack } from '@mui/icons-material';

const Payment = ({ prevPage, setPrevPage, setPage, leadId }) => {
  const [payMode, setPayMode] = useState('agent'); // agent || online

  const proceedWithAgentLogin = useCallback(async (agentId, pinNumber, urlHost2) => {
    try {
      const { salesManId, placesBySalesMan } = await verifyAgent(agentId, pinNumber);
      if (salesManId) {
        // agent is valid
        if (placesBySalesMan.indexOf(leadId) === -1) {
          placesBySalesMan.push(leadId);
          await addPlaceIdToSalesMan(salesManId, placesBySalesMan);
        }
        await updateAgentSubscription(leadId, agentId, urlHost2);
        setPrevPage([...prevPage, 'payment']);
        setPage('contact-us');
      }
    } catch (error) {
      console.log('proceedWithAgentLogin Error : ', error.message);
    }
  }, []);
  const handleBack = () => {
    const prev = prevPage[prevPage.length - 1];
    setPrevPage((prevItems) => prevItems.filter((item, i) => i !== prevItems.length - 1));
    setPage(prev);
  };
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form ">
            <div className="m-3 ">
              <ArrowBack className="mb-5" onClick={handleBack} />
              <div style={{ textAlign: 'center' }}>
                <h5 style={{ textAlign: 'center' }}>
                  Congrats on making the first step towards growing your bussiness with FerryPal
                </h5>
                <h5>Please make the one time payment of INR 5000</h5>
                <h5 style={{ textAlign: 'center' }}>
                  {' '}
                  Once we receive the payment, we will reach out to you in 24-48 hours with your
                  Digital Menu and Dashboard details{' '}
                </h5>
              </div>
              <form
                className="needs-validation "
                noValidate
                style={{ height: '400px', width: '100%' }}>
                {true && (
                  <>
                    <h3 className="text-center">Payment</h3>
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <div
                          className={`nav-link ${payMode === 'online' ? 'active' : ''}`}
                          onClick={() => setPayMode('online')}>
                          Pay Online
                        </div>
                      </li>
                      <li className="nav-item">
                        <div
                          className={`nav-link ${payMode === 'agent' ? 'active' : ''}`}
                          onClick={() => setPayMode('agent')}>
                          Pay Agent
                        </div>
                      </li>
                    </ul>
                    {payMode === 'online' && <StripeOnlinePayment />}
                    {payMode === 'agent' && <AgentLogin onSubmit={proceedWithAgentLogin} />}
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
