import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';

import { auth, db } from '../../lib/firebase';
import { setUserAction } from '../Login/actions';
import settings from '../../../config/settings';

const sendVerificationEmail = (dispatch, history, email, userCredential) => {
  // User created
  const user = userCredential.user;
  // ...
  console.log('yahooo..', user);
  dispatch(setUserAction(user ?? {}));

  sendEmailVerification(auth.currentUser, { url: settings.storeUrl })
    .then(() => {
      console.log('Verification Email Sent Successfully !');

      // Add a new document in collection "users"
      setDoc(doc(db, 'users', user.uid), {
        displayName: email.split('@')[0],
        userType: 'store-user',
        email,
        createdAt: new Date()
      });

      // history.push('/default-login');
      history.push('/'); // temporarily we are not verifying email; go to home page
    })
    .catch((error) => {
      console.error(error);
    });
};

const createUser = (dispatch, history, email, password) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => sendVerificationEmail(dispatch, history, email, userCredential))
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('errors..', errorCode, errorMessage);
      alert(errorMessage);
    });
};

export { createUser };
