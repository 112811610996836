// Progressive web app
const initializeServiceWorker = () => {
  // Some browser does not support progressive web app
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js').then(() => {
      console.log('Service worker registered!');
    });
  }
};

export { initializeServiceWorker };
