import * as ActionTypes from '../../data/state/action-types';

const initialState = {
  categories: [
    /* {id, name, order, discountPercentage, dealValidFrom, dealValidTo} */
  ],
  items: [
    /* {itemId, itemName, description, price, imageSrc, available, ourSpecial, category, dealPrice, dealValidFrom, dealValidTo} */
  ]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };
    case ActionTypes.ADD_CATEGORY:
      return {
        ...state,
        categories: [action.category, ...state.categories]
      };
    case ActionTypes.UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((category) => {
          return category.id === action.id ? action.updatedCategory : category;
        })
      };
    case ActionTypes.LOAD_ITEMS:
      return {
        ...state,
        items: action.items
      };
    case ActionTypes.ADD_ITEM:
      return {
        ...state,
        items: [
          {
            itemId: action.itemId,
            itemName: action.itemName,
            description: action.description,
            price: action.price,
            available: action.available,
            ourSpecial: false,
            imageSrc: action.imageSrc,
            category: action.category
          },
          ...state.items
        ]
      };
    case ActionTypes.SET_ITEM_AVAILABILITY:
      return {
        ...state,
        items: state.items.map((item) => {
          return item.itemId === action.itemId ? { ...item, available: action.available } : item;
        })
      };
    case ActionTypes.SET_ITEM_SPECIAL:
      return {
        ...state,
        items: state.items.map((item) => {
          return item.itemId === action.itemId ? { ...item, ourSpecial: action.ourSpecial } : item;
        })
      };
    case ActionTypes.DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item.itemId !== action.itemId)
      };
    case ActionTypes.UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((item) => {
          return item.itemId === action.itemId ? action.updatedItem : item;
        })
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
