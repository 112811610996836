import React from 'react';

const ContactUs = () => {
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form d-flex flex-column ">
            <div className="m-3 pt-5 " style={{ textAlign: 'center' }}>
              <h3>Thank you!</h3>
              <h5 style={{ textAlign: 'center' }}>
                We are all set. We will be contacting you soon with your user name and password to
                access your Dashboard.
              </h5>
              <h5>Is there anything else we can help with?</h5>
              <div className="d-flex justify-content-center">
                <a href="https://wa.me/9645117111" target="blank" className="btn btn-primary m-3">
                  Contact Us
                </a>
                <a
                  href="https://www.ferrypal.com/features/"
                  target="blank"
                  className="btn btn-primary m-3">
                  More About FerryPal
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
