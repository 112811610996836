import stringSimilarity from 'string-similarity';

import * as ActionTypes from '../../data/state/action-types';
import { toggleIsLoading } from '../Loading/actions';
import { addItem } from '../AddItem/actions.js';
import foodImages from '../../data/food-images.json';
import settings from '../../../config/settings';

const loadMenuItemsAction = (menuItems) => ({
  type: ActionTypes.LOAD_MULTI_MENU_ITEMS,
  menuItems
});

const initMenuItemAction = () => ({
  type: ActionTypes.INIT_MULTI_MENU_ITEMS
});

const updateMenuItemAction = (menuItem) => ({
  type: ActionTypes.UPDATE_MULTI_MENU_ITEM,
  menuItem
});

const updateMenuItem = (dispatch, menuItem) => dispatch(updateMenuItemAction(menuItem));

const insertMenuItem = (dispatch, menuItems, menuItem, at) => {
  const newItem = { ...menuItem, id: menuItems.length, include: false };
  const newList = [...menuItems.slice(0, at), newItem, ...menuItems.slice(at)];
  dispatch(loadMenuItemsAction(newList));
};

const doAdd = async (history, dispatch, placeId, menuItems) => {
  toggleIsLoading(dispatch, true);
  for (const menuItem of menuItems) {
    if (menuItem.include !== true) {
      continue;
    }

    const { itemName, description, price = 0, category } = menuItem;
    let imageSrc = null;
    const lookupTerm = category.id + '-' + itemName.toLowerCase();
    const matches = stringSimilarity.findBestMatch(lookupTerm, foodImages);
    const targetImage = matches?.bestMatch?.target;
    if (targetImage) {
      imageSrc = `${settings.foodImagePath}/${targetImage}`;
    }

    await addItem(dispatch, placeId, itemName, description, price, true, imageSrc, category);
  }

  dispatch(initMenuItemAction());
  toggleIsLoading(dispatch, false);
  history.push('/menu-list');
};

export { updateMenuItem, insertMenuItem, doAdd };
