import React, { useContext, useEffect, useState } from 'react';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { StateContext } from '../../../data/state/state-context';
import AddCategoryDeal from './AddCategoryDeal';
import CategoryDealItem from './CategoryDealItem';
const CategoryDeals = ({ dealCategories, setDealCategories, dealItems }) => {
  const [rootState, dispatch] = useContext(StateContext);
  const { categories } = rootState.items;
  const { placeId } = rootState.main;

  const [isAddNewVissible, toggleAddNew] = useState(false);

  useEffect(() => {
    // setDealItems from items which has dealPrice active
    const now = new Date().getTime();
    const _dealCategories = categories
      .map((cat) => {
        if (
          cat.dealValidFrom &&
          cat.dealValidTo &&
          typeof cat.dealValidFrom.toDate === 'function' &&
          typeof cat.dealValidTo.toDate === 'function' &&
          new Date(cat.dealValidFrom.toDate()).getTime() <
            new Date(cat.dealValidTo.toDate()).getTime() &&
          now < new Date(cat.dealValidTo.toDate()).getTime()
        ) {
          return cat;
        } else {
          return null;
        }
      })
      .filter((cat) => cat !== null);
    setDealCategories(_dealCategories);
  }, [categories, setDealCategories]);

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header new">
              <h6> Category Deals</h6>
              <button className="btn btn-primary d-block" onClick={() => toggleAddNew(true)}>
                Add New Deal
              </button>
            </div>
            <div className="ms-panel-body px-1 py-1">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Category</th>
                      <th scope="col">Discount Percentage</th>
                      <th scope="col">Valid From</th>
                      <th scope="col">Valid To</th>
                      <th scope="col">{/*Actions for deletion*/}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isAddNewVissible && (
                      <AddCategoryDeal
                        toggleAddNew={toggleAddNew}
                        dispatch={dispatch}
                        placeId={placeId}
                        categories={categories}
                        dealCategories={dealCategories}
                        dealItems={dealItems}
                      />
                    )}
                    {dealCategories.map((deal, idx) => (
                      <CategoryDealItem
                        dispatch={dispatch}
                        category={deal}
                        placeId={placeId}
                        key={`deal-item-${idx}`}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryDeals;
