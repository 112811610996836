import { collection, doc, query, where, getDocs, updateDoc, arrayUnion } from 'firebase/firestore';

import { db } from '../../lib/firebase';

const verifyAgent = async (userId, pin) => {
  try {
    let salesManInfo = { salesManId: '', placesBySalesMan: [] };
    const q = query(
      collection(db, 'salesman'),
      where('userId', '==', userId),
      where('pin', '==', pin)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      // invalid credentials
      alert('Invalid Credentials');
    } else {
      const doc = querySnapshot.docs[0];
      const { places = [], isActive } = doc.data();
      if (!isActive) {
        // Agent is not active
        alert('Agent is not active');
      } else {
        salesManInfo.salesManId = doc.id;
        salesManInfo.placesBySalesMan = places;
      }
    }
    return salesManInfo;
  } catch (error) {
    throw error;
  }
};

const addPlaceIdToSalesMan = async (salesManId, places = []) => {
  try {
    const placeRef = doc(db, 'salesman', salesManId);
    await updateDoc(placeRef, { places });
  } catch (error) {
    throw error;
  }
};

const updateAgentSubscription = async (placeId, agentId, urlHost2, subscriptionType, isMonthly) => {
  try {
    const subscriptionEndDate = new Date();
    if (isMonthly) {
      subscriptionEndDate.setMonth(subscriptionEndDate.getMonth() + 1);
    } else {
      subscriptionEndDate.setFullYear(subscriptionEndDate.getFullYear() + 1);
    }
    const placeRef = doc(db, 'places', placeId);
    await updateDoc(placeRef, {
      urlHost2,
      hasSubscribed: true,
      subscriptionEndDate,
      subscriptionType,
      paymentInfo: arrayUnion({
        when: new Date(),
        mode: 'agent',
        agentId
      })
    });
  } catch (error) {
    throw error;
  }
};

const updateOnlineSubscription = async (placeId, paymentId, mode, subscriptionType, isMonthly) => {
  try {
    const subscriptionEndDate = new Date();
    if (isMonthly) {
      subscriptionEndDate.setMonth(subscriptionEndDate.getMonth() + 1);
    } else {
      subscriptionEndDate.setFullYear(subscriptionEndDate.getFullYear() + 1);
    }

    const placeRef = doc(db, 'places', placeId);
    await updateDoc(placeRef, {
      hasSubscribed: true,
      subscriptionEndDate,
      subscriptionType,
      paymentInfo: arrayUnion({
        when: new Date(),
        mode,
        paymentId
      })
    });
  } catch (error) {
    throw error;
  }
};

const updateFreeSubscription = async (placeId) => {
  try {
    const placeRef = doc(db, 'places', placeId);
    await updateDoc(placeRef, {
      hasSubscribed: true,
      subscriptionType: 0
    });
  } catch (error) {
    throw error;
  }
};

export {
  verifyAgent,
  updateAgentSubscription,
  updateOnlineSubscription,
  addPlaceIdToSalesMan,
  updateFreeSubscription
};
