import React, { useState } from 'react';
import PDFMenu from './PDFMenu';

const Content = ({ coverImages }) => {
  const [selectedMenu, setSelectedMenu] = useState(-1);
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12 ms-panel text-center p-4">
          <h3>Printable Menu</h3>
          <div className="d-flex">
            <div class="row">
              {coverImages.map((image, index) => (
                <>
                  <div class="col-sm-12 col-lg-8 pt-5">
                    <img src={image.data} alt="Menu cover page" />
                  </div>
                  <div class="col-sm-12 col-lg-4 pt-1 my-auto">
                    <div className="ms-card">
                      <div className="ms-card-body">
                        <h6>{image.title}</h6>
                        <p>{image.descr}</p>
                        {index === selectedMenu ? (
                          <PDFMenu coverImage={image.data} />
                        ) : (
                          <button
                            onClick={() => setSelectedMenu(index)}
                            type="button"
                            class="btn btn-info">
                            Choose This Design
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
