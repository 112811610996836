import React, { useEffect, useState, useContext }  from 'react';
import { useHistory } from 'react-router-dom';
import { authenticate } from '../actions';
import { StateContext } from '../../../data/state/state-context';

const Loading = () => {
  const [, dispatch] = useContext(StateContext);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    if(loading === true && history){
      authenticate(dispatch, history);
      setLoading(false);
    }
  }, [history, loading]);

  return (
    <div>Loading...</div>
  );
};

export default Loading;
