import React, { useCallback, useContext, useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { StateContext } from '../../../data/state/state-context';
import { getRatings, updateStoreRatings } from '../actions';

export const ratingLabels = {
  1: 'Not up to par',
  2: 'Not Bad',
  3: 'Good',
  4: 'Very Good',
  5: 'Extremely Good'
};

const RatingList = () => {
  const [rootState] = useContext(StateContext);
  const { placeId } = rootState.main;

  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const _ratings = await getRatings(placeId);
        setRatings(_ratings);
      } catch (error) {
        setRatings([]);
      }
    };

    if (placeId) {
      fetchRatings();
    }
  }, [placeId]);

  const setRatingVisibility = useCallback(
    (ratedBy, disabled) => {
      const updatedRatings = ratings.map((itm) =>
        itm.ratedBy === ratedBy ? { ...itm, ratedBy, disabled } : itm
      );
      setRatings(updatedRatings);
      updateStoreRatings(placeId, updatedRatings);
    },
    [ratings, placeId]
  );

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header new">
              <h6>Ratings</h6>
            </div>
            <div className="ms-panel-body px-1 py-1">
              {ratings.length > 0 && (
                <div className="table-responsive">
                  <table className="table table-hover thead-primary">
                    <thead>
                      <tr>
                        <th scope="col">Show</th>
                        <th scope="col">Ratings</th>
                        <th scope="col">Commments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ratings.map((rating, idx) => (
                        <tr key={`rating_key_${idx}`}>
                          <td>
                            <label className="ms-switch">
                              <input
                                type="checkbox"
                                defaultChecked={rating.disabled !== true}
                                onClick={() =>
                                  setRatingVisibility(rating.ratedBy, !rating.disabled)
                                }
                              />
                              <span className="ms-switch-slider ms-switch-success round" />
                            </label>
                          </td>
                          <td>
                            <Typography>{ratingLabels[rating.value]}</Typography>
                            <Rating name="read-only-rating" value={rating.value} readOnly />
                          </td>
                          <td>
                            <Typography>{rating.comments}</Typography>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {ratings.length === 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ minHeight: '40vh' }}>
                  <Typography>You don't have any ratings</Typography>
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingList;
