import React from 'react';
import { Link } from 'react-router-dom';

function storeCard({ item }) {
  const { address, imageSrc, placeName, urlHost } = item;
  const index = urlHost.indexOf('.');
  const slug = urlHost.substring(0, index);
  return (
    <div className="col-md-4 mt-3 d-flex justify-content-center">
      <div className="card" style={{ width: '21rem', height: '400px' }}>
        <img className="card-img-top" src={imageSrc} alt="Card" style={{ height: '210px' }} />
        <div className="card-body  bg-black ">
          <h5 className="card-title text-capitalize text-light">{placeName}</h5>
          <h5 className="card-text text-light">Address:</h5>
          <p className="card-text text-light">{address}</p>
          <Link to={`/m/${slug}`}>
            <button className="btn btn-primary w-100 m-auto">Choose</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default storeCard;
