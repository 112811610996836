import React, { useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

const SearchStores = ({ fetchOnlineStores, disabled }) => {
  const [searchStr, setSearchStr] = useState('');

  const onSearch = (e) => {
    e.preventDefault();
    fetchOnlineStores(searchStr);
  };

  // const doFetch = useCallback(
  //   async (query) => {
  //     fetchOnlineStores(query);
  //   },
  //   [fetchOnlineStores]
  // );

  // const onChangeUrl = useMemo(() => debounce(doFetch, 3000), [doFetch]);

  // useEffect(() => {
  //   if (searchStr && searchStr.length > 3) {
  //     onChangeUrl(searchStr);
  //   }
  // }, [onChangeUrl, searchStr]);

  return (
    <div className="ms-panel-body py-3 px-0">
      <div className="ms-chat-container">
        <div className="ms-chat-header px-3">
          <div className="input-group mb-3">
            <input
              type="text"
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
              className="form-control"
              placeholder="Search for more stores"
            />
            <div className="input-group-append">
              <button
                className="btn btn-secondary btn-sm"
                onClick={onSearch}
                disabled={disabled || !searchStr}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchStores;
