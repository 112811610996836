import React, { useContext } from 'react';

import { StateContext } from '../../../data/state/state-context';

const ConfirmationPage = () => {
  const [rootState] = useContext(StateContext);

  if (
    !rootState.multiMenu.menuItems.some(
      (mi) => mi.itemName.trim().length > 0 && mi.include === true
    )
  ) {
    return (
      <div className="alert alert-danger" role="alert">
        No items to add
      </div>
    );
  }

  return (
    <div className="ms-wizard-step">
      <p className="h6 px-4 py-4">
        Your items are ready to be added. After you hit Finish, you can view them and make more
        changes from the Menu List.
      </p>
    </div>
  );
};

export default ConfirmationPage;
