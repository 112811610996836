import React, { useState, useContext, useEffect } from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Content from './Content';
import LoadingSpinner from '../../../shared/loadingspinner';
import CoverImage from './CoverImage';
import { StateContext } from '../../../data/state/state-context';
import { toggleIsLoading } from '../../Loading/actions';

const backgroundImage1 = encodeURI('https://m.qrmeu.com/assets/menu-cover1.png');
const backgroundImage2 = encodeURI('https://m.qrmeu.com/assets/menu-cover2.png');
const backgroundImage3 = encodeURI('https://m.qrmeu.com/assets/menu-cover3.png');
const backgroundImage4 = encodeURI('https://m.qrmeu.com/assets/menu-cover4.png');
const backgroundImage5 = encodeURI('https://m.qrmeu.com/assets/menu-cover5.png');
const backgroundImage6 = encodeURI('https://m.qrmeu.com/assets/menu-cover6.png');

/**
 * To add new menu designs here:
 * - Create a background image
 *   -- tip:
 *   -- make a copy of existing image like menu-cover2.png and change
 *   -- so far the diff between designs are just the left side of the image
 *   -- so you could just paste a new design on the left side and make it align
 * - Then 1. import that image, 2. add a `CoverImage` comp below, 3. update coverImages attribute of `Content`
 * - That's all!
 */

const PrintableMenu = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { placeName, urlHost } = rootState.main;
  const [imageData1, setImageData1] = useState(null);
  const [imageData2, setImageData2] = useState(null);
  const [imageData3, setImageData3] = useState(null);
  const [imageData4, setImageData4] = useState(null);
  const [imageData5, setImageData5] = useState(null);
  const [imageData6, setImageData6] = useState(null);

  useEffect(() => {
    const loading =
      !imageData1 || !imageData2 || !imageData3 || !imageData4 || !imageData5 || !imageData6;
    toggleIsLoading(dispatch, loading);

    return () => toggleIsLoading(dispatch, false);
  }, [dispatch, imageData1, imageData2, imageData3, imageData4, imageData5, imageData6]);

  return (
    <>
      <div className="ms-body ms-aside-left-open ms-primary-theme">
        <Sidenavigation />
        <main className="body-content">
          <Topnavigation />
          <Content
            coverImages={[
              {
                title: 'Bold',
                descr: 'Makes a bold stance as your yummy burger',
                data: imageData1
              },
              {
                title: 'Playful',
                descr: 'As playful as your young customers can imagine',
                data: imageData2
              },
              { title: 'Hotbun', descr: 'For the eyes of your craving customer', data: imageData3 },
              { title: 'Elegant', descr: 'As elegant as it can be', data: imageData4 },
              { title: 'Juicy', descr: 'As juicy as your juices', data: imageData5 },
              { title: 'Cool Dark', descr: 'Plain, dark and simple', data: imageData6 }
            ]}
          />
          <LoadingSpinner asOverlay />
        </main>
      </div>

      {/* placing the section below outside of ms-body panel to generate image with a size that that fits well on A4 page */}
      <div style={{ opacity: 0 }}>
        <CoverImage
          setImageData={setImageData1}
          BackgroundImage={backgroundImage1}
          placeName={placeName}
          urlHost={urlHost}
        />
        <CoverImage
          setImageData={setImageData2}
          BackgroundImage={backgroundImage2}
          placeName={placeName}
          urlHost={urlHost}
        />
        <CoverImage
          setImageData={setImageData3}
          BackgroundImage={backgroundImage3}
          placeName={placeName}
          urlHost={urlHost}
        />
        <CoverImage
          setImageData={setImageData4}
          BackgroundImage={backgroundImage4}
          placeName={placeName}
          urlHost={urlHost}
        />
        <CoverImage
          setImageData={setImageData5}
          BackgroundImage={backgroundImage5}
          placeName={placeName}
          urlHost={urlHost}
        />
        <CoverImage
          setImageData={setImageData6}
          BackgroundImage={backgroundImage6}
          placeName={placeName}
          urlHost={urlHost}
        />
      </div>
    </>
  );
};

export default PrintableMenu;
