import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React from 'react';
import { ColorExtractor } from 'react-color-extractor';
import { db, storage } from '../../../lib/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { generateGUID } from '../../../utils';
import { ArrowBack } from '@mui/icons-material';
const SelectImage = ({ onImageChange }) => (
  <div className="m-3">
    <label htmlFor="create-store-image">Store image/logo</label>
    <div className="input-group">
      <input
        type="file"
        className="form-control"
        id="create-store-image"
        placeholder="Select image..."
        required
        onChange={onImageChange}
      />
    </div>
    <div className="invalid-feedback">Invalid image</div>
  </div>
);
const StoreImage = ({ storeImgFile, onRemoveImage }) => (
  <div className="m-3">
    <div>
      <img className="d-block w-50 h-50" src={storeImgFile} alt="Store" />
    </div>

    <div className="new">
      <button type="button" className="btn btn-secondary" onClick={onRemoveImage}>
        Remove
      </button>
    </div>
  </div>
);

const MenuAndLogo = ({
  prevPage,
  setPrevPage,
  setPage,
  leadId,
  storeImgSrc,
  setStoreImgSrc,
  storeMenuSrc,
  setStoreMenuSrc,
  storeImgFile,
  setStoreImgFile,
  menuLogoLater,
  setMenuLogoLater,
  setColors
}) => {
  const onMenuChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setStoreMenuSrc(event.target.files[0]);
    }
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function () {
        setStoreImgFile(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setStoreImgSrc(event.target.files[0]);
      event.target.value = null; // clear the input field
    }
  };
  const handleNoMenuAndLogo = () => {
    setMenuLogoLater(true);
  };
  const onRemoveImage = () => {
    setStoreImgFile(null);
  };

  function updateLogo(logo) {
    const logoName = generateGUID();
    try {
      const imageRef = ref(storage, `logos/${logoName}`);
      uploadBytes(imageRef, logo).then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          await updateDoc(doc(db, 'leads', leadId), {
            storeImageURL: url
          });
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
  function updateMenu(menu) {
    const menuName = generateGUID();
    try {
      const imageRef = ref(storage, `menus/${menuName}`);
      uploadBytes(imageRef, menu).then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          await updateDoc(doc(db, 'leads', leadId), {
            storeMenuURL: url
          });
        });
      });
    } catch (error) {
      console.lop(error);
    }
  }
  const handleContinue = (event) => {
    event.preventDefault();
    if (menuLogoLater || storeImgSrc || storeMenuSrc) {
      storeImgSrc && updateLogo(storeImgSrc);

      storeMenuSrc && updateMenu(storeMenuSrc);
      setPrevPage([...prevPage, 'menu-logo']);
      setPage('store-contacts');
    } else {
      alert('Please provide the logo and menu');
      return;
    }
  };
  const handleBack = () => {
    const prev = prevPage[prevPage.length - 1];
    setPrevPage((prevItems) => prevItems.filter((item, i) => i !== prevItems.length - 1));
    setPage(prev);
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col  ml-3" style={{ height: '100vh', overflowY: 'scroll' }}>
          <div className="ms-auth-form">
            <div className="m-3 ">
              <ArrowBack className="mb-3" onClick={handleBack} />
              <h2>Upload your logo and menu</h2>
              {storeImgFile !== null ? (
                <StoreImage storeImgFile={storeImgFile} onRemoveImage={onRemoveImage} />
              ) : (
                <SelectImage onImageChange={onImageChange} />
              )}
              <div className="m-3">
                {storeImgSrc !== null && (
                  <ColorExtractor src={storeImgFile} getColors={setColors} />
                )}
              </div>
              <div className="m-3">
                <label htmlFor="menu">Store menu</label>
                <input
                  type="file"
                  className="form-control"
                  id="menu"
                  onChange={onMenuChange}
                  required
                />
                <span> {storeMenuSrc ? storeMenuSrc.name : ''}</span>
              </div>
              <div className="m-3">
                {storeMenuSrc || storeImgSrc ? (
                  <span></span>
                ) : (
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="nomenulogo"
                      onChange={handleNoMenuAndLogo}
                    />
                    <label className="form-check-label" htmlFor="nomenulogo">
                      I will upload logo and/or menu later
                    </label>
                  </div>
                )}
                <div className="m-3">
                  <button type="button" className="btn btn-primary" onClick={handleContinue}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuAndLogo;
