import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import MultiStep from './react-multistep';
import MultiForm from './MultiForm';
import ConfirmationPage from './ConfirmationPage';
import { StateContext } from '../../../data/state/state-context';
import { doAdd } from '../actions';

const prevStyle = {};
const nextStyle = {};
const Steps = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const history = useHistory();

  const steps = [{ component: <MultiForm /> }, { component: <ConfirmationPage /> }];

  const onFinish = () =>
    doAdd(history, dispatch, rootState.main.placeId, rootState.multiMenu.menuItems);

  return (
    <MultiStep steps={steps} prevStyle={prevStyle} nextStyle={nextStyle} onFinish={onFinish} />
  );
};

export default Steps;
