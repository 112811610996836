import React, { Component } from 'react';
import Content from '../sections/Lockscreen/Content';

class Lockscreen extends Component {
    render() {
        return (
           <Content/>
        );
    }
}

export default Lockscreen;