import { doc, collection, updateDoc, arrayUnion } from 'firebase/firestore';

import { query, where, getDocs } from 'firebase/firestore';

import { db } from '../../lib/firebase';

import { getUrlHost } from '../../utils';

const validateUrlHost = async (urlPrefix) => {
  if (!urlPrefix || urlPrefix.trim() === '') {
    return '';
  }

  if (urlPrefix.trim().length < 2) {
    return 'invalid';
  }

  const urlHost = getUrlHost(urlPrefix);
  try {
    const q = query(collection(db, 'leads'), where('onlineStoreURL', '==', urlHost));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return 'valid';
    } else {
      return 'invalid';
    }
  } catch (error) {
    console.log(error);
  }
};
const addPlaceIdToSalesMan = async (salesManId, places = []) => {
  try {
    const placeRef = doc(db, 'salesman', salesManId);
    await updateDoc(placeRef, { places });
  } catch (error) {
    throw error;
  }
};
const updateAgentSubscription = async (collectionId, agentId, urlHost2) => {
  try {
    const placeRef = doc(db, 'leads', collectionId);
    await updateDoc(placeRef, {
      paymentInfo: arrayUnion({
        when: new Date(),
        mode: 'agent',
        agentId: agentId
      })
    });
  } catch (error) {
    throw error;
  }
};
const verifyAgent = async (userId, pin) => {
  try {
    let salesManInfo = { salesManId: '', placesBySalesMan: [] };
    const q = query(
      collection(db, 'salesman'),
      where('userId', '==', userId),
      where('pin', '==', pin)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      // invalid credentials
      alert('Invalid Credentials');
    } else {
      const doc = querySnapshot.docs[0];
      const { places = [], isActive } = doc.data();
      if (!isActive) {
        // Agent is not active
        alert('Agent is not active');
      } else {
        salesManInfo.salesManId = doc.id;
        salesManInfo.placesBySalesMan = places;
      }
    }
    return salesManInfo;
  } catch (error) {
    throw error;
  }
};

export { validateUrlHost, addPlaceIdToSalesMan, updateAgentSubscription, verifyAgent };
