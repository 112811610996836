import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import Draggable from 'react-draggable';
const CardContent = ({ cardimg, textColor }) => {
  const [textContent, setTextContent] = useState('');
  const divRef = useRef(null);
  const handleDownload = (e) => {
    e.preventDefault();
    console.log('download');
    html2canvas(divRef.current).then(function (canvas) {
      const link = document.createElement('a');
      link.download = 'greetingCard.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  };
  return (
    <div className="card" style={{ width: '400px' }}>
      <div className="cont" ref={divRef}>
        <img
          src={cardimg}
          className="card-img-top"
          alt="cardphoto"
          style={{ position: 'relative' }}
        />
        <Draggable>
          <div
            style={{
              position: 'absolute',
              color: textColor,
              cursor: 'grab',
              fontSize: '20px',
              fontWeight: 'bold'
            }}>
            {textContent}
          </div>
        </Draggable>
      </div>
      <div className="card-body">
        <form>
          <div className="form-group bg-primary">
            <input
              type="text"
              className="form-control"
              value={textContent}
              onChange={(e) => setTextContent(e.target.value)}
              id="input"
              placeholder="Enter Text"
            />
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary " onClick={handleDownload}>
              Download
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CardContent;
