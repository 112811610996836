import { createContext } from 'react';
import mainReducer from '../../screens/Root/reducers';
import itemsReducer from '../../screens/Items/reducers';
import ordersReducer from '../../screens/Orders/reducers';
import importMenuReducer from '../../screens/ImportMenu/reducers';
import multiMenuReducer from '../../screens/AddMultipleItems/reducers';
import { usePersistedReducer } from './persisted-reducer';

const combineReducers = (reducers) => {
  return (children, action) => {
    const newState = {};
    for (const key in reducers) {
      newState[key] = reducers[key](children[key], action);
    }
    return newState;
  };
};

const state = {
  main: mainReducer.initialState,
  items: itemsReducer.initialState,
  orders: ordersReducer.initialState,
  importMenu: importMenuReducer.initialState,
  multiMenu: multiMenuReducer.initialState
};

const reducers = combineReducers({
  main: mainReducer.reducer,
  items: itemsReducer.reducer,
  orders: ordersReducer.reducer,
  importMenu: importMenuReducer.reducer,
  multiMenu: multiMenuReducer.reducer
});

const getCombinedReducer = () => {
  // TODO: isn't this run on the client? If so, the env var is not going to be set, and we will see reducer logs always
  if (process.env.NODE_ENV === 'production') {
    return reducers;
  }

  const logger = require('use-reducer-logger').default;
  return logger(reducers);
};

const useRootReducer = () => {
  const cr = getCombinedReducer();
  const storageKey = '__blackboard_state';
  return usePersistedReducer(cr, state, storageKey);
};

const StateContext = createContext();

export { useRootReducer, StateContext };
