import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PageOfSmartOrderEx from './PageOfSmartOrderEx';
const preview = encodeURI('https://m.qrmeu.com/assets/Vector-black.png');
// Create Document Component
const PDFOfSmartOrderEx = ({ cardImage, cardQrImage }) => {
  return (
    cardQrImage && (
      <>
        <div className="d-block ">
          <PDFDownloadLink
            document={<PageOfSmartOrderEx cardImage={cardImage} cardQrImage={cardQrImage} />}
            fileName={`Smart Order Card`}>
            {({ loading }) =>
              loading ? (
                <button
                  type="button"
                  class="btn btn-white text-dark  border-light rounded m-3"
                  style={{ textAlign: 'center', width: '230px' }}>
                  Please Wait..
                  <img src={preview} alt="prview" className="ml-5" />
                </button>
              ) : (
                <button
                  type="button"
                  class="btn btn-white text-dark border-light rounded m-3"
                  style={{ textAlign: 'center', width: '230px' }}>
                  Download Card
                  <img src={preview} alt="prview" className="ml-5" />
                </button>
              )
            }
          </PDFDownloadLink>
        </div>
      </>
    )
  );
};

export default PDFOfSmartOrderEx;
