import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { validateUrlHost } from '../actions';
import { getUrlHost } from '../../../utils';

const StoreUrl = ({ urlPrefix, setUrlPrefix, isUrlValid, setIsUrlValid }) => {
  const [isLoading, setIsLoading] = useState(false);

  const validateUrl = useCallback(
    async (url) => {
      try {
        setIsLoading(true);
        const _isUrlValid = await validateUrlHost(url);
        setIsUrlValid(_isUrlValid);
        setIsLoading(false);
      } catch (error) {
        setIsUrlValid('invalid');
        setIsLoading(false);
      }
    },
    [setIsUrlValid]
  );

  const onChangeUrl = useMemo(() => debounce(validateUrl, 1000), [validateUrl]);

  useEffect(() => {
    onChangeUrl(urlPrefix);
  }, [onChangeUrl, urlPrefix]);

  return (
    <div className="mb-3">
      <label htmlFor="basic-url">Preferred domain *</label>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon3">
            https://
          </span>
        </div>
        <input
          type="text"
          className={`form-control is-${isUrlValid}`}
          id="basic-url"
          aria-describedby="basic-addon3"
          value={urlPrefix}
          onChange={(e) => setUrlPrefix(e.target.value)}
        />
        <div className="input-group-append">
          <span className="input-group-text" id="basic-addon3">
            .myferrypal.com
          </span>
        </div>
        {isLoading !== true && isUrlValid !== '' && urlPrefix !== '' && (
          <div className={`${isUrlValid}-feedback is-${isUrlValid}`}>
            {`https://${getUrlHost(urlPrefix)} ${isUrlValid === 'valid' ? '' : 'is unavailable'}`}
          </div>
        )}
        {isLoading !== true && isUrlValid !== '' && urlPrefix === '' && (
          <div className={`invalid-feedback is-${isUrlValid}`}>Required</div>
        )}
      </div>
    </div>
  );
};

export default StoreUrl;
