import { collection, addDoc } from 'firebase/firestore';
import { geohashForLocation } from 'geofire-common';
import { query, where, getDocs } from 'firebase/firestore';

import { auth, db } from '../../lib/firebase';
import * as ActionTypes from '../../data/state/action-types';
import { uploadImage } from '../AddItem/actions';
import { toggleIsLoading } from '../Loading/actions';
import { getUrlHost } from '../../utils';

const setStoreAction = (
  placeId,
  placeName,
  workingHoursText,
  imageSrc,
  verified,
  locationInfo,
  geohash,
  phoneNumber,
  address,
  hasOnlineOrdering,
  onlineOrderingSchedule,
  hasOwnDelivery,
  deliveryOptions,
  hasDunzoDelivery,
  urlHost,
  currency,
  theme,
  colors,
  primaryColor,
  secondaryColor,
  subscriptionType,
  localLanguages
) => ({
  type: ActionTypes.SET_STORE,
  placeId,
  placeName,
  workingHoursText,
  imageSrc,
  verified,
  locationInfo,
  geohash,
  phoneNumber,
  address,
  hasOnlineOrdering,
  onlineOrderingSchedule,
  hasOwnDelivery,
  deliveryOptions,
  hasDunzoDelivery,
  urlHost,
  currency,
  theme,
  colors,
  primaryColor,
  secondaryColor,
  subscriptionType,
  localLanguages
});

const createStore = async (
  dispatch,
  history,
  placeName,
  address,
  locationInfo,
  storeImgFile,
  phNo,
  urlHost,
  currency,
  colors
) => {
  toggleIsLoading(dispatch, true); // start spinning
  let imageSrc = '';
  if (storeImgFile !== null) {
    imageSrc = await uploadImage(storeImgFile);
  }
  const { lat, lng } = locationInfo;
  const geohash = geohashForLocation([lat, lng]);
  const phoneNumber = phNo ? phNo.substring(1) : null;
  // const address = locationInfo.geocodedAddress;
  const docRef = await addDoc(collection(db, 'places'), {
    placeName,
    address,
    geohash,
    createdBy: auth.currentUser.uid,
    createdAt: new Date(),
    imageSrc,
    locationInfo,
    phoneNumber,
    urlHost,
    currency,
    colors
  });
  toggleIsLoading(dispatch, false); // stop spinning
  console.log('Document written with ID: ', docRef.id);

  dispatch(
    setStoreAction(
      docRef.id,
      placeName,
      '',
      imageSrc,
      false,
      locationInfo,
      geohash,
      phoneNumber,
      address,
      true,
      {},
      false,
      [],
      false,
      urlHost,
      currency,
      'custom',
      colors,
      '',
      '',
      0,
      []
    )
  );

  history.push('/configure-website');
};

const validateUrlHost = async (urlPrefix) => {
  if (!urlPrefix || urlPrefix.trim() === '') {
    return '';
  }

  if (urlPrefix.trim().length < 2) {
    return 'invalid';
  }

  const urlHost = getUrlHost(urlPrefix);
  try {
    const q = query(collection(db, 'places'), where('urlHost', '==', urlHost));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      const q2 = query(collection(db, 'places'), where('urlHost2', '==', urlHost));
      const querySnapshot2 = await getDocs(q2);
      if (querySnapshot2.empty) {
        return 'valid';
      } else {
        return 'invalid';
      }
    } else {
      return 'invalid';
    }
  } catch (error) {}
};

export { createStore, setStoreAction, validateUrlHost };
