import React, { useContext, useState, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import { StateContext } from '../../../data/state/state-context';
import { updateTheme } from '../actions';
import ColorPicker from './ColorPicker';

const ConfigureWebsiteForm = ({ onContinue }) => {
  const [rootState, dispatch] = useContext(StateContext);
  const { placeId, colors, theme, primaryColor, secondaryColor, urlHost } = rootState.main;
  const [predefined, setPredefined] = useState(false);
  const [primaryColorPicker, showPrimaryColorPicker] = useState(false);
  const [secondaryColorPicker, showSecondaryColorPicker] = useState(false);
  const [otherPrimary, setOtherPrimary] = useState('#000000');
  const [otherSecondary, setOtherSecondary] = useState('#000000');

  const onUpdateTheme = (placeId, theme, primaryColor, secondaryColor) =>
    updateTheme(dispatch, placeId, theme, primaryColor, secondaryColor);

  useEffect(() => {
    setPredefined(theme !== 'custom');
    if (primaryColor && colors.includes(primaryColor) === false) {
      setOtherPrimary(primaryColor);
    }
    if (secondaryColor && colors.includes(secondaryColor) === false) {
      setOtherSecondary(secondaryColor);
    }
  }, [colors, theme, primaryColor, secondaryColor]);

  return (
    <form className="needs-validation" noValidate>
      <h3>Configure Your Store's Website</h3>
      {predefined !== true && colors?.length > 0 && (
        <>
          <p>
            Choose a primary and secondary color for your website. Based on the logo/image you
            uploaded we recommend choosing from the following colors
          </p>
          <div className="mb-3">
            <label htmlFor="create-store-name">Primary color</label>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              {colors.map((color, id) => (
                <div
                  key={id}
                  onClick={() => onUpdateTheme(placeId, 'custom', color, secondaryColor)}
                  style={{
                    backgroundColor: color,
                    width: 100,
                    height: 100
                  }}>
                  {primaryColor === color ? (
                    <i class="fa fa-check fa-lg mt-2 ml-2 text-white" aria-hidden="true" />
                  ) : (
                    <></>
                  )}
                </div>
              ))}
              <div
                onClick={() => showPrimaryColorPicker(true)}
                style={{
                  backgroundColor: otherPrimary,
                  width: 100,
                  height: 100
                }}>
                {primaryColor === otherPrimary ? (
                  <i className="fa fa-check fa-lg mt-2 ml-2 text-white" aria-hidden="true" />
                ) : (
                  <></>
                )}
                <span className="mt-2 ml-2 text-white">Other</span>
              </div>
            </div>
            <ColorPicker
              show={primaryColorPicker}
              onChangeComplete={({ hex }) => {
                setOtherPrimary(hex);
                onUpdateTheme(placeId, 'custom', hex, secondaryColor);
                showPrimaryColorPicker(false);
              }}
              handleClose={() => showPrimaryColorPicker(false)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="create-store-name">Secondary color</label>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              {colors.map((color, id) => (
                <div
                  key={id}
                  onClick={() => onUpdateTheme(placeId, 'custom', primaryColor, color)}
                  style={{
                    backgroundColor: color,
                    width: 100,
                    height: 100
                  }}>
                  {secondaryColor === color ? (
                    <i class="fa fa-check fa-lg mt-2 ml-2 text-white" aria-hidden="true" />
                  ) : (
                    <></>
                  )}
                </div>
              ))}
              <div
                onClick={() => showSecondaryColorPicker(true)}
                style={{
                  backgroundColor: otherSecondary,
                  width: 100,
                  height: 100
                }}>
                {secondaryColor === otherSecondary ? (
                  <i className="fa fa-check fa-lg mt-2 ml-2 text-white" aria-hidden="true" />
                ) : (
                  <></>
                )}
                <span className="mt-2 ml-2 text-white">Other</span>
              </div>
            </div>
            <ColorPicker
              show={secondaryColorPicker}
              onChangeComplete={({ hex }) => {
                setOtherSecondary(hex);
                onUpdateTheme(placeId, 'custom', primaryColor, hex);
                showSecondaryColorPicker(false);
              }}
              handleClose={() => showSecondaryColorPicker(false)}
            />
          </div>
        </>
      )}

      <div className="mb-3">
        <div className="d-md-inline-flex">
          <span>Use a pre-defined theme</span>
          <label className="ms-switch ml-4">
            <input
              type="checkbox"
              checked={predefined}
              defaultChecked={false}
              onClick={(e) => {
                setPredefined(e.target.checked);
                if (e.target.checked !== true) {
                  onUpdateTheme(placeId, 'custom', primaryColor, secondaryColor);
                }
              }}
            />
            <span className="ms-switch-slider ms-switch-success round" />
          </label>
        </div>
      </div>
      {predefined === true && (
        <div className="mb-3">
          <div className="row">
            <div className="col">
              <div class="form-check">
                <label class="form-check-label" for="theme-default">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value="default"
                    id="theme-default"
                    checked={theme === 'default'}
                    onChange={() => onUpdateTheme(placeId, 'default', primaryColor, secondaryColor)}
                  />
                  Default
                  <img src="/assets/img/themes/default.png" alt="default" />
                </label>
              </div>
            </div>
            <div className="col">
              <div class="form-check">
                <label class="form-check-label" for="theme-red">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value="red"
                    id="theme-red"
                    checked={theme === 'red'}
                    onChange={() => onUpdateTheme(placeId, 'red', primaryColor, secondaryColor)}
                  />
                  Red
                  <img src="/assets/img/themes/red.png" alt="red" />
                </label>
              </div>
            </div>
            <div className="col">
              <div class="form-check">
                <label class="form-check-label" for="theme-green">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value="green"
                    id="theme-green"
                    checked={theme === 'green'}
                    onChange={() => onUpdateTheme(placeId, 'green', primaryColor, secondaryColor)}
                  />
                  Green
                  <img src="/assets/img/themes/green.png" alt="green" />
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      <a
        class="btn btn-info mt-4 d-block w-100"
        href={`https://${urlHost}`}
        target="_blank"
        rel="noopener noreferrer"
        role="button">
        Preview Website
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-box-arrow-up-right ml-3"
          viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
          />
          <path
            fill-rule="evenodd"
            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
          />
        </svg>
      </a>
      {!!onContinue && (
        <button className="btn btn-primary mt-4 d-block w-100" onClick={onContinue}>
          Continue
        </button>
      )}
    </form>
  );
};

export default ConfigureWebsiteForm;
