import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Content from './Content';
import { StateContext } from '../../../data/state/state-context';

const Defaultregister = () => {
  const [, dispatch] = useContext(StateContext);
  const history = useHistory();
  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
        <main className="body-content">
            <Content dispatch={dispatch} history={history}/>
        </main>
    </div>
  );
}

export default Defaultregister;
