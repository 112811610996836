import React, { useContext } from 'react';

import { StateContext } from '../data/state/state-context';

import './loadingspinner.css';

const LoadingSpinner = ({ asOverlay }) => {
  const [rootState] = useContext(StateContext);
  const { isLoading } = rootState.main;

  return isLoading ? (
    <div className={`${asOverlay && "loading-spinner__overlay"}`}>
      <div className="lds-dual-ring"></div>
    </div>
  ) : null;
};

export default LoadingSpinner;
