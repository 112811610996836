import React from 'react';

import { currencies } from '../../../data/constants';

const CurrencySelect = ({ selectedId, onSelect, ...props }) => (
  <select
    {...props}
    id="storecurrency"
    className="form-control"
    required
    value={selectedId}
    onChange={(e) => onSelect(e.target.value)}>
    {currencies.map((currency, idx) => (
      <option key={idx} value={currency.id}>
        {currency.name}
      </option>
    ))}
  </select>
);

export default CurrencySelect;
