import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-phone-number-input/style.css';
import ModalVideo from 'react-modal-video';
import i18n, { languages } from '../../../i18n';
import { onChangeLanguage } from '../../EditStore/actions';
import { StateContext } from '../../../data/state/state-context';

function Jumbotron({ setPage }) {
  return (
    <div
      className="jumbotron jumbotron-fluid w-100 bg-black"
      style={{
        paddingBottom: '10rem',
        textAlign: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '10rem'
      }}>
      <div className="px-5">
        <h1 className="display-4 text-light" style={{ fontWeight: 700 }}>
          {i18n.t('landing_commission_free:main_header')}
        </h1>
        <p className="lead text-light">{i18n.t('landing_commission_free:sub_header')}</p>
        <p className="lead">
          <button className="btn btn-primary btn-lg" onClick={() => setPage('create-basic')}>
            {i18n.t('landing_commission_free:create_online_store')}
          </button>
        </p>
      </div>
    </div>
  );
}

const ProductFeatures = ({ setPage }) => {
  const [rootState, dispatch] = useContext(StateContext);
  const [videoOpen, setVideoOpen] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const { lng } = rootState.main;

  useEffect(() => {
    const _lng = searchParams.get('lng') ? searchParams.get('lng').trim() : '';
    if (_lng && languages.indexOf(_lng) !== -1) {
      i18n.changeLanguage(_lng).then(() => {
        if (lng !== _lng) {
          onChangeLanguage(dispatch, _lng);
        }
      });
    }
  }, [dispatch, lng, searchParams]);

  return (
    <div className="ms-content-wrapper ms-auth">
      <main role="main">
        <Jumbotron setPage={setPage} />
        <div className="container marketing">
          <div className="row featurette">
            <div className="col-md-6">
              <h2
                className="featurette-heading"
                style={{
                  fontSize: '2.6rem',
                  fontWeight: 700,
                  lineHeight: 1.2
                }}>
                {i18n.t('landing_commission_free:section1_header')}
              </h2>
              <p
                className="lead"
                style={{
                  marginTop: '1rem',
                  marginRight: '3rem'
                }}>
                {i18n.t('landing_commission_free:section1_content')}
              </p>
            </div>
            <div className="col-md-6 mt-auto">
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={videoOpen}
                videoId={lng === 'ta' ? 'jt3CTyZHxPg' : 'fzK0Ph5cqTA'}
                onClose={() => setVideoOpen(false)}
              />
              <img
                className="rounded cursor-pointer"
                src={encodeURI('https://m.qrmeu.com/assets/landing-video-thumbnail.jpg')}
                alt="video"
                onClick={() => setVideoOpen(true)}
              />
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row mb-4">
            <div className="col-lg-4">
              <div style={{ minHeight: 160 }}>
                <img
                  style={{ maxHeight: 160, maxWidth: 160, margin: 20 }}
                  src={encodeURI('https://m.qrmeu.com/assets/e-invoice-icon.webp')}
                  alt="e-invoice"
                />
              </div>
              <h2>E-commerce Website</h2>
              <p>
                With FerryPal, you can create an online store for your restaurant where customers
                can browse your menu and place orders directly from your website. This makes it easy
                for customers to order from you, and it allows you to reach more people and increase
                sales.
              </p>
            </div>
            <div className="col-lg-4">
              <div style={{ minHeight: 160 }}>
                <img
                  style={{ maxHeight: 100, maxWidth: 100, margin: 20 }}
                  src={encodeURI('https://m.qrmeu.com/assets/offer-icon.webp')}
                  alt="e-invoice"
                />
              </div>
              <h2>Discounts/Promotions</h2>
              <p>
                FerryPal allows you to offer discounts and promotions to your customers. This can
                help you attract new customers and encourage repeat business from loyal customers.
              </p>
            </div>
            <div className="col-lg-4">
              <div style={{ minHeight: 160 }}>
                <img
                  style={{ maxHeight: 120, maxWidth: 120, margin: 20 }}
                  src={encodeURI('https://m.qrmeu.com/assets/food-delivery-icon.webp')}
                  alt="e-invoice"
                />
              </div>
              <h2>Self Pickup/Delivery</h2>
              <p>
                FerryPal allows your customers to choose whether they want to pick up their order
                from your restaurant or have it delivered to them. This can help you provide a more
                convenient experience for your customers and can increase your sales.
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-4">
              <div style={{ minHeight: 160 }}>
                <img
                  style={{ maxHeight: 100, maxWidth: 100, margin: 20 }}
                  src={encodeURI('https://m.qrmeu.com/assets/pos-terminal-icon.webp')}
                  alt="e-invoice"
                />
              </div>
              <h2>POS</h2>
              <p>
                FerryPal includes a point-of-sale system that allows you to easily manage orders,
                payments, and other aspects of your restaurant's operations. This can help you run
                your business more efficiently and effectively.
              </p>
            </div>
            <div className="col-lg-4">
              <div style={{ minHeight: 160 }}>
                <img
                  style={{ maxHeight: 100, maxWidth: 100, margin: 20 }}
                  src={encodeURI('https://m.qrmeu.com/assets/cashback-usd-icon.webp')}
                  alt="e-invoice"
                />
              </div>
              <h2>Instant Payments</h2>
              <p>
                With FerryPal, you can receive payments from customers instantly. This can help you
                manage your cash flow and ensure that you get paid quickly and easily.
              </p>
            </div>
            <div className="col-lg-4">
              <div style={{ minHeight: 160 }}>
                <img
                  style={{ maxHeight: 100, maxWidth: 100, margin: 20 }}
                  src={encodeURI('https://m.qrmeu.com/assets/number-zero-icon.png')}
                  alt="e-invoice"
                />
              </div>
              <h2>0% Comission</h2>
              <p>
                One of the key benefits of FerryPal is that we do not charge any commission on your
                sales. This means that you can keep more of the revenue your restaurant earns and
                focus on growing your business.
              </p>
            </div>
          </div>
        </div>
      </main>

      <div className="jumbotron jumbotron-fluid bg-black">
        <div className="container text-center">
          <p className="lead text-light">{i18n.t('landing_commission_free:closing_header')}</p>
          <p className="lead">
            <button className="btn btn-primary btn-lg" onClick={() => setPage('create-basic')}>
              {i18n.t('landing_commission_free:create_online_store')}
            </button>
          </p>
          {lng && lng !== 'en' && (
            <p>
              <a className="text-light" href="/s/create?lng=en">
                Switch to English
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductFeatures;
