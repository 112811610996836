import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { StateContext } from '../../../data/state/state-context';
import {
  loadOrders,
  orderAccepted,
  orderPickedUp,
  orderRejected,
  setOrderItemReady,
  setExpectedOrderReadyTime
} from '../actions';

const DEFAULT_READY_IN_MIN = 20;
const debounceSetExpectedOrderReadyTime = debounce(setExpectedOrderReadyTime, 5000);

const StatusBadge = ({ orderReady }) =>
  orderReady === true ? (
    <span className="badge badge-success">Ready</span>
  ) : (
    <span className="badge badge-primary">Pending</span>
  );

const ActionButton = ({ orderReady, disabled, onClick }) =>
  orderReady === true ? (
    <button
      onClick={onClick}
      type="button"
      disabled={disabled}
      className="btn btn-in-list btn-secondary">
      Reset
    </button>
  ) : (
    <button
      onClick={onClick}
      type="button"
      disabled={disabled}
      className="btn btn-in-list btn-success">
      Ready
    </button>
  );

const OrderItem = ({ tripId, createdAt, canUpdate, pickedUp, orderItem, setReady }) => {
  const price = Number(orderItem.price);
  const quantity = Number(orderItem.quantity);
  return (
    <tr id={orderItem.orderItemId} data-trip-id={tripId}>
      <td>{moment(createdAt).fromNow()}</td>
      <td>
        <span style={{ fontWeight: 'bold' }}>{orderItem.itemName}</span>
        <br />
        <span style={{ fontStyle: 'italic', fontSize: '0.8em', whiteSpace: 'pre-wrap' }}>
          {orderItem.notes}
        </span>
      </td>
      <td>{quantity}</td>
      <td>{price.toFixed(2)}</td>
      <td>{(quantity * price).toFixed(2)}</td>
      {canUpdate ? (
        <>
          <td>
            <StatusBadge orderReady={orderItem.orderReady} />
          </td>
          <td>
            <ActionButton
              orderReady={orderItem.orderReady}
              disabled={pickedUp}
              onClick={() => setReady(tripId, orderItem.orderItemId, !orderItem.orderReady)}
            />
          </td>
        </>
      ) : (
        <td colSpan="2"></td>
      )}
    </tr>
  );
};

const SubOrder = ({
  tripId,
  createdAt,
  canUpdate,
  pickedUp,
  notes,
  setReady,
  orderFor,
  orderItems
}) => {
  const orderTotal = useMemo(() => {
    return orderItems.reduce(
      (prevTotal, oi) => prevTotal + Number(oi.quantity) * Number(oi.price),
      0
    );
  }, [orderItems]);

  return (
    <>
      <tr className="bg-secondary text-white">
        <th scope="col" colSpan="4">
          {`Order for: ${orderFor}`}
          <br />
          <span
            style={{
              fontWeight: 'normal',
              color: '#fff',
              fontSize: '0.8em',
              whiteSpace: 'pre-wrap'
            }}>
            Notes: {notes}
          </span>
        </th>
        <th scope="col" colSpan="3">{`Total: ${orderTotal.toFixed(2)}`}</th>
      </tr>
      <tr className="bg-light text-white">
        <th scope="col">Order Time</th>
        <th scope="col">Item</th>
        <th scope="col">Quantity</th>
        <th scope="col">Price</th>
        <th scope="col">Amount</th>
        <th scope="col" colSpan="2">
          Order Status
        </th>
      </tr>
      {orderItems.map((oi) => {
        return (
          <OrderItem
            key={oi.orderItemId}
            tripId={tripId}
            createdAt={createdAt}
            canUpdate={canUpdate}
            pickedUp={pickedUp}
            orderItem={oi}
            setReady={setReady}
          />
        );
      })}
    </>
  );
};

const AcceptRejectWithTimeout = ({
  dispatch,
  tripId,
  acceptedByPlaceAt,
  rejectedByPlaceAt,
  expectedOrderReadyTime
}) => {
  const [disabled, setDisabled] = useState(!!acceptedByPlaceAt || !!rejectedByPlaceAt);
  const [timeoutId, setTimeoutId] = useState(0);
  const [pendingAccept, setPendingAccept] = useState(false);
  const [pendingReject, setPendingReject] = useState(false);
  const [readyTime, setReadyTime] = useState('');

  const updateAfter5Seconds = (updateStatus) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // disable button and update db after giving 5 seconds for user to fix accidental click
    setTimeoutId(
      setTimeout(() => {
        setDisabled(true);
        updateStatus();
      }, 5 * 1000)
    );
  };
  const proposeReadyTime = () => {
    const d = new Date();
    let h = d.getHours(),
      m = d.getMinutes() + DEFAULT_READY_IN_MIN;
    if (m >= 60) {
      m -= 60;
      h++;
      if (h > 23) {
        h = 0;
      }
    }
    h = (h < 10 ? '0' : '') + h;
    m = (m < 10 ? '0' : '') + m;
    setReadyTime(h + ':' + m);
  };
  const onToggleAccept = () => {
    proposeReadyTime();
    setPendingAccept(true);
    setPendingReject(false);
    const rt = new Date();
    rt.setMinutes(rt.getMinutes() + DEFAULT_READY_IN_MIN);
    updateAfter5Seconds(() => orderAccepted(dispatch, tripId, rt));
  };
  const onToggleReject = () => {
    setPendingAccept(false);
    setPendingReject(true);
    updateAfter5Seconds(() => orderRejected(dispatch, tripId));
  };
  const onReadyTimeChange = (event) => {
    const timeString = event.target.value;
    setReadyTime(timeString);

    const rt = new Date();
    rt.setHours(Number(timeString.split(':')[0]));
    rt.setMinutes(Number(timeString.split(':')[1]));
    debounceSetExpectedOrderReadyTime(dispatch, tripId, rt);
  };

  useEffect(() => {
    if (expectedOrderReadyTime) {
      // BANDAGE start
      let ert = expectedOrderReadyTime;
      if (typeof ert !== 'object') {
        // This can happen from redux persist - which converts evertything to string
        // We need a fix for the root issue - where we restore from redux persist (TODO)
        if (new Date(ert)) {
          ert = new Date(ert);
        }
      }
      // BANDAGE end
      let h = ert.getHours(),
        m = ert.getMinutes();
      h = (h < 10 ? '0' : '') + h;
      m = (m < 10 ? '0' : '') + m;
      setReadyTime(h + ':' + m);
    }
  }, [expectedOrderReadyTime]);

  if (!!acceptedByPlaceAt) {
    return (
      <div className="form-group col-md-12 col-xl-8">
        <label for="ready-time">Order will be ready at:</label>
        <input
          className="form-control"
          id="ready-time"
          type="time"
          name="ready-time"
          value={readyTime}
          onChange={onReadyTimeChange}
        />
      </div>
    );
  }

  // TODO: do we need the state `disabled`?
  // Can't we use acceptedByPlaceAt and rejectedByPlaceAt to get the same behavior below?
  if (pendingAccept) {
    return (
      <>
        <button
          onClick={onToggleAccept}
          type="button"
          disabled={disabled}
          className="btn btn-in-list btn-light mx-1">
          Accepted
        </button>
        {!disabled && (
          <button
            onClick={onToggleReject}
            type="button"
            className="btn btn-in-list btn-secondary mx-1">
            Reject
          </button>
        )}
      </>
    );
  }

  if (pendingReject || !!rejectedByPlaceAt) {
    return (
      <>
        {!disabled && (
          <button
            onClick={onToggleAccept}
            type="button"
            className="btn btn-in-list btn-success mx-1">
            Accept
          </button>
        )}
        <button
          onClick={onToggleReject}
          type="button"
          disabled={disabled}
          className="btn btn-in-list btn-light mx-1">
          Rejected
        </button>
      </>
    );
  }

  return (
    <>
      <button onClick={onToggleAccept} type="button" className="btn btn-in-list btn-success mx-1">
        Accept
      </button>
      <button onClick={onToggleReject} type="button" className="btn btn-in-list btn-secondary mx-1">
        Reject
      </button>
    </>
  );
};

const requestedTimeString = (requestedTime) => {
  if (!requestedTime) {
    return 'No time provided';
  }

  if (requestedTime === 'ASAP') {
    return 'ASAP';
  }

  return moment(requestedTime).calendar();
};

const PickupDetails = ({ trip }) => {
  const { orderPickup } = trip;
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm">{`Pick up by: ${trip.orderCreatedBy} `}</div>
        <div className="col-sm">
          <a className="text-white mr-4" href={`tel:${trip.orderCreatedByPhone}`}>
            <i className="fas fa-phone fs-16" />
            {trip.orderCreatedByPhone}
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          {`Requested time: ${requestedTimeString(orderPickup?.requestedTime)}`}
        </div>
      </div>
    </div>
  );
};

const DeliveryDetails = ({ trip }) => {
  const { orderDelivery } = trip;
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm">{`Deliver to: ${trip.orderCreatedBy}`}</div>
        <div className="col-sm">
          <a className="text-white mr-4" href={`tel:${trip.orderCreatedByPhone}`}>
            <i className="fas fa-phone fs-16" />
            {trip.orderCreatedByPhone}
          </a>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-sm">
          {`Distance: ${orderDelivery?.distance}`}
        </div> */}
        <div className="col-sm">
          {`Requested time: ${requestedTimeString(orderDelivery?.requestedTime)}`}
        </div>
      </div>
      <div className="row">
        <div className="col-sm">{`Address: ${orderDelivery?.address}`}</div>
      </div>
    </div>
  );
};

const OrderGroup = ({ trip }) => {
  const [rootState, dispatch] = useContext(StateContext);
  const { hasDunzoDelivery } = rootState.main;
  const {
    tripId,
    createdAt,
    notes,
    acceptedByPlaceAt,
    rejectedByPlaceAt,
    expectedOrderReadyTime,
    pickedUpAt,
    subOrders,
    isOrderDelivery
  } = trip;
  const [pickedUp, setPickedUp] = useState(!!pickedUpAt);
  const [collapsed, setCollapsed] = useState(pickedUp);
  const onOrderPickedUp = (e) => {
    e.stopPropagation();
    setCollapsed(!pickedUp);
    orderPickedUp(dispatch, tripId, !pickedUp);
  };
  const readyCounter = useRef(0);
  const setReady = (tripId1, orderItemId, isReady) => {
    readyCounter.current++;
    setOrderItemReady(dispatch, tripId1, orderItemId, isReady);
  };
  const orderReady = useMemo(() => {
    const orderItemsStatus = Object.entries(subOrders).map(([, orderItems]) => {
      return orderItems.every((oi) => oi.orderReady === true);
    });
    return orderItemsStatus.every((ready) => ready === true);
  }, [subOrders, readyCounter.current]);

  useEffect(() => {
    setPickedUp(!!pickedUpAt);
  }, [pickedUpAt]);

  return (
    <>
      <tr className={pickedUp === true ? 'bg-light text-white' : 'bg-primary text-white'}>
        <td>
          <AcceptRejectWithTimeout
            dispatch={dispatch}
            tripId={tripId}
            acceptedByPlaceAt={acceptedByPlaceAt}
            rejectedByPlaceAt={rejectedByPlaceAt}
            expectedOrderReadyTime={expectedOrderReadyTime}
          />
        </td>
        <td colSpan="3">
          {isOrderDelivery === true ? (
            <DeliveryDetails trip={trip} />
          ) : (
            <PickupDetails trip={trip} />
          )}
        </td>
        <td colSpan="3" style={{ textAlign: 'end' }}>
          {orderReady === true ? (
            <div onClick={() => setCollapsed(!collapsed)}>
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={pickedUp} onClick={onOrderPickedUp} />{' '}
                <span className="ms-switch-slider ms-switch-success round" />
              </label>
              <span className="text-white">
                {isOrderDelivery === true ? 'Out for delivery' : 'Picked up'}
              </span>
            </div>
          ) : (
            isOrderDelivery === true &&
            hasDunzoDelivery === true && (
              <button
                onClick={() => {
                  /* TODO: popup a dlg to confirm time, and call croquet api */
                }}
                type="button"
                disabled={false}
                className="btn btn-in-list btn-success">
                Schedule Dunzo Pickup
              </button>
            )
          )}
        </td>
      </tr>
      {!collapsed &&
        Object.entries(subOrders).map(([orderFor, orderItems]) => (
          <SubOrder
            key={orderFor}
            tripId={tripId}
            createdAt={createdAt}
            canUpdate={!!acceptedByPlaceAt}
            pickedUp={pickedUp}
            notes={notes}
            setReady={setReady}
            orderFor={orderFor}
            orderItems={orderItems}
          />
        ))}
    </>
  );
};

const OrdersList = ({ trips }) => (
  <div className="table-responsive">
    <table className="table table-hover thead-primary">
      <tbody>
        {trips.map((trip) => (
          <OrderGroup trip={trip} key={trip.tripId} />
        ))}
      </tbody>
    </table>
  </div>
);

const NoOrders = () => <p className="h6 text-center py-3">No Orders to show here</p>;

const Ordertable = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { placeId } = rootState.main;
  const { trips } = rootState.orders;
  const { items } = rootState.items;
  useEffect(() => {
    loadOrders(dispatch, placeId, trips, items);
  }, [items]);

  return (
    <div className="col-12">
      <div className="ms-panel">
        <div className="ms-panel-header">
          <h6> Order List</h6>
        </div>
        <div className="ms-panel-body px-1 py-1">
          {Array.isArray(trips) && trips.length > 0 ? <OrdersList trips={trips} /> : <NoOrders />}
        </div>
      </div>
    </div>
  );
};

export default Ordertable;
