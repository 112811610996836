import React from 'react';
import Content from './Content';

const ContentSection = () => (
  <div className=" ms-panel ms-panel-fh">
    <div className="row">
      <div className="col">
        <div className="d-flex justify-content-center flex-column">
          <Content />
        </div>
      </div>
    </div>
  </div>
);

const StoreSubscription = () => {
  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
      <main className="body-content">
        <ContentSection />
      </main>
    </div>
  );
};

export default StoreSubscription;
