import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Editproductcontent from './Editproductcontent';
import Quickbar from '../../../components/layouts/Quickbar';
import LoadingSpinner from '../../../shared/loadingspinner';

const Editproduct = () => {
  const location = useLocation();
  let itemInfo = {};
  if (location && location.state && location.state.item) {
    itemInfo = { ...location.state.item };
  }

  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <Editproductcontent itemInfo={itemInfo} />
        <LoadingSpinner asOverlay />
      </main>
      <Quickbar />
    </div>
  );
};

export default Editproduct;
