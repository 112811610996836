import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { StateContext } from '../../../data/state/state-context';
import { deleteItem, setItemAvailability, setItemSpecial } from '../actions';

const Listcontent = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { items, categories } = rootState.items;
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Items List</h6>
            </div>
            <div className="ms-panel-body px-1 py-1">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Available</th>
                      <th scope="col">Our Special</th>
                      <th scope="col">Item Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Category</th>
                      <th scope="col">Edit / Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <label className="ms-switch">
                            <input
                              type="checkbox"
                              defaultChecked={item.available}
                              onClick={() =>
                                setItemAvailability(dispatch, item.itemId, !item.available)
                              }
                            />
                            <span className="ms-switch-slider ms-switch-success round" />
                          </label>
                        </td>
                        <td>
                          <label className="ms-switch">
                            <input
                              type="checkbox"
                              defaultChecked={item.ourSpecial}
                              onClick={() =>
                                setItemSpecial(dispatch, item.itemId, !item.ourSpecial)
                              }
                            />
                            <span className="ms-switch-slider ms-switch-success round" />
                          </label>
                        </td>
                        <td>
                          <div
                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                              <img
                                src={item.imageSrc}
                                style={{ width: 50, height: 30 }}
                                alt={item.itemName}
                              />
                            </div>
                            <div className="text-wrap">
                              <span>{item.itemName}</span>
                              <br />
                              <span className="small">{item.description}</span>
                            </div>
                          </div>
                        </td>
                        <td>{parseFloat(item.price).toFixed(2)}</td>
                        <td>
                          <span className="text-capitalize">
                            {categories.find(({ id }) => id === item.category)?.name}
                          </span>
                        </td>
                        <td style={{ display: 'flex' }}>
                          <Link to={{ pathname: '/edit-product', state: { item } }}>
                            <i className="fas fa-pencil-alt text-secondary" />
                          </Link>
                          <div
                            onClick={() => {
                              const confirmBox = window.confirm(`Delete '${item.itemName}'?`);
                              if (confirmBox === true) {
                                deleteItem(dispatch, item.itemId, item.imageSrc);
                              }
                            }}>
                            <i className="far fa-trash-alt ms-text-danger" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listcontent;
