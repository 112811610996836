import React, { useState } from 'react';
import PDFOfSmartOrder from './PDFOfSmartOrder.js';
import './SmartOrderCard.css';

const preview = encodeURI('https://m.qrmeu.com/assets/Vector-black.png');

const Content = ({ cardImages }) => {
  const [selectedMenu, setSelectedMenu] = useState(-1);
  return (
    <div className="ms-content-wrapper ms-panel col-md-12  text-center ">
      <h3>Smart Order Card</h3>
      <div className="cont ">
        {cardImages.map((image, index) => (
          <div className=" m-3 cardrow ">
            <img className="cardrowimg" src={image.demo} alt="demo-table-card" />
            <div
              className=" d-flex justify-content-center  "
              style={{ backgroundColor: '#ff8306' }}>
              <h4 style={{ color: '#ffffff' }} className="mt-1 bold align-content-center">
                Smart Order Card
              </h4>
            </div>

            <div className=" cardrowdesc ">
              <div className=" descrow-para mr-3 ml-3 mt-3">
                <p style={{ color: '#ffffff', textAlign: 'justify' }}>
                  First Click on the choose the design whichever design you would like to download
                  accordingly and then hit the download button
                </p>
              </div>
              <div className="mb-3">
                {index === selectedMenu ? (
                  <PDFOfSmartOrder cardImage={image.data} cardQrImage={image.orderQR} />
                ) : (
                  <button
                    onClick={() => setSelectedMenu(index)}
                    type="button"
                    class="btn btn-white text-dark border-light rounded m-3"
                    style={{ textAlign: 'center', width: '230px' }}>
                    Choose Card
                    <img src={preview} alt="prview" className="ml-5" />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Content;
