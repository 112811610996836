import React, { useMemo } from 'react';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const StoreDetail = ({
  onClick,
  selected,
  storeName,
  storeCategory,
  storeLocation,
  itemCategories
}) => {
  const itemCount = useMemo(
    () =>
      itemCategories
        ? itemCategories.reduce((total, currCateg) => (currCateg?.items?.length || 0) + total, 0)
        : 0,
    [itemCategories]
  );
  return (
    <li
      className={`ms-chat-user-container ms-open-chat ms-deletable p-3 media clearfix ${
        selected ? 'selected' : ''
      }`}
      onClick={onClick}>
      <div className="media-body ms-chat-user-info mt-1">
        <h6>{storeName}</h6> <span className="ms-chat-time">Menu items: {itemCount}</span>
        <p>{storeLocation}</p>
      </div>
    </li>
  );
};

const OnlineStores = ({ storesDetails, selectedStore, setSelectedStore }) => {
  return (
    <div className="ms-panel">
      <div className="ms-panel-body py-3 px-0">
        <div className="ms-chat-container">
          <div className="ms-chat-body">
            <Scrollbar className="ms-scrollable">
              <ul>
                {storesDetails.map(
                  ({ storeName, storeCategory, storeLocation, itemCategories }, index) => (
                    <StoreDetail
                      key={index}
                      selected={selectedStore === index}
                      storeName={storeName}
                      storeCategory={storeCategory}
                      storeLocation={storeLocation}
                      itemCategories={itemCategories}
                      onClick={() => {
                        setSelectedStore(index);
                      }}
                    />
                  )
                )}
                <li
                  className={`ms-chat-user-container ms-open-chat ms-deletable p-3 media clearfix ${
                    selectedStore === 'manual' ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedStore('manual')}>
                  <div className="media-body ms-chat-user-info mt-1">
                    <h6>None of the above (Update manually)</h6>{' '}
                    <span className="ms-chat-time"></span>
                    <p>
                      If none of the listed stores match with your store, then you can search for it
                      with specific keywords. If no luck even after search, click here to manually
                      enter the details.
                    </p>
                  </div>
                </li>
              </ul>
            </Scrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineStores;
