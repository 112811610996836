import React from 'react';
import { useHistory } from 'react-router-dom';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Content from './Content';
import LoadingSpinner from '../../../shared/loadingspinner';

const ConfigureWebsite = () => {
  const history = useHistory();
  const onContinue = () => history.push("/store-subscribe");

  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
        <Sidenavigation />
        <main className="body-content">
            <Content onContinue={onContinue} />
            <LoadingSpinner asOverlay />
        </main>
    </div>
  );
}

export default ConfigureWebsite;
