import React, { useState } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../lib/firebase';
import PDFMenu from '../../PrintableMenu/components/PDFMenu';

const Content = ({ coverImages, filter, setFilter }) => {
  const [selectedMenu, setSelectedMenu] = useState(-1);
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="jumbotron jumbotron-fluid w-100 bg-black mt-5 mb-0">
          <div className="px-5">
            <h1 className="display-4 text-light">Ready To Print Menu</h1>
            <p className="lead text-light ml-2">
              Choose your design and download.
              <br />
              Don't waste time on data entry. Let our AI do the hardwork!
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col ms-panel text-center p-4">
          <div className="w-100 text-left px-4">
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                type="button"
                onClick={() => setFilter('popular')}
                className={`btn ${filter === 'popular' ? 'btn-primary' : 'btn-secondary'}`}>
                Popular
              </button>
              <button
                type="button"
                onClick={() => setFilter('latest')}
                className={`btn ${filter === 'latest' ? 'btn-primary' : 'btn-secondary'}`}>
                Latest
              </button>
              <button
                type="button"
                onClick={() => setFilter('all')}
                className={`btn ${filter === 'all' ? 'btn-primary' : 'btn-secondary'}`}>
                All
              </button>
            </div>
          </div>
          <div className="d-flex">
            <div className="row">
              {coverImages.map((image, index) => (
                <>
                  <div className="col-sm-12 col-md-6 col-lg-4 pt-5">
                    <div className="ms-card">
                      <img src={image.data} alt="Menu cover page" />
                      <div className="ms-card-body">
                        <div className="row">
                          <div className="col">
                            <h5 className="card-title">{image.title}</h5>
                            <p>{image.descr}</p>
                          </div>
                          <div className="col">
                            {index === selectedMenu ? (
                              <PDFMenu coverImage={image.data} />
                            ) : (
                              <button
                                onClick={() => {
                                  logEvent(analytics, 'choosing_design', {
                                    index,
                                    name: image.title
                                  });
                                  setSelectedMenu(index);
                                }}
                                type="button"
                                className="btn btn-secondary">
                                Choose This Design
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
