import React, { useContext, useState } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';

import { StateContext } from '../data/state/state-context';

const ProgressModal = () => {
  const [rootState] = useContext(StateContext);
  const { progressModalShow, progressModalPercentDone } = rootState.main;
  return (
    <Modal
      className="modal-min"
      show={progressModalShow}
      onHide={() => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body className="text-center">
        {false && (
          <button type="button" className="close" onClick={() => {}}>
            <span aria-hidden="true">×</span>
          </button>
        )}
        <i className="flaticon-paper-plane d-block" />
        <h1>Your online store is being created!</h1>
        <ProgressBar now={progressModalPercentDone} label={`${progressModalPercentDone}%`} />
        <p>
          Be ready to be surprised! We are setting up an online store for you, that will be fully
          operational in minutes.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default ProgressModal;
