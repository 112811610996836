import { useRef, useEffect, useReducer } from 'react';
import deepEqual from 'fast-deep-equal/es6';

// Given any value
// This hook will return the previous value
// Whenever the current value changes
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const usePersistedReducer = (reducer, initialState, storageKey) => {
  function init() {
    // BUG: Date objects will get stringified when the state is restored from persistant storage - NEED TO FIX
    // Note for dev: structural change to initialState during dev may require restarting the browser
    const stringState = sessionStorage.getItem(storageKey);
    if (stringState) {
      try {
        return JSON.parse(stringState);
      } catch (error) {
        return initialState;
      }
    } else {
      return initialState;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState, init);
  const prevState = usePrevious(state);

  useEffect(() => {
    const stateEqual = deepEqual(prevState, state);
    if (!stateEqual) {
      const stringifiedState = JSON.stringify(state);
      sessionStorage.setItem(storageKey, stringifiedState);
    }
  }, [state]);

  return [state, dispatch];
};

export { usePersistedReducer };
