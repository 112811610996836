import React from 'react';
import ReactDOM from 'react-dom';
import Root from './screens/Root';
// import * as serviceWorker from './serviceWorker';

// NOTE: uncomment next line to enable bootstrap js features (eg: collapse rows using bootstrap class/tags)
// import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

// Scss
import '../node_modules/react-modal-video/scss/modal-video.scss';

// Css
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/vendors/iconic-fonts/font-awesome/css/all.min.css';
import './assets/vendors/iconic-fonts/flat-icons/flaticon.css';
import './assets/vendors/iconic-fonts/cryptocoins/cryptocoins.css';
import './assets/vendors/iconic-fonts/cryptocoins/cryptocoins-colors.css';
import './assets/css/animate.min.css';
import './assets/css/style.css';

ReactDOM.render(<Root />, document.getElementById('foodtech'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
