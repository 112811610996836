import React from 'react';
import ferryPal from '../../../../public/assets/img/favicon.ico';

const TopnavigationEx = () => {
  return (
    <nav className="navbar py-2 bg-black justify-content-between">
      <a className="navbar-brand" href="/u/home" target="_PARENT">
        <img
          src={ferryPal}
          style={{ width: 30, height: 30, verticalAlign: 'middle' }}
          className="d-inline-block rounded-circle"
          alt=""
        />
        <span className="h4 p-2 align-middle text-white">FerryPal</span>
      </a>
    </nav>
  );
};

export default TopnavigationEx;
