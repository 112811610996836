import { collection, doc, query, where, updateDoc, onSnapshot } from 'firebase/firestore';

import { db } from '../../lib/firebase';
import * as ActionTypes from '../../data/state/action-types';
import { getRecords } from '../../lib/helper';

const appendOrderAction = (
  tripId,
  createdAt,
  userId,
  orderCreatedBy,
  orderCreatedByPhone,
  when,
  notes,
  acceptedByPlaceAt,
  rejectedByPlaceAt,
  expectedOrderReadyTime,
  pickedUpAt,
  isOrderDelivery,
  orderDelivery,
  orderPickup,
  subOrders
) => {
  return {
    type: ActionTypes.APPEND_ORDER,
    tripId,
    createdAt,
    userId,
    orderCreatedBy,
    orderCreatedByPhone,
    when,
    notes,
    acceptedByPlaceAt,
    rejectedByPlaceAt,
    expectedOrderReadyTime,
    pickedUpAt,
    isOrderDelivery,
    orderDelivery,
    orderPickup,
    subOrders
  };
};

const setOrderItemReadyAction = (tripId, orderItemId, isReady) => {
  return {
    type: ActionTypes.TOGGLE_ORDER_ITEM_READY,
    tripId,
    orderItemId,
    isReady
  };
};

const setOrderAcceptedAtAction = (tripId, expectedOrderReadyTime) => {
  return {
    type: ActionTypes.SET_ORDER_ACCEPTED_AT,
    tripId,
    expectedOrderReadyTime
  };
};

const setOrderRejectedAtAction = (tripId) => {
  return {
    type: ActionTypes.SET_ORDER_REJECTED_AT,
    tripId
  };
};

const setExpectedReadyTimeAction = (tripId, expectedOrderReadyTime) => {
  return {
    type: ActionTypes.SET_EXPECTED_READY_TIME,
    tripId,
    expectedOrderReadyTime
  };
};

const setOrderPickedUpAtAction = (tripId, pickedUpAt) => {
  return {
    type: ActionTypes.SET_ORDER_PICKED_UP_AT,
    tripId,
    pickedUpAt
  };
};

const orderInfo = async (orderItemIds, items) => {
  const orderItems = await getRecords('order_item', orderItemIds);
  return orderItems.map((oi) => {
    const { id: orderItemId, itemId, notes, quantity, price, orderReady } = oi;
    const { itemName } = items.find((i) => i.itemId === itemId) ?? {};
    return { orderItemId, itemId, itemName, notes, quantity, price, orderReady };
  });
};

const loadOrders = async (dispatch, placeId, trips, items) => {
  const q = query(
    collection(db, 'trips'),
    where('placeId', '==', placeId),
    where('orderItems', '!=', {}),
    where('pickedUpAt', '==', null)
  );

  // Using onSnapshot() to get notified on changes
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    // TODO: when we subscribe to changes, we should clear order list before appending
    querySnapshot.forEach(async (doc) => {
      // doc.data() is never undefined for query doc snapshots
      const tripId = doc.id;

      // check if the trip with above tripId exists in the trips array
      const existingTrip = trips.find((trip) => trip.tripId === tripId);
      if (existingTrip) {
        // TODO: for now we are only taking new trips; ignoring any changes to trip.
        // In future, we may want to compare the incoming trip and existing trip and see if there are changes and update
        return;
      }

      const {
        createdAt,
        userId,
        notifyList,
        orderItems,
        when,
        notes,
        acceptedByPlaceAt,
        rejectedByPlaceAt,
        expectedOrderReadyTime,
        pickedUpAt,
        isOrderDelivery,
        orderDelivery,
        orderPickup
      } = doc.data();

      if (rejectedByPlaceAt && createdAt.toDate() < new Date().setDate(new Date().getDate() - 1)) {
        // don't show rejected orders more than 1 day back
        return;
      }

      const [{ displayName: orderCreatedBy, phoneNumber: orderCreatedByPhone }] = await getRecords(
        'users',
        [userId]
      );
      const subOrders = {};
      for (const [key, value] of Object.entries(orderItems)) {
        const orderFor =
          key === 'self'
            ? orderCreatedBy
            : notifyList.find((nl) => nl.notifyId === key)?.notifyName;
        if (orderFor) {
          subOrders[orderFor] = await orderInfo(value, items);
        }
      }

      dispatch(
        appendOrderAction(
          tripId,
          createdAt.toDate(),
          userId,
          orderCreatedBy,
          orderCreatedByPhone,
          when.toDate(),
          notes,
          acceptedByPlaceAt,
          rejectedByPlaceAt,
          expectedOrderReadyTime?.toDate(),
          pickedUpAt,
          isOrderDelivery,
          orderDelivery,
          orderPickup,
          subOrders
        )
      );
    });
  });

  // return unsubscribe function to stop listening for changes when the component unmounts
  return unsubscribe;
};
const setOrderItemReady = (dispatch, tripId, orderItemId, isReady) => {
  const orderItemRef = doc(db, 'order_item', orderItemId);
  updateDoc(orderItemRef, {
    orderReady: isReady
  });

  dispatch(setOrderItemReadyAction(tripId, orderItemId, isReady));
};

const setExpectedOrderReadyTime = (dispatch, tripId, expectedOrderReadyTime) => {
  const tripRef = doc(db, 'trips', tripId);
  updateDoc(tripRef, {
    expectedOrderReadyTime // js Date object
  });

  dispatch(setExpectedReadyTimeAction(tripId, expectedOrderReadyTime));
};

const orderPickedUp = (dispatch, tripId, pickedUp) => {
  const tripRef = doc(db, 'trips', tripId);
  const pickedUpAt = pickedUp === true ? new Date() : null;
  updateDoc(tripRef, {
    pickedUpAt
  });

  dispatch(setOrderPickedUpAtAction(tripId, pickedUpAt));
};

const orderAccepted = (dispatch, tripId, expectedOrderReadyTime) => {
  const tripRef = doc(db, 'trips', tripId);
  updateDoc(tripRef, {
    acceptedByPlaceAt: new Date(),
    rejectedByPlaceAt: null,
    expectedOrderReadyTime
  });

  dispatch(setOrderAcceptedAtAction(tripId, expectedOrderReadyTime));
};

const orderRejected = (dispatch, tripId) => {
  const tripRef = doc(db, 'trips', tripId);
  updateDoc(tripRef, {
    acceptedByPlaceAt: null,
    rejectedByPlaceAt: new Date()
  });

  dispatch(setOrderRejectedAtAction(tripId));
};

export {
  loadOrders,
  setOrderItemReady,
  orderAccepted,
  orderRejected,
  orderPickedUp,
  setExpectedOrderReadyTime
};
