import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PageOfTableSticker from './PageOfTableSticker';

const download = encodeURI('https://m.qrmeu.com/assets/Vector-white.png');

// Create Document Component
const PDFOfMergeImages = ({ arrayOfMergedImages }) => {
  return (
    arrayOfMergedImages && (
      <>
        <div className="mt-3">
          <PDFDownloadLink
            document={<PageOfTableSticker arrayOfMergedImages={arrayOfMergedImages} />}
            fileName={`Table sticker`}>
            {({ loading }) =>
              loading ? (
                <button
                  type="button"
                  className="btn btn-primary rounded m-0"
                  style={{ textAlign: 'center', width: '230px' }}>
                  Please wait ...
                  <img src={download} alt="download" className="ml-4" />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary  rounded m-0"
                  style={{ textAlign: 'center', width: '230px' }}>
                  Download Cards
                  <img src={download} alt="download" className="ml-4" />
                </button>
              )
            }
          </PDFDownloadLink>
        </div>
      </>
    )
  );
};

export default PDFOfMergeImages;
