import React from 'react';
import 'react-phone-number-input/style.css'
import ConfigureWebsiteForm from './ConfigureWebsiteForm';

const Content = ({onContinue}) => {
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <ConfigureWebsiteForm onContinue={onContinue} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
