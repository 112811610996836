import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { validateUrlHost } from '../../CreateStore/actions';
import { getUrlHost } from '../../../utils';

const AgentLogin = ({ onSubmit }) => {
  const [agentId, setAgentId] = useState('');
  const [pinNumber, setPinNumber] = useState('');
  const [qrCodeSrNo, setQrCodeSrNo] = useState('');
  const [isUrlValid, setIsUrlValid] = useState(''); // 'valid' || 'invalid'

  const validateUrl = useCallback(
    async (urlPrefix) => {
      try {
        const _isUrlValid = await validateUrlHost(urlPrefix);
        setIsUrlValid(_isUrlValid);
      } catch (error) {
        setIsUrlValid('invalid');
      }
    },
    [setIsUrlValid]
  );

  const onChangeUrl = useMemo(() => debounce(validateUrl, 1000), [validateUrl]);

  useEffect(() => {
    if (qrCodeSrNo && qrCodeSrNo.length === 3) {
      const urlPrefix = `store-${qrCodeSrNo}`;
      onChangeUrl(urlPrefix);
    } else {
      setIsUrlValid('');
    }
  }, [onChangeUrl, qrCodeSrNo]);

  return (
    <div>
      <div style={{ margin: 20 }}>
        <div className="mb-3">
          <label htmlFor="login-user-email">Agent ID</label>
          <div className="input-group">
            <input
              className={`form-control`}
              id="login-agent-id"
              placeholder="Enter sales agent ID"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="none"
              required
              value={agentId}
              onChange={(e) => setAgentId(e.target.value)}
            />
            <div className={`invalid-feedback`}>Please provide a valid id.</div>
          </div>
        </div>
        <div className="mb-2">
          <label htmlFor="login-agent-pin">PIN</label>
          <div className="input-group">
            <input
              type="password"
              className={`form-control`}
              id="login-user-password"
              autoComplete="new-password"
              autoCorrect="off"
              autoCapitalize="none"
              placeholder="Enter PIN"
              required
              value={pinNumber}
              onChange={(e) => setPinNumber(e.target.value)}
            />
            <div className={`invalid-feedback`}>Please provide a pin.</div>
          </div>
        </div>
        <div className="mb-2">
          <label htmlFor="login-agent-pin">QR code serial number (optional)</label>
          <div className="input-group">
            <input
              className={`form-control is-${isUrlValid}`}
              id="qr-sr-no"
              placeholder="3 alphanumberic characters"
              required
              value={qrCodeSrNo}
              onChange={(e) => setQrCodeSrNo(e.target.value)}
            />
            <div className={`invalid-feedback`}>Not available</div>
          </div>
        </div>
      </div>
      <button
        className={`btn mt-4 d-block w-100 btn-primary`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (agentId.trim() && pinNumber.trim() && isUrlValid !== 'invalid') {
            let urlHost2 = '';
            if (qrCodeSrNo.trim()) {
              urlHost2 = getUrlHost(`store-${qrCodeSrNo}`);
            }
            onSubmit(agentId, pinNumber, urlHost2);
          }
        }}>
        Proceed
      </button>
    </div>
  );
};

export default AgentLogin;
