import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import MultiStep from './react-multistep';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { StateContext } from '../../../data/state/state-context';
import { doImport } from '../actions';

const prevStyle = {};
const nextStyle = {};
const Steps = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const history = useHistory();
  const [src, setSrc] = useState('');
  const [json, setJson] = useState(null);

  const steps = [
    { component: <StepOne setSrc={setSrc} setJson={setJson} /> },
    { component: <StepTwo src={src} json={json} /> },
    { component: <StepThree /> }
  ];

  const onFinish = () =>
    doImport(history, dispatch, rootState.main.placeId, rootState.importMenu.menuItems);

  return (
    <MultiStep steps={steps} prevStyle={prevStyle} nextStyle={nextStyle} onFinish={onFinish} />
  );
};

export default Steps;
