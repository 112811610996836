import React from "react";
import QRCode from "qrcode.react";

const StoreQRCode = ({ urlHost, placeName }) => {
  const downloadQR = () => {
    const canvas = document.getElementById("store-website-url");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    const fileName = urlHost.split('.')[0];
    downloadLink.download = `${fileName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if(!urlHost){
    return null;
  }

  return (
    <div>
      <div>
        <QRCode
          id="store-website-url"
          value={urlHost}
          size={256}
          level="H"
          includeMargin={true}
        />
      </div>
      <div className="pl-4">
        <button onClick={downloadQR} type="button" class="btn btn-light">Download QR Code</button>
      </div>
    </div>
  );
};

export default StoreQRCode;
