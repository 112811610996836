import React from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Quickbar from '../../../components/layouts/Quickbar';
import RatingList from './ratinglist';

const Deals = () => {
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <RatingList />
      </main>
      <Quickbar />
    </div>
  );
};

export default Deals;
