import React, { useEffect, useRef } from 'react';
import { toPng } from 'html-to-image';
import QRCode from 'qrcode.react';
import { waMeLink } from '../../../utils/wame';

// Create Document Component
const CoverImage = ({ setImageData, BackgroundImage, placeName, urlHost }) => {
  const domEl = useRef(null);
  const waLink = waMeLink({ urlHost });

  useEffect(() => {
    const toImage = async () => {
      // From https://www.npmjs.com/package/html-to-image
      // Safari is not supported, as it uses a stricter security model on <foreignObject> tag.
      // Suggested workaround is to use toSvg and render on the server.
      const dataUrl = await toPng(domEl.current);
      setImageData(dataUrl);
    };
    if (domEl.current) {
      toImage();
    }
  }, [domEl]);

  return (
    <div
      id="domEl"
      ref={domEl}
      style={{
        width: 1100,
        height: 780,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }}>
      <div class="container-fluid h-100">
        <div class="row h-25 align-items-center">
          <div class="col"></div>
          <div class="col">
            <h2 className="text-white text-center font-weight-bold" style={{ fontFamily: 'inter' }}>
              {placeName}
            </h2>
          </div>
        </div>
        <div class="row h-50 align-items-center">
          <div class="col">
            <QRCode
              id="store-website-url"
              value={waLink}
              size={192}
              level="H"
              includeMargin={true}
              style={{
                display: 'inherit',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: 10
              }}
            />
          </div>
          <div class="col"></div>
        </div>
        <div class="row h-25 align-items-center">
          <div class="col">
            <div
              className="mt-4 p-1 text-white text-monospace medium text-wrap text-break text-center"
              style={{ fontFamily: 'inter' }}>
              {urlHost}
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  );
};

export default CoverImage;
