import {
  collection,
  doc,
  query,
  where,
  orderBy,
  limit,
  addDoc,
  updateDoc,
  getDocs
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { auth, db } from '../../lib/firebase';
import * as ActionTypes from '../../data/state/action-types';
import { generateGUID, idfy } from '../../utils';

const addItemAction = (itemId, itemName, description, price, available, imageSrc, category) => {
  return {
    type: ActionTypes.ADD_ITEM,
    itemId,
    itemName,
    description,
    price,
    available,
    imageSrc,
    category
  };
};

const addCategoryAction = (category) => {
  return {
    type: ActionTypes.ADD_CATEGORY,
    category
  };
};

const uploadImage = async (file) => {
  const fileName = generateGUID();
  const storage = getStorage();
  const storageRef = ref(storage, fileName);

  try {
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(ref(storage, fileName));
    return url;
  } catch (error) {
    // console.log('Image upload Error', error);
    throw error;
  }
};

const addCategoryIfNew = async (dispatch, placeId, category) => {
  const q = query(
    collection(db, 'categories'),
    where('categoryId', '==', category.id),
    where('placeId', '==', placeId)
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    // we have a new category
    // get previous order and increment by 1
    const q2 = query(
      collection(db, 'categories'),
      where('placeId', '==', placeId),
      orderBy('order', 'desc'),
      limit(1)
    );
    const querySnapshot2 = await getDocs(q2);
    let newOrder = 1;
    querySnapshot2.forEach((doc) => {
      const { order } = doc.data();
      console.log('last order is', order);
      if (order) {
        newOrder = parseInt(order) + 1;
      }
    });

    await addDoc(collection(db, 'categories'), {
      placeId,
      categoryId: category.id,
      categoryName: category.name,
      order: newOrder
    });
    dispatch(addCategoryAction({ ...category, order: newOrder }));
  }
};

const addItem = async (
  dispatch,
  placeId,
  itemName,
  description,
  price,
  available,
  imageSrc,
  category
) => {
  await addCategoryIfNew(dispatch, placeId, category);
  const docRef = await addDoc(collection(db, 'items'), {
    itemName,
    description,
    placeId,
    createdBy: auth.currentUser.uid,
    price,
    available,
    ourSpecial: false,
    imageSrc,
    category: category.id
  });
  console.log('Document written with ID: ', docRef.id);
  dispatch(
    addItemAction(docRef.id, itemName, description, price, available, imageSrc, category.id)
  );

  // we are also storing items under Place (something to rethink; but need to update app code if removing it)
  const placeRef = doc(db, 'places', placeId);
  const itemsItem = `items.${docRef.id}`;
  await updateDoc(placeRef, {
    [itemsItem]: 0
  });
};

const getCategoryObj = (categories, categoryName) => {
  let category = categories.find(({ name }) => name === categoryName);
  if (!category) {
    category = { id: idfy(categoryName), name: categoryName };
  }
  return category;
};

export { addItem, addCategoryIfNew, uploadImage, getCategoryObj };
