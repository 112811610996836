import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../lib/firebase';
import { postLoginChores } from '../Login/actions';
import * as ActionTypes from '../../data/state/action-types';

const authenticate = (dispatch, history) => {
  onAuthStateChanged(auth, (user) => {
    postLoginChores(dispatch, history, user);
  });
};

const setProgressModal = (dispatch, progressModalShow, progressModalPercentDone = 0) => {
  dispatch({
    type: ActionTypes.PROGRESS_MODAL,
    progressModalShow,
    progressModalPercentDone
  });
};

const toggleIsLoading = (dispatch, isLoading) => {
  dispatch({
    type: ActionTypes.TOGGLE_ISLOADING,
    isLoading
  });
};

export { authenticate, setProgressModal, toggleIsLoading };
