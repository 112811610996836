import React, { useState, useContext, useEffect } from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import Content from './Content';
import LoadingSpinner from '../../../shared/loadingspinner';
import CoverImage from './CoverImage';
import { StateContext } from '../../../data/state/state-context';
import { toggleIsLoading } from '../../Loading/actions';

const backgroundImage6 = encodeURI('https://m.qrmeu.com/assets/menu-cover6.png');

/**
 * To add new menu designs here:
 * - Create a background image
 *   -- tip:
 *   -- make a copy of existing image like menu-cover2.png and change
 *   -- so far the diff between designs are just the left side of the image
 *   -- so you could just paste a new design on the left side and make it align
 * - Then 1. import that image, 2. add a `CoverImage` comp below, 3. update coverImages attribute of `Content`
 * - That's all!
 */

const PrintableMenu6 = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { placeName, urlHost } = rootState.main;
  const [imageData6, setImageData6] = useState(null);

  useEffect(() => {
    const loading = !imageData6;
    toggleIsLoading(dispatch, loading);

    return () => toggleIsLoading(dispatch, false);
  }, [dispatch, imageData6]);

  return (
    <>
      <div className="ms-body ms-aside-left-open ms-primary-theme">
        <Sidenavigation />
        <main className="body-content">
          <Topnavigation />
          <Content
            coverImages={[
              { title: 'Cool Dark', descr: 'Plain, dark and simple', data: imageData6 }
            ]}
          />
          <LoadingSpinner asOverlay />
        </main>
      </div>

      {/* placing the section below outside of ms-body panel to generate image with a size that that fits well on A4 page */}
      <div style={{ opacity: 0 }}>
        <CoverImage
          setImageData={setImageData6}
          BackgroundImage={backgroundImage6}
          placeName={placeName}
          urlHost={urlHost}
        />
      </div>
    </>
  );
};

export default PrintableMenu6;
