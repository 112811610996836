import { doc, updateDoc } from 'firebase/firestore';
import { geohashForLocation } from 'geofire-common';

import { db } from '../../lib/firebase';
import * as ActionTypes from '../../data/state/action-types';
import { uploadImage } from '../AddItem/actions';
import { removeImageByUrl } from '../Items/actions';
import { toggleIsLoading } from '../Loading/actions';

const setUpdateStoreAction = (
  placeName,
  workingHoursText,
  imageSrc,
  locationInfo,
  geohash,
  phoneNumber,
  address,
  hasOnlineOrdering,
  onlineOrderingSchedule,
  hasOwnDelivery,
  deliveryOptions,
  hasDunzoDelivery,
  currency,
  colors
) => ({
  type: ActionTypes.UPDATE_STORE,
  placeName,
  workingHoursText,
  imageSrc,
  locationInfo,
  geohash,
  phoneNumber,
  address,
  hasOnlineOrdering,
  onlineOrderingSchedule,
  hasOwnDelivery,
  deliveryOptions,
  hasDunzoDelivery,
  currency,
  colors
});

const updateStore = async (
  dispatch,
  history,
  placeId,
  placeName,
  workingHoursText,
  locationInfo,
  storeImgFile,
  phNo,
  currImgSrc,
  rmImgSrc,
  hasImageModified,
  hasOnlineOrdering,
  onlineOrderingSchedule,
  hasOwnDelivery,
  deliveryOptions,
  hasDunzoDelivery,
  currency,
  colors
) => {
  toggleIsLoading(dispatch, true); // start spinning
  let imageSrc = currImgSrc || '';
  if (hasImageModified) {
    if (rmImgSrc) {
      await removeImageByUrl(rmImgSrc);
      imageSrc = '';
    }
    if (storeImgFile !== null) {
      imageSrc = await uploadImage(storeImgFile);
    }
  }

  const { lat, lng } = locationInfo;
  const geohash = geohashForLocation([lat, lng]);
  const placeRef = doc(db, 'places', placeId);
  const phoneNumber = phNo ? phNo.substring(1) : null;
  await updateDoc(placeRef, {
    placeName,
    workingHoursText,
    address: locationInfo.geocodedAddress,
    locationInfo,
    geohash,
    phoneNumber,
    imageSrc,
    hasOnlineOrdering,
    onlineOrderingSchedule,
    hasOwnDelivery,
    deliveryOptions,
    hasDunzoDelivery,
    currency,
    colors
  });

  toggleIsLoading(dispatch, false); // stop spinning

  dispatch(
    setUpdateStoreAction(
      placeName,
      workingHoursText,
      imageSrc,
      locationInfo,
      geohash,
      phoneNumber,
      locationInfo.geocodedAddress,
      hasOnlineOrdering,
      onlineOrderingSchedule,
      hasOwnDelivery,
      deliveryOptions,
      hasDunzoDelivery,
      currency,
      colors
    )
  );

  history.push('/');
};

const onChangeLocalLang = async (dispatch, placeId, localLanguages) => {
  try {
    // first update state, then update dB
    dispatch({
      type: ActionTypes.UPDATE_LOCAL_LANGUAGE,
      localLanguages
    });

    const placeRef = doc(db, 'places', placeId);
    await updateDoc(placeRef, { localLanguages });
  } catch (error) {
    //
  }
};

const onChangeLanguage = (dispatch, lng) => {
  sessionStorage.setItem('lng', lng);
  dispatch({
    type: ActionTypes.SET_LANGUAGE,
    lng
  });
};

export { updateStore, onChangeLocalLang, onChangeLanguage };
