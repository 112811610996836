import React, { useEffect } from 'react';
import PDFOfMergeImages from './PDFOfMergeImages';

const Content = ({
  arrayOfMergedImages,
  sortedImages,
  setSortedImages,
  showOverlay,
  setShowOverlay
}) => {
  useEffect(() => {
    const sortedArray = arrayOfMergedImages.sort((a, b) => a.index - b.index);

    setSortedImages(sortedArray);
  }, [arrayOfMergedImages]);

  return (
    <div className="ms-content-wrapper ms-panel border">
      <div className="d-flex justify-content-end align-content-center">
      <PDFOfMergeImages arrayOfMergedImages={sortedImages} />
        <button
          onClick={() => {
            setShowOverlay(!showOverlay);
          }}
          className="btn btn-danger ml-2">
          Close
        </button>

      </div>
      <div class="row">
        {sortedImages.map((image, index) => (
          <div className="col-sm-12 col-lg-8 pt-5" key={index}>
            <img src={image.data} alt="Menu cover page" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Content;
