import React, { useEffect, useState } from 'react';
import response from './response.json';
import storeImage from '../../../../public/assets/img/foodtech/add-product-1.jpg';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { ArrowBack } from '@mui/icons-material';
function StoreCard({ prevPage, setPrevPage, item, setPage, leadId }) {
  const { storeName, storeLocation } = item;
  const handleSelStore = async (event) => {
    const docRef = doc(db, 'leads', leadId);
    try {
      await updateDoc(docRef, {
        onlineStoreURL: item.onlineStoreURL
      });
      console.log('Document updated with ID: ', leadId);
      setPrevPage([...prevPage, 'show-stores']);
      setPage('store-contacts');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };
  return (
    <div className="col-md-6 mt-3 d-flex justify-content-center">
      <div className="card" style={{ width: '21rem', height: '400px' }}>
        <img className="card-img-top" src={storeImage} alt="Card" style={{ height: '210px' }} />
        <div className="card-body  bg-black ">
          <h5 className="card-title text-capitalize text-light">{storeName}</h5>
          <h5 className="card-text text-light">Address:</h5>
          <p className="card-text text-light">{storeLocation}</p>

          <button className="btn btn-primary w-100 m-auto" onClick={handleSelStore}>
            Choose
          </button>
        </div>
      </div>
    </div>
  );
}
const ShowStores = ({ prevPage, setPrevPage, leadId, setPage }) => {
  console.log(prevPage);
  const [stores, setStores] = useState([]);
  useEffect(() => {
    const getStores = async () => {
      // query = `${placeName}, ${location}`
      // const response = await fetch(
      //   `${settings.croquetUrl}/matchingstores-details?q=${encodeURIComponent(query)}`
      // );
      if (response) {
        setStores(response);
      } else {
        setPage('store-url');
      }
    };

    getStores();
  });
  const handleNoStore = (event) => {
    setPrevPage([...prevPage, 'show-stores']);
    setPage('store-url');
  };
  const handleBack = () => {
    const prev = prevPage[prevPage.length - 1];
    setPrevPage((prevItems) => prevItems.filter((item, i) => i !== prevItems.length - 1));
    setPage(prev);
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <ArrowBack className="m-2" onClick={handleBack} />

          <div className="container " style={{ height: '100vh', overflowY: 'scroll' }}>
            <div className="row">
              {stores.map((item, index) => {
                return (
                  <StoreCard
                    prevPage={prevPage}
                    setPrevPage={setPrevPage}
                    item={item}
                    setPage={setPage}
                    key={index}
                    leadId={leadId}
                  />
                );
              })}
              <div className="col-md-6 mt-3 d-flex justify-content-center">
                <button
                  className="btn btn-primary px-2 m-auto"
                  style={{ width: '21rem' }}
                  onClick={handleNoStore}>
                  None of the above
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowStores;
