import CardContent from './CardContent';
import React from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';

const newYear = encodeURI('https://m.qrmeu.com/assets/newyear.jpg');

const GreetingCard = () => {
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <div className="ms-content-wrapper ms-panel d-flex justify-content-center">
          <CardContent cardimg={newYear} textColor="white" />
        </div>
      </main>
    </div>
  );
};

export default GreetingCard;
