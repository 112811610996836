import React from 'react';
import Content from './Content';

const Defaultlogin = () => {
  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
        <main className="body-content">
            <Content />
        </main>
    </div>
  );
};

export default Defaultlogin;
