import React from 'react';
import Sidenavigation from '../../../components/layouts/Sidenavigation';
import Topnavigation from '../../Root/components/Topnavigation';
import CustomerList from './CustomerList';

const Deals = () => {
  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme">
      <Sidenavigation />
      <main className="body-content">
        <Topnavigation />
        <CustomerList />
      </main>
    </div>
  );
};

export default Deals;
