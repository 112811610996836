import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Content from './Content';
import { StateContext } from '../../../data/state/state-context';
import { createStore } from '../actions';
import LoadingSpinner from '../../../shared/loadingspinner';

const CreateStore = () => {
  const [, dispatch] = useContext(StateContext);
  const history = useHistory();
  const onCreateStore = (
    placeName,
    address,
    locationInfo,
    storeImgFile,
    phNo,
    urlHost,
    currency,
    colors
  ) =>
    createStore(
      dispatch,
      history,
      placeName,
      address,
      locationInfo,
      storeImgFile,
      phNo,
      urlHost,
      currency,
      colors
    );

  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
      <main className="body-content">
        <Content onCreateStore={onCreateStore} />
        <LoadingSpinner asOverlay />
      </main>
    </div>
  );
};

export default CreateStore;
