import React, { useContext, useState, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import stringSimilarity from 'string-similarity';
import { debounce } from 'lodash';

import Breadcrumb from './Breadcrumb';
import { addItem, uploadImage } from '../actions';
import { StateContext } from '../../../data/state/state-context';
import ProductCategories from './ProductCategory';
import { toggleIsLoading } from '../../Loading/actions';
import foodImages from '../../../data/food-images.json';
import settings from '../../../../config/settings';
import { searchPexels } from '../../../utils/external-images';

const Addproductcontent = () => {
  const [rootState, dispatch] = useContext(StateContext);
  const { categories } = rootState.items;
  const history = useHistory();
  const [saved, setSaved] = useState(false);
  const [productImgSrc, setProductImgSrc] = useState(null);
  const [productImgFile, setProductImgFile] = useState(null);
  const [rankedImages, setRankedImages] = useState([]);
  const [rankIndex, setRankIndex] = useState(0);
  const [category, setCategory] = useState('');
  const [userSelectedImage, setUserSelectedImage] = useState(false);

  const placeholderImage = process.env.PUBLIC_URL + '/assets/img/foodtech/add-product-6.jpg';

  const clearForm = () => {
    document.getElementById('add-product-form').reset();
    document.getElementById('add-product-available').checked = false;
    onRemoveImage();
  };

  const onAddItem = async (itemName, description, price, available) => {
    try {
      toggleIsLoading(dispatch, true); // start spinning
      let imageSrc = '';
      if (productImgSrc !== null) {
        if (userSelectedImage === false) {
          // we have an image url (local or remote) - no need to upload user selected file
          imageSrc = productImgSrc;
        } else {
          imageSrc = await uploadImage(productImgFile);
        }
      }
      await addItem(
        dispatch,
        rootState.main.placeId,
        itemName,
        description,
        price,
        available,
        imageSrc,
        category
      );
      toggleIsLoading(dispatch, false); // stop spinning
      setSaved(true); // fake flag for now; rightway would be to dispatch it after db-save inside addItem fn
      clearForm();
    } catch (error) {
      toggleIsLoading(dispatch, false); // stop spinning
      console.log('Error while adding product ', error);
      alert('Something went wrong');
    }
  };

  const onCancel = () => history.push('/menu-list');

  const onSave = (event) => {
    // TODO: there is bootstrap validation that kicks in during submit
    // Need to see how to make it work even when we handle submit here
    event.preventDefault();
    event.stopPropagation();

    const itemName = document.getElementById('add-product-name');
    const description = document.getElementById('add-product-description');
    const _price = document.getElementById('add-product-price');
    const price = parseFloat(_price.value) || 0;
    const available = document.getElementById('add-product-available').checked;
    if (itemName) {
      onAddItem(itemName.value, description.value, price, available);
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUserSelectedImage(true);
      const reader = new FileReader();
      reader.onload = function () {
        setProductImgSrc(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      // setProductImgSrc(URL.createObjectURL(event.target.files[0]));
      setProductImgFile(event.target.files[0]);
      event.target.value = null; // clear the input field
    }
  };

  const onRemoveImage = () => {
    setUserSelectedImage(false);
    setProductImgSrc(null);
    setProductImgFile(null);
  };

  // NOTE: if searchKeywords is not provided, then findAnImage searches for category-itemName combo
  const findAnImage = useCallback(
    async (searchKeywords = '') => {
      let lookupTerm = '';
      let pexelsUrls = [];
      if (typeof searchKeywords === 'string' && searchKeywords !== '') {
        if (searchKeywords.trim().indexOf('https://') === 0) {
          // we have an image url
          setProductImgSrc(searchKeywords.trim());
          return;
        }

        pexelsUrls = await searchPexels(searchKeywords);
        lookupTerm = searchKeywords;
      } else if (userSelectedImage === false) {
        // user hasn't picked a file - let's try to find one based on string similarity
        const itemName = document.getElementById('add-product-name');
        if (!itemName?.value) {
          return;
        }

        lookupTerm = category.id + '-' + itemName.value.toLowerCase();
      }

      if (lookupTerm === '') {
        return;
      }

      const matches = stringSimilarity.findBestMatch(lookupTerm, foodImages);
      const rankedResult = matches?.ratings?.sort((a, b) => b.rating - a.rating);
      rankedResult.unshift(...pexelsUrls);
      if (rankedResult.length > 0) {
        setRankedImages(rankedResult);
        setRankIndex(0);
        const imgSrc =
          rankedResult[0].target.indexOf('https://') === 0
            ? rankedResult[0].target
            : `${settings.foodImagePath}/${rankedResult[0].target}`;
        setProductImgSrc(imgSrc);
      }
    },
    [category, userSelectedImage]
  );

  const findAnImageDebounce = useMemo(() => debounce(findAnImage, 1000), [findAnImage]);

  const searchImgByKeyword = (e) => {
    // setUserSelectedImage(true);
    findAnImageDebounce(e.target.value);
  };

  const switchImage = (idx) => {
    // setUserSelectedImage(true);
    setRankIndex(idx);
    const imgSrc =
      rankedImages[idx].target.indexOf('https://') === 0
        ? rankedImages[idx].target
        : `${settings.foodImagePath}/${rankedImages[idx].target}`;
    setProductImgSrc(imgSrc);
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb />
          {saved === true ? (
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <strong>Saved!</strong> Saved successfully. View
              <Link to="/menu-list">{' Menu List'}</Link>
            </div>
          ) : (
            <div className="alert alert-success" role="alert">
              Got an image or PDF of the menu?{' '}
              <Link to="/import-menu">If so, try Import Menu.</Link>
            </div>
          )}
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>Item Details</h6>
            </div>
            <div className="ms-panel-body">
              <form id="add-product-form" className="needs-validation clearfix" noValidate>
                <div className="form-row">
                  <ProductCategories
                    categories={categories}
                    selected={category}
                    onSelect={setCategory}
                  />
                  <div className="col-md-12 mb-3">
                    <label htmlFor="add-product-name">Product Name</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="add-product-name"
                        placeholder="Name"
                        onBlur={findAnImage}
                        autoComplete="new-name"
                        required
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="add-product-price">Price</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="add-product-price"
                        placeholder="0.00"
                        autoComplete="new-price"
                        required
                      />
                      <div className="invalid-feedback">Price</div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="add-product-description">Description</label>
                    <div className="input-group">
                      <textarea
                        rows={5}
                        id="add-product-description"
                        className="form-control"
                        placeholder="Short description"
                        required
                      />
                      <div className="invalid-feedback">Please provide description.</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="ms-panel">
                <div className="ms-panel-header">
                  <h6>Image </h6>
                </div>
                <div className="ms-panel-body">
                  {productImgSrc === null ? (
                    <img className="d-block w-100" src={placeholderImage} alt="Placeholder" />
                  ) : (
                    <>
                      <div className="ms-panel-header new">
                        <button
                          type="button"
                          className="btn btn-outline-secondary has-icon"
                          onClick={() => switchImage(rankIndex > 0 ? rankIndex - 1 : 0)}>
                          <i class="fa fa-chevron-left"></i>
                        </button>
                        <input
                          className="form-control form-control-lg border-dark mx-1"
                          type="text"
                          placeholder="Keywords or URL"
                          onChange={searchImgByKeyword}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary has-icon"
                          onClick={() => switchImage(rankIndex + 1)}>
                          <i class="fa fa-chevron-right"></i>
                        </button>
                      </div>
                      <img
                        src={productImgSrc}
                        alt="Product"
                        style={{ width: '100%', objectFit: 'cover' }}
                      />
                      <div className="new">
                        <button type="button" className="btn btn-secondary" onClick={onRemoveImage}>
                          Remove
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="ms-panel-header new">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="validatedCustomFile"
                      onChange={onImageChange}
                    />
                    <label className="custom-file-label" htmlFor="validatedCustomFile">
                      Select image...
                    </label>
                    <div className="invalid-feedback">Invalid image</div>
                  </div>
                </div>
                <div className="ms-panel-header new">
                  <p className="medium">Available</p>
                  <div>
                    <label className="ms-switch">
                      <input type="checkbox" id="add-product-available" />
                      <span className="ms-switch-slider round" />
                    </label>
                  </div>
                </div>
                <div className="ms-panel-header new">
                  <button className="btn btn-secondary d-block" onClick={onCancel}>
                    Cancel
                  </button>
                  <button className="btn btn-primary d-block" onClick={onSave}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addproductcontent;
