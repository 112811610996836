import React from 'react';

const DunzoDelivery = ({ hasDunzoDelivery, setHasDunzoDelivery }) => {
  return (
    <div className="my-2">
      <div className="d-md-inline-flex">
        <label className="ms-switch">
          <input
            type="checkbox"
            checked={hasDunzoDelivery}
            defaultChecked={hasDunzoDelivery === true}
            onClick={(e) => {
              setHasDunzoDelivery(e.target.checked);
            }}
          />
          <span className="ms-switch-slider ms-switch-success round" />
        </label>
        <span> Enable Dunzo delivery</span>
      </div>
    </div>
  );
};

export default DunzoDelivery;
